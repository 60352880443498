<template>
    <monthly-sales-goal />
</template>

<script>
import MonthlySalesGoal from '../../components/Sales/MonthlySalesGoal.vue'

export default {
    name: 'MonthlySalesGoalView',
    components: {
        MonthlySalesGoal,
    },
}
</script>