<template>
    <exam/>
</template>

<script>
import Exam from '../../components/NovaExam/Exam.vue'

export default {
    name: 'NovaExam',
    components: {
        Exam,
    },
}
</script>