<template>
    <calls-goal />
</template>

<script>
import CallsGoal from '../../components/Sales/CallsGoal.vue'

export default {
    name: 'CallsGoalView',
    components: {
        CallsGoal,
    },
}
</script>