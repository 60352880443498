import { COLLECTED_GOAL } from '../endpoints';

export async function fetchAllCollectedGoals() {  
    try {
      const response = await fetch(COLLECTED_GOAL, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        console.error("Error response data:", errorData);
        throw new Error(`Error fetching collected goals: ${response.statusText}`);
      }
  
      const data = await response.json();
      console.log("Fetched collected collected:", data);
      return data;
    } catch (error) {
      console.error("Error in fetchAllcollectedGoals:", error.message || error);
      throw error;
    }
  }
  

  /**
 * Function to create a collected goal.
 * @param {data} data - The data to be sent to the API.
 * @returns {Promise<any>} - The response from the server.
 * @author JDGT
 */
export async function createCollectedGoal(dataItem) {    
    console.log("dataItem:", dataItem);
    try {
      const response = await fetch(COLLECTED_GOAL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataItem),
      });
  
      if (!response.ok) {
        throw new Error(`Error ${response.status}: ${response.statusText}`);
      }
  
      const data = await response.json();
      console.log("Response:", data);
      return response;
    } catch (error) {
      console.error("Error creating collected goal:", error);
      throw error; // Rethrow the error to handle it outside this function
    }
  }

/**
 * Function to remove a collected goal by ID.
 * @param {number} id - The ID of the collected goal to delete.
 * @returns {Promise<any>} - The response from the server.
 * @author JDGT
 */
export async function removeCollectedGoal(id) {    
  
    try {
      const response = await fetch(`${COLLECTED_GOAL}/${id}`, {
        method: "DELETE",
      });
  
      if (!response.ok) {
        throw new Error(`Error ${response.status}: ${response.statusText}`);
      } 
      
      return response;
    } catch (error) {
      console.error("Error removing collected goal:", error);
      throw error; // Rethrow para manejar errores fuera de esta función.
    }
  }
  