import { GET_ACTIVITY_TODAY_EMPLOYEE, ACTIVITY_REPORT } from '../../utils/endpoints';

/**
 * Fetch today's activities for a given employee number.
 * @param {string} employeeNumber - The employee number to query.
 * @returns {Promise<object>} - The response data from the server.
 * @throws {Error} - Throws an error if the fetch fails or the server returns an error.
 * @author JDGT
 */
export async function fetchTodayActivities(employeeData) {
  console.log("employeeData: ", employeeData);
  console.log("employeeData.employee_number: ", employeeData.employee_number);  

  const endpoint = `${GET_ACTIVITY_TODAY_EMPLOYEE}`;
  console.log("endpoint: ", endpoint);

  try {
    const response = await fetch(endpoint, {
      method: 'POST', // Cambiado a POST porque envías un objeto
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(employeeData), // Envía el objeto employeeData en el cuerpo
    });

    if (!response.ok) {
      // Retornar un array vacío o lanzar un error según tu preferencia
      const errorData = await response.json();
      console.error(
        errorData.message || `Failed to fetch activities. Status: ${response.status}`
      );
      return [];
    }

    return await response.json(); // Parsear la respuesta como JSON
  } catch (error) {
    console.error("Error fetching today's activities:", error.message || error);
    throw new Error(`Error fetching today's activities: ${error.message}`);
  }
}

/**
 * Function to consume the create activity endpoint
 * @param {Object} data - The payload to be sent in the POST request
 * @param {string} url - The endpoint URL
 * @returns {Promise<Object>} - The response data from the server
 * @throws {Error} - If the request fails or the server returns an error
 */
export async function createActivity(data) {
  console.log("Payload enviado:", data);
  try {
    const response = await fetch(`${ACTIVITY_REPORT}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      const errorDetails = await response.json(); // Leer el cuerpo del error
      console.error('Detalles del error del servidor:', errorDetails);
      throw new Error(`HTTP error! status: ${response.status} - ${JSON.stringify(errorDetails)}`);
    }

    //return await response.json(); // Retornar la respuesta como JSON
    return response;
  } catch (error) {
    console.error('Error creando actividad:', error);
    throw error;
  }
}

/**
 * Function to consume the addTodayActivity endpoint.
 * Author: JDGT
 * @param {string} reason - The reason for the activity.
 * @param {Oject} employeeData - The employee number.
 * @param {Oject} data - Activity data.
 * @returns {Promise<Object>} - The response data from the server.
 * @throws {Error} - Throws an error if the request fails or the server returns an error.
 */
export async function addTodayActivity(reason, employeeData, data) {
  const endpointUrl = `${ACTIVITY_REPORT}/add`; // Replace with the actual URL of the endpoint

  const payload = {
    reason,
    employeeData,
    data,
  };

  try {
    const response = await fetch(endpointUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Failed to add today activity');
    }

    const responseData = await response.json();
    //return responseData;
    return response;
  } catch (error) {
    console.error('Error adding today activity:', error.message);
    throw error;
  }
}

/**
 * Fetch the activity summary from the server and handle the response.
 * @param {object} employeeData - The employee data object.
 * @param {string} typeOfJob - The type of job.
 * @returns {Promise<void>} - A promise that resolves when the image is processed.
 */
export async function fetchActivitySummary(employeeData, typeOfJob) {
  if (!employeeData || !employeeData.employee_number) {
    throw new Error("Employee data is invalid.");
  }

  const endpoint = `${ACTIVITY_REPORT}/summary`;

  try {
    const response = await fetch(endpoint, {
      method: 'POST', // Cambiado a POST
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ employeeData, typeOfJob }), // Enviar el objeto completo en el cuerpo
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status} - ${response.statusText}`);
    }

    // Convierte la respuesta a un blob (contenido binario)
    const blob = await response.blob();

    // Verifica el blob
    console.log('Blob size:', blob.size);
    console.log('Blob type:', blob.type);

    if (blob.size === 0 || blob.type !== 'image/jpeg') {
      throw new Error('The image data is invalid.');
    }

    // Descarga la imagen
    const downloadLink = document.createElement('a');
    const imageUrl = URL.createObjectURL(blob);
    downloadLink.href = imageUrl;
    downloadLink.download = `activity_summary_${employeeData.employee_number}.jpg`; // Nombre del archivo
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);

    console.log('Image downloaded successfully.');
  } catch (error) {
    console.error('Failed to fetch and download activity summary:', error);
  }
}


export async function fetchALLTodayActivities() {
  try {
    const response = await fetch(`${ACTIVITY_REPORT}/all/today`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      // Manejar errores HTTP (status code fuera del rango 2xx)
      throw new Error(`HTTP error! Status: ${response.status} - ${response.statusText}`);
    }

    const data = await response.json(); // Convertir la respuesta a JSON
    console.log('Response Data:', data); // Mostrar datos en la consola
    return data; // Devolver los datos obtenidos
  } catch (error) {
    console.error('Error fetching today\'s activities:', error.message || error);
    throw new Error('Failed to fetch today\'s activities.');
  }
}

export async function fetchUniqueExtensions() {  

  try {
    const response = await fetch(`${ACTIVITY_REPORT}/extensions`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status} - ${response.statusText}`);
    }

    const data = await response.json();
    console.log('Unique Extensions:', data);
    return data; // Devuelve el array de extensiones
  } catch (error) {
    console.error('Failed to fetch extensions:', error.message);
  }
}

/**
 * Function to consume the endpoint for filtering activities with reasons
 * @param filters - Object containing filters: creationDate, staff_name, and reason
 * @returns Promise<any> - Response data from the endpoint
 */
export async function fetchActivitiesWithReason(filters) { 

  try {
    const response = await fetch(`${ACTIVITY_REPORT}/filter/reason`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(filters),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    console.log('Response Received:', data);
    return data;
  } catch (error) {
    console.error('Error fetching activities:', error.message);
    throw error;
  }
}

/**
 * Function to consume the endpoint for filtering activities with reasons
 * @param filters - Object containing filters: creationDate, staff_name, and reason
 * @returns Promise<any> - Response data from the endpoint
 */
export async function fetchActivitiesWithReasonEmployee(filters) { 

  try {
    const response = await fetch(`${ACTIVITY_REPORT}/filter/employee`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(filters),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    console.log('Response Received:', data);
    return data;
  } catch (error) {
    console.error('Error fetching activities:', error.message);
    throw error;
  }
}

export async function exportActivitiesToExcel(dataActivity) {
  try {
      console.log("dataActivity: ", dataActivity);
      // Validar que dataActivity sea un array no vacío
      /* if (!Array.isArray(dataActivity) || dataActivity.length === 0) {
          throw new Error('No hay datos para exportar.');
      } */

      // Realizar la petición al endpoint
      const response = await fetch(`${ACTIVITY_REPORT}/export-excel`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(dataActivity),
      });

      if (!response.ok) {
          throw new Error('Error al exportar los datos. Verifica el servidor.');
      }

      // Obtener el blob del archivo
      const blob = await response.blob();

      // Crear un enlace para descargar el archivo
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;

      // Definir el nombre del archivo
      const filename = `activity_report_${new Date().toISOString().split('T')[0]}.xlsx`;
      link.setAttribute('download', filename);

      // Simular clic para iniciar la descarga
      document.body.appendChild(link);
      link.click();

      // Limpiar el enlace del DOM
      link.remove();
      window.URL.revokeObjectURL(url);

      console.log('Archivo exportado exitosamente.');
  } catch (error) {
      console.error('Error al exportar el archivo:', error);
  }
}

/**
 * Function to consume the summary/calls endpoint
 * @param {Object} body - The request body containing employeeData and typeOfJob
 * @param {Object} body.employeeData - Employee data object
 * @param {string} body.typeOfJob - Type of job
 * @returns {Promise<Object>} - The response from the server
 * @throws {Error} - If the request fails or the response is not ok
 */
export async function getCallsSummary(body) {
  console.log("body: ", body);
  
  const headers = {
    'Content-Type': 'application/json',
  };


  // Validate input
  if (!body || !body.employeeData || !body.employeeData.employee_number) {
    throw new Error('Employee data is invalid');
  }

  try {
    const response = await fetch(`${ACTIVITY_REPORT}/summary/calls`, {
      method: 'POST',
      headers,
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      //const errorText = await response.text();
      //throw new Error(`Request failed: ${response.status} - ${errorText}`);
      return null;
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching call summary:', error);
    throw error;
  }
}

/**
 * Fetch all today's activities by department from the API.
 * @param {string} department - The name of the department.
 * @returns {Promise<any>} - The response data from the endpoint.
 * @throws Will throw an error if the department is not provided or if the request fails.
 * @author JDGT
 */
export async function fetchTodayActivitiesByDepartment(department) {
  if (!department) {
    throw new Error('Department is required.');
  }

  const url = `${ACTIVITY_REPORT}/all/today/by-department?department=${encodeURIComponent(department)}`;

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP Error: ${response.status} - ${response.statusText}`);
    }

    const data = await response.json();
    console.log('Fetched activities:', data);
    return data;
  } catch (error) {
    console.error('Error fetching today\'s activities by department:', error);
    throw error;
  }
}