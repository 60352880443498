import { CALLS_GOAL } from '../../utils/endpoints';

export async function fetchAllCallsGoals() {
    //const endpoint = `${BASE_URL}/calls-goals`; // Reemplaza BASE_URL con tu URL base
    //console.log("Fetching all calls goals from:", endpoint);
  
    try {
      const response = await fetch(CALLS_GOAL, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        console.error("Error response data:", errorData);
        throw new Error(`Error fetching calls goals: ${response.statusText}`);
      }
  
      const data = await response.json();
      console.log("Fetched calls goals:", data);
      return data;
    } catch (error) {
      console.error("Error in fetchAllCallsGoals:", error.message || error);
      throw error;
    }
  }
  

  /**
 * Function to create a calls goal.
 * @param {data} data - The data to be sent to the API.
 * @returns {Promise<any>} - The response from the server.
 * @author JDGT
 */
export async function createCallsGoal(dataItem) {    
    console.log("dataItem:", dataItem);
    try {
      const response = await fetch(CALLS_GOAL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataItem),
      });
  
      if (!response.ok) {
        throw new Error(`Error ${response.status}: ${response.statusText}`);
      }
  
      const data = await response.json();
      console.log("Response:", data);
      return response;
    } catch (error) {
      console.error("Error creating calls goal:", error);
      throw error; // Rethrow the error to handle it outside this function
    }
  }

/**
 * Function to remove a calls goal by ID.
 * @param {number} id - The ID of the calls goal to delete.
 * @returns {Promise<any>} - The response from the server.
 * @author JDGT
 */
export async function removeCallsGoal(id) {    
  
    try {
      const response = await fetch(`${CALLS_GOAL}/${id}`, {
        method: "DELETE",
      });
  
      if (!response.ok) {
        throw new Error(`Error ${response.status}: ${response.statusText}`);
      } 
      
      return response;
    } catch (error) {
      console.error("Error removing calls goal:", error);
      throw error; // Rethrow para manejar errores fuera de esta función.
    }
  }
  