<template>
    <collected-goal />
</template>

<script>
import CollectedGoal from '../../components/Sales/CollectedGoal.vue'

export default {
    name: 'CollectedGoalView',
    components: {
        CollectedGoal,
    },
}
</script>