<template>
  <div>
    <v-data-table :headers="headers" :items="filteredEmployees" sort-by="name" class="elevation-1">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Permissions</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-text-field v-model="search" label="Search" solo-inverted prepend-icon="mdi-magnify"
            class="mr-3 mt-5"></v-text-field>
          <v-btn color="blue" dark class="mb-2 mr-2" @click="getAllEmployees">
            <v-icon left>mdi-refresh</v-icon>
            Refresh
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.walk_ins="{ item }">
        <v-switch v-model="item.walk_ins" @change="toggleItem(item, 'walk_ins')"
          :color="item.walk_ins ? 'green' : 'red'" :class="item.walk_ins ? 'switch-green' : 'switch-red'">
        </v-switch>
      </template>
      <template v-slot:item.job_offer="{ item }">
        <v-switch v-model="item.job_offer" @change="toggleItem(item, 'job_offer')"
          :color="item.job_offer ? 'green' : 'red'" :class="item.job_offer ? 'switch-green' : 'switch-red'">
        </v-switch>
      </template>
      <template v-slot:item.training_logs="{ item }">
        <v-switch v-model="item.training_logs" @change="toggleItem(item, 'training_logs')"
          :color="item.training_logs ? 'green' : 'red'" :class="item.training_logs ? 'switch-green' : 'switch-red'">
        </v-switch>
      </template>
      <template v-slot:item.nova_in_out="{ item }">
        <v-switch v-model="item.nova_in_out" @change="toggleItem(item, 'nova_in_out')"
          :color="item.nova_in_out ? 'green' : 'red'" :class="item.nova_in_out ? 'switch-green' : 'switch-red'">
        </v-switch>
      </template>
      <template v-slot:item.hr="{ item }">
        <v-switch v-model="item.hr" @change="toggleItem(item, 'hr')"
          :color="item.hr ? 'green' : 'red'" :class="item.hr ? 'switch-green' : 'switch-red'">
        </v-switch>
      </template>
      <template v-slot:item.hr_philippines="{ item }">
        <v-switch v-model="item.hr_philippines" @change="toggleItem(item, 'hr_philippines')"
          :color="item.hr_philippines ? 'green' : 'red'" :class="item.hr_philippines ? 'switch-green' : 'switch-red'">
        </v-switch>
      </template>
      <template v-slot:item.accounting="{ item }">
        <v-switch v-model="item.accounting" @change="toggleItem(item, 'accounting')"
          :color="item.accounting ? 'green' : 'red'" :class="item.accounting ? 'switch-green' : 'switch-red'">
        </v-switch>
      </template>
      <template v-slot:item.accounting_validation="{ item }">
        <v-switch v-model="item.accounting_validation" @change="toggleItem(item, 'accounting_validation')"
          :color="item.accounting_validation ? 'green' : 'red'" :class="item.accounting_validation ? 'switch-green' : 'switch-red'">
        </v-switch>
      </template>
      <template v-slot:item.marketing="{ item }">
        <v-switch v-model="item.marketing" @change="toggleItem(item, 'marketing')"
          :color="item.marketing ? 'green' : 'red'" :class="item.marketing ? 'switch-green' : 'switch-red'">
        </v-switch>
      </template>
      <template v-slot:item.fleet="{ item }">
        <v-switch v-model="item.fleet" @change="toggleItem(item, 'fleet')"
          :color="item.fleet ? 'green' : 'red'" :class="item.fleet ? 'switch-green' : 'switch-red'">
        </v-switch>
      </template>
      <template v-slot:item.it="{ item }">
        <v-switch v-model="item.it" @change="toggleItem(item, 'it')"
          :color="item.it ? 'green' : 'red'" :class="item.it ? 'switch-green' : 'switch-red'">
        </v-switch>
      </template>

      <template v-slot:item.activity_report="{ item }">
        <v-switch v-model="item.activity_report" @change="toggleItem(item, 'activity_report')"
          :color="item.activity_report ? 'green' : 'red'" :class="item.activity_report ? 'switch-green' : 'switch-red'">
        </v-switch>
      </template>

      <template v-slot:item.sales="{ item }">
        <v-switch v-model="item.sales" @change="toggleItem(item, 'sales')"
          :color="item.sales ? 'green' : 'red'" :class="item.sales ? 'switch-green' : 'switch-red'">
        </v-switch>
      </template>

    </v-data-table>
    <div class="loader-container" v-if="showLoader">
      <div class="loader"></div>
    </div>
  </div>
</template>

<script>
import { GET_ALL_EMPLOYEES_BY_STATUS, UPDATE_PERMISSIONS } from '../../utils/endpoints';
import { bus } from '../../main.js';

export default {
  name: 'PermissionsConsole',
  data: () => ({
    showLoader: false,
    employees: [],
    search: '',

    headers: [
      {
        text: 'Employee number',
        align: 'start',
        sortable: false,
        value: 'employee_number',
      },
      { text: 'Name(s)', value: 'name' },
      { text: 'Last name(s)', value: 'last_name' },
      { text: 'Department', value: 'department' },
      { text: 'Permissions', value: 'permissions' },
      { text: 'Position', value: 'position' },
      { text: 'Nova Email', value: 'nova_email' },
      { text: 'Company', value: 'company' },
      { text: 'Walk_ins', value: 'walk_ins', sortable: false },
      { text: 'Job Offer', value: 'job_offer', sortable: false },
      { text: 'Training Log', value: 'training_logs', sortable: false },
      { text: 'Nova In Out', value: 'nova_in_out', sortable: false },
      { text: 'HR', value: 'hr', sortable: false },      
      { text: 'HR Philippines', value: 'hr_philippines', sortable: false },      
      { text: 'Accounting', value: 'accounting', sortable: false },
      { text: 'Accounting Validation', value: 'accounting_validation', sortable: false },
      { text: 'Marketing', value: 'marketing', sortable: false },
      { text: 'Fleet', value: 'fleet', sortable: false },
      { text: 'IT', value: 'it', sortable: false },
      { text: 'Activity Report', value: 'activity_report', sortable: false },
      { text: 'Sales', value: 'sales', sortable: false },
    ],
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'New Employee' : 'Edit Employee'
    },
    filteredEmployees() {
      return this.employees.filter(employee =>
        employee.name.toLowerCase().includes(this.search.toLowerCase())
      );
    },
  },
  mounted() {
    bus.$emit('login-success');
    bus.$emit('permissions');
    this.getAllEmployees();
  },
  methods: {
    toggleItem(Obj, item) {
      console.log(item);
      console.log(Obj);
      if (Obj[item]) {
        console.log("ON");
      } else {
        console.log("OFF")
      }
      const data = {
        "employee_number": Obj['employee_number'],
        [item]: Obj[item]
      }
      console.log("data: ", data)

      this.update_PermissionsEmployee(data);
    },

    getAllEmployees() {
      this.showLoader = true;
      console.log("Getting all employees.....")
      fetch(`${GET_ALL_EMPLOYEES_BY_STATUS}Active`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Failed to fetch employees. Status: ' + response.status);
          }
          console.log("response: ", response);
          return response.json();
        })
        .then(data => {
          console.log('data')
          console.log(data)
          console.log(data.employees);
          this.employees = data.employees;
          this.showLoader = false;
        })
        .catch(error => {
          console.error(error);
          alert('An error occurred while fetching employees. Please try again later.');
        });
    },
    update_PermissionsEmployee(data) {
      const requestOptions = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      };

      fetch(UPDATE_PERMISSIONS, requestOptions)
        .then(response => {
          if (!response.ok) {
            throw new Error('Failed to update employee status');
          }
          return response.json();
        })
        .catch(error => {
          alert('An error occurred while updating employee status. Please try again later.');
          this.getAllEmployees();
        });
    },
  },
}
</script>

<style>
.switch-green .v-input--switch__track {
  background-color: #77dd77 !important;
}

.switch-green .v-input--switch__thumb {
  background-color: #77dd77 !important;
}

.switch-red .v-input--switch__track {
  background-color: #FF9999 !important;
}

.switch-red .v-input--switch__thumb {
  background-color: #FF9999 !important;
}

/* 
  Style adjustments for the data table:
  - Table headers will not wrap text and will display all content in a single line.
  - Table cells will wrap text if it's too long but will maintain a minimum width of 170px.
  */
.v-data-table th {
  white-space: nowrap;
}

.v-data-table td {
  /*word-wrap: break-word;*/
  white-space: nowrap;
  /* min-width: 170px; */
}

/* Loader */
.loader-container {
  position: fixed;
  /* Para que el loader esté por encima de todo el contenido */
  top: 0;
  left: 0;
  width: 100%;
  /* Ocupa todo el ancho del viewport */
  height: 100%;
  /* Ocupa todo el alto del viewport */
  display: flex;
  justify-content: center;
  /* Centra horizontalmente */
  align-items: center;
  /* Centra verticalmente */
  background-color: rgba(255, 255, 255, 0.5);
  /* Fondo semitransparente */
  z-index: 9999;
  /* Coloca el loader por encima de todo el contenido */
}

.loader {
  width: 120px;
  height: 60px;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  background: #fff;
  filter: blur(5px) contrast(10) hue-rotate(60deg);
  mix-blend-mode: darken;
}

.loader:before,
.loader:after {
  content: "";
  width: 40px;
  border-radius: 50%;
  background: #ff00ff;
  animation: l3 1s infinite alternate;
}

.loader:after {
  --s: -1;
}

@keyframes l3 {

  90%,
  100% {
    transform: translate(calc(var(--s, 1)*30px))
  }
}
</style>
