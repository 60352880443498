<template>
    <v-card class="mx-auto my-4" max-width="1800">
        <v-card-title>
            Expense Record Form
        </v-card-title>
        <v-card-text>
            <v-form v-model="valid" ref="form">
                <v-container>
                    <v-row>
                        <v-col cols="4" md="4">
                            <!-- Instructor -->
                            <v-text-field v-model="form.created_by" readonly></v-text-field>
                        </v-col>
                    </v-row>

                    <v-divider v-if="!form.prorated" class="mt-4 mb-6"></v-divider>
                    <v-row v-if="!form.prorated">
                        <!-- Checkbox para seleccionar la opción -->
                        <v-col cols="4">
                            <v-checkbox v-model="showFileInput" label="Import Expense File"></v-checkbox>
                        </v-col>
                    </v-row>
                    <v-divider v-if="!showFileInput" class="mt-4 mb-6"></v-divider>
                    <v-row v-if="!showFileInput">
                        <!-- Checkbox prorated -->
                        <v-col cols="4">
                            <v-checkbox v-model="form.prorated" label="Prorated"></v-checkbox>
                        </v-col>
                    </v-row>
                    <!-- PRORATED -->
                    <v-row v-if="form.prorated">
                        <v-col cols="2">
                            <v-text-field v-model="amount_prorated" label="Total Amount" type="number"
                                step="0.1"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row v-if="showFileInput">
                        <!-- File input que se muestra cuando el checkbox está seleccionado -->
                        <v-col cols="3">
                            <v-file-input label="Upload expense records file" accept=".xlsx,.xls"
                                @change="handleFileUpload"></v-file-input><!-- accept=".pdf,.csv,.xlsx,.xls" -->
                        </v-col>

                        <v-col cols="3">
                            <v-file-input v-model="receipts_files" label="Receipts (Upload)"
                                prepend-icon="mdi-paperclip" accept="/*"
                                multiple></v-file-input><!-- @change="receiptsSelected" :rules="[rules.required]" required -->
                        </v-col>
                        <!-- Botón morado con icono que dice 'Import' -->
                        <v-col cols="12">
                            <v-btn color="purple" dark @click="importExpenses">
                                <v-icon left>mdi-import</v-icon>
                                Import
                            </v-btn>
                        </v-col>
                    </v-row>
                    <!-- Form expense -->
                    <v-divider v-if="!showFileInput" class="mt-4 mb-6"></v-divider>
                    <div v-if="!showFileInput">
                        <v-row>
                            <v-col cols="12">
                                <!-- Radio Button Group -->
                                <v-radio-group v-model="selection" row :readonly="form.status === true">
                                    <v-radio label="Vehicle" value="vehicle"></v-radio>
                                    <v-radio label="Management" value="management"></v-radio>
                                </v-radio-group>
                            </v-col>
                            <v-col cols="3">
                                <v-combobox v-if="showCombobox" v-model="selectedItem" :items="currentItems"
                                    :label="currentLabel" :rules="[rules.required]" required return-object clearable
                                    outlined :item-text="itemText" :key="selection"
                                    :readonly="form.status === true"></v-combobox>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col v-if="selection === 'vehicle'" cols="3" md="3">
                                <!-- Instructors -->
                                <v-combobox v-model="instructorSelected" :items="instructors" item-text="driver_name"
                                    label="Instructor" return-object :rules="[rules.required]" required
                                    :readonly="form.status === true"></v-combobox>
                            </v-col>
                            <v-col v-if="!form.prorated" cols="3" md="3">
                                <!-- Location -->
                                <v-combobox v-model="locationSelected" :items="itemLocations" item-text="name"
                                    label="Location" return-object :rules="[rules.required]" required
                                    :readonly="form.status === true"></v-combobox>
                            </v-col>

                        </v-row>
                        <v-row v-if="!form.prorated">
                            <v-col cols="3" md="3">
                                <!-- Department -->
                                <v-combobox v-model="departmentSelected" :items="itemDepartments" label="Department"
                                    :rules="[rules.required]" required return-object item-text="name"
                                    :readonly="form.status === true"></v-combobox><!-- :readonly="selection === 'vehicle' || form.status === true" -->
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="3" md="3">
                                <!-- Expense Date -->
                                <v-menu ref="expense_date" v-model="dateMenu" :close-on-content-click="false"
                                    :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="form.expense_date" label="Expense Date"
                                            prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"
                                            :rules="[rules.required]" required
                                            :readonly="form.status === true"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="form.expense_date" @input="dateMenu = false"
                                        :readonly="form.status === true"></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="3" md="3">
                                <!-- Payment Method -->
                                <v-select v-model="form.payment_method" :items="paymentMethods" label="Payment Method"
                                    :rules="[rules.required]" required
                                    :readonly="selection === 'vehicle' || form.status === true"></v-select>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="3" md="3">
                                <!-- Card Number -->
                                <v-combobox v-model="cardSelected" :items="itemCards" label="Card Number"
                                    :rules="[rules.required]" required return-object :item-text="formatCardNumber"
                                    :readonly="selection === 'vehicle' || form.status === true"></v-combobox>
                            </v-col>
                            <v-col cols="3" md="3">
                                <!-- Card Account -->
                                <v-text-field v-model="form.card_account.account_name" label="Card Account"
                                    :rules="[rules.required]" required readonly></v-text-field>
                            </v-col>
                        </v-row>
                    </div>
                    <!-- TYPE OF EXPENSES FORM -->
                    <div>
                        <v-divider class="mt-4 mb-6"></v-divider>
                        <h4>TYPE OF EXPENSE</h4>
                        <v-divider v-if="!showFileInput" class="mt-4 mb-6"></v-divider>
                        <v-row v-if="!showFileInput">
                            <v-col cols="12">
                                <div
                                    v-if="form.status === false || (EditedIndex !== '' && form.expense_type.type.length === 0)">
                                    <v-row
                                        v-if="EditedIndex === '' || (EditedIndex !== '' && form.expense_type.type.length === 0)">
                                        <v-col cols="3">
                                            <v-combobox v-model="selectedSupplier" :items="suppliers"
                                                item-text="contact_name" label="Supplier" clearable outlined
                                                return-object :readonly="form.status === true"></v-combobox>
                                        </v-col>
                                        <v-col cols="3">
                                            <v-combobox v-model="selectedExpenseType" :items="expenseTypes"
                                                :item-text="displayText" item-value="account_id"
                                                label="Select Expense Type" clearable outlined return-object
                                                :readonly="form.status === true"></v-combobox>
                                        </v-col>
                                    </v-row>

                                    <v-divider class="mt-4 mb-6"></v-divider>

                                    <v-row
                                        v-if="EditedIndex === '' || (EditedIndex !== '' && form.expense_type.type.length === 0)">
                                        <!-- Price -->
                                        <v-col v-if="!form.prorated" cols="2">
                                            <v-text-field v-model="type_of_expense.amount" label="Amount" type="number"
                                                step="0.1"></v-text-field>
                                        </v-col>
                                        <v-col v-if="!form.prorated" cols="2">
                                            <v-text-field v-model="type_of_expense.discount" label="Discount"
                                                type="number" step="0.1"></v-text-field>
                                        </v-col>
                                        <v-col v-if="!form.prorated" cols="2">
                                            <v-file-input v-model="type_of_expense.files" label="Ticket (Upload)"
                                                prepend-icon="mdi-paperclip" @change="onFileSelected" accept="/*"
                                                multiple></v-file-input><!-- :rules="[rules.required]" required -->
                                        </v-col>
                                    </v-row>
                                    <v-row
                                        v-if="EditedIndex === '' || (EditedIndex !== '' && form.expense_type.type.length === 0)">
                                        <v-col cols="6">
                                            <v-textarea v-model="type_of_expense.description" label="Description"
                                                :readonly="form.status === true"></v-textarea><!-- :rules="[rules.required]" required -->
                                        </v-col>
                                        <v-col cols="1" class="align-self-end mb-5">
                                            <v-btn v-if="!form.prorated" :disabled="!isFormComplete"
                                                @click="addItem_manual" :class="buttonClasses" icon>
                                                <v-icon class="white--text icon-thick">mdi-plus</v-icon>
                                            </v-btn>
                                            <v-btn v-if="form.prorated" color="teal" dark @click="prorate" class="mt-3">
                                                <v-icon left>mdi-chart-pie</v-icon>
                                                Prorate
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                    <!-- EXPENSE TYPE TABLE EXCEL-->
                    <v-divider v-if="showFileInput" class="mt-4 mb-"></v-divider>
                    <v-row v-if="showFileInput">
                        <v-col cols="12">
                            <template>
                                <v-simple-table>
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th class="text-left"
                                                    style="color: #000; font-weight: bold !important; font-size: 14px;">
                                                    Date
                                                </th>
                                                <th class="text-left"
                                                    style="color: #000; font-weight: bold !important; font-size: 14px;">
                                                    Location
                                                </th>
                                                <th class="text-left"
                                                    style="color: #000; font-weight: bold !important; font-size: 14px;">
                                                    Card Number
                                                </th>
                                                <th class="text-left"
                                                    style="color: #000; font-weight: bold !important; font-size: 14px;">
                                                    Account Name
                                                </th>
                                                <th class="text-left"
                                                    style="color: #000; font-weight: bold !important; font-size: 14px;">
                                                    Vehicle Name
                                                </th>
                                                <th class="text-left"
                                                    style="color: #000; font-weight: bold !important; font-size: 14px;">
                                                    Management Name
                                                </th>
                                                <th class="text-left"
                                                    style="color: #000; font-weight: bold !important; font-size: 14px;">
                                                    Supplier
                                                </th>
                                                <th class="text-left"
                                                    style="color: #000; font-weight: bold !important; font-size: 14px;">
                                                    Type
                                                </th>
                                                <th class="text-left"
                                                    style="color: #000; font-weight: bold !important; font-size: 14px;">
                                                    Description
                                                </th>
                                                <th class="text-left"
                                                    style="color: #000; font-weight: bold !important; font-size: 14px;">
                                                    Amount
                                                </th>
                                                <th class="text-left"
                                                    style="color: #000; font-weight: bold !important; font-size: 14px;">
                                                    Discount
                                                </th>
                                                <th class="text-left"
                                                    style="color: #000; font-weight: bold !important; font-size: 14px;">
                                                    Total
                                                </th>
                                                <th class="text-left"
                                                    style="color: #000; font-weight: bold !important; font-size: 14px;">
                                                    Receipts
                                                </th>
                                                <th class="text-left"
                                                    style="color: #000; font-weight: bold !important; font-size: 14px;">
                                                    Actions
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody v-if="expensesArray.length > 0">
                                            <tr v-for="(item, index) in expensesArray" :key="index"
                                                v-if="item && item.amount !== undefined">
                                                <td style="white-space: normal; word-wrap: break-word;">
                                                    {{ item?.expense_date || 'N/A' }}
                                                </td>
                                                <td style="white-space: normal; word-wrap: break-word;">
                                                    {{ item?.location || 'N/A' }}
                                                </td>
                                                <td style="white-space: normal; word-wrap: break-word;">
                                                    {{ item?.card_number || 'N/A' }}
                                                </td>
                                                <td style="white-space: normal; word-wrap: break-word;">
                                                    {{ item?.card_account?.account_name || 'N/A' }}
                                                </td>
                                                <td style="white-space: normal; word-wrap: break-word;">
                                                    {{ item?.vehicle_name || 'N/A' }}
                                                </td>
                                                <td style="white-space: normal; word-wrap: break-word;">
                                                    {{ item?.management_name || 'N/A' }}
                                                </td>
                                                <td style="white-space: normal; word-wrap: break-word;">
                                                    {{ item?.expense_type?.type[0].supplier?.contact_name || 'N/A' }}
                                                </td>
                                                <td style="white-space: normal; word-wrap: break-word;">
                                                    {{ item?.expense_type?.type[0].type?.account_code || 'N/A' }} - {{
                                                        item?.expense_type?.type[0].type?.account_name || 'N/A' }}
                                                </td>
                                                <td style="white-space: normal; word-wrap: break-word;">
                                                    {{ item?.expense_type?.type[0].description || 'N/A' }}
                                                </td>
                                                <td>
                                                    $ {{ parseFloat(item.expense_type?.type[0].amount).toFixed(2) }}
                                                </td>
                                                <td>
                                                    $ {{ parseFloat(item?.expense_type?.type[0].discount ||
                                                        0).toFixed(2) }}
                                                </td>
                                                <td>
                                                    $ {{ parseFloat(item?.expense_type?.type[0].total || 0).toFixed(2)
                                                    }}
                                                </td>
                                                <td style="white-space: normal; word-wrap: break-word;">
                                                    <v-icon
                                                        v-for="(file, fileIndex) in item?.expense_type?.type[0].files || []"
                                                        :key="fileIndex" color="blue" @click="openFileDialog(file)">
                                                        mdi-file
                                                    </v-icon>
                                                </td>
                                                <td>
                                                    <v-btn icon @click="removeExpense_fromExpenses(index)">
                                                        <v-icon color="red">mdi-delete</v-icon>
                                                    </v-btn>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <template v-else>
                                            <tr>
                                                <td colspan="7" style="text-align: center;">No expenses
                                                    available</td>
                                            </tr>
                                        </template>
                                    </template>
                                </v-simple-table>
                                <!-- Dialog for file preview -->
                                <v-dialog v-model="dialog" max-width="1000px" max-height="90vh">
                                    <v-card style="height: 85vh;">
                                        <v-card-title class="headline" v-if="selectedFile && selectedFile.name">
                                            {{ selectedFile.name }}
                                        </v-card-title>
                                        <v-card-text style="height: calc(85vh - 50px); overflow-y: auto;">
                                            <template v-if="selectedFile && isImage(selectedFile)">
                                                <img :src="selectedFile.preview" alt="Image Preview"
                                                    style="max-width: 100%;" />
                                            </template>
                                            <template v-else-if="selectedFile && isPdf(selectedFile)">
                                                <embed :src="selectedFile.preview" type="application/pdf"
                                                    style="width: 100%; height: 100%;" />
                                            </template>
                                            <template v-else-if="selectedFile && isText(selectedFile)">
                                                <iframe :src="selectedFile.preview" style="width: 100%; height: 100%;"
                                                    frameborder="0"></iframe>
                                            </template>
                                            <template v-else>
                                                <p>Preview not available for this file type. You can download it using
                                                    the link below.</p>
                                                <v-btn v-if="selectedFile" :href="selectedFile.preview" target="_blank"
                                                    download>Download File</v-btn>
                                            </template>
                                        </v-card-text>
                                    </v-card>
                                </v-dialog>
                                <!-- Dialog for file preview -->
                                <!-- <v-dialog v-model="dialog" max-width="600px">
                                    <v-card>
                                        <v-card-title class="headline" v-if="selectedFile">{{ selectedFile.name
                                            }}</v-card-title>
                                        <v-card-text>                                            
                                            <img v-if="isImage(selectedFile)" :src="selectedFile.preview"
                                                alt="Image Preview" style="max-width: 100%;" />
                                        </v-card-text>
                                    </v-card>
                                </v-dialog> -->
                            </template>
                        </v-col>
                    </v-row>
                    <v-divider v-if="!showFileInput && !form.prorated" class="mt-4 mb-6"></v-divider>
                    <!-- EXPENSE TYPE TABLE ONE BY ONE MANUAL-->
                    <v-row v-if="!showFileInput || form.prorated">
                        <v-col cols="12">
                            <template>
                                <v-simple-table>
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th class="text-left"
                                                    style="color: #000; font-weight: bold !important; font-size: 14px;">
                                                    Location
                                                </th>
                                                <th class="text-left"
                                                    style="color: #000; font-weight: bold !important; font-size: 14px;">
                                                    Supplier
                                                </th>
                                                <th class="text-left"
                                                    style="color: #000; font-weight: bold !important; font-size: 14px;">
                                                    Type
                                                </th>
                                                <th class="text-left"
                                                    style="color: #000; font-weight: bold !important; font-size: 14px;">
                                                    Description
                                                </th>
                                                <th v-if="form.prorated" class="text-left"
                                                    style="color: #000; font-weight: bold !important; font-size: 14px;">
                                                    Porcentage %
                                                </th>
                                                <th class="text-left"
                                                    style="color: #000; font-weight: bold !important; font-size: 14px;">
                                                    Amount
                                                </th>
                                                <th class="text-left"
                                                    style="color: #000; font-weight: bold !important; font-size: 14px;">
                                                    Discount
                                                </th>
                                                <th class="text-left"
                                                    style="color: #000; font-weight: bold !important; font-size: 14px;">
                                                    Total
                                                </th>
                                                <th class="text-left"
                                                    style="color: #000; font-weight: bold !important; font-size: 14px;">
                                                    Receipts
                                                </th>
                                                <th class="text-left"
                                                    style="color: #000; font-weight: bold !important; font-size: 14px;">
                                                    Actions
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody v-if="form.expense_type?.type?.length > 0">
                                            <tr v-for="(item, index) in form.expense_type.type" :key="index"
                                                v-if="item && item.amount !== undefined">
                                                <td style="white-space: normal; word-wrap: break-word;">
                                                    {{ item?.location || 'N/A' }}
                                                </td>
                                                <td style="white-space: normal; word-wrap: break-word;">
                                                    {{ item?.supplier?.contact_name || 'N/A' }}
                                                </td>
                                                <td style="white-space: normal; word-wrap: break-word;">
                                                    {{ item?.type?.account_code || 'N/A' }} - {{
                                                        item?.type?.account_name || 'N/A' }}
                                                </td>
                                                <td style="white-space: normal; word-wrap: break-word;">
                                                    {{ item?.description || 'N/A' }}
                                                </td>
                                                <td v-if="form.prorated"
                                                    style="white-space: normal; word-wrap: break-word;">
                                                    {{ parseFloat(item?.porcentage || 0).toFixed(2) }} %
                                                </td>
                                                <td>
                                                    $ {{ parseFloat(item.amount).toFixed(2) }}
                                                </td>
                                                <td>
                                                    $ {{ parseFloat(item?.discount || 0).toFixed(2) }}
                                                </td>
                                                <td>
                                                    $ {{ parseFloat(item?.total || 0).toFixed(2) }}
                                                </td>

                                                <td style="white-space: normal; word-wrap: break-word;">
                                                    <v-icon
                                                        v-for="(file, fileIndex) in (form.uuid && !item.files ? item.files_names : item.files) || []"
                                                        :key="fileIndex" color="blue"
                                                        @click="openFileDialogS3Bucket(file, form.uuid)"><!-- v-for="(file, fileIndex) in (form.uuid ? item.files_names : item.files) || []" -->
                                                        mdi-file
                                                    </v-icon>
                                                </td>
                                                <td>
                                                    <v-btn icon @click="removeExpense(index)"
                                                        :disabled="form.status === true">
                                                        <v-icon color="red">mdi-delete</v-icon>
                                                    </v-btn>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <template v-else>
                                            <tr>
                                                <td colspan="7" style="text-align: center;">No expenses
                                                    available</td>
                                            </tr>
                                        </template>
                                    </template>
                                </v-simple-table>
                                <!-- Dialog for file preview -->
                                <v-dialog v-model="dialog" max-width="1000px" max-height="90vh">
                                    <v-card style="height: 85vh;">
                                        <v-card-title class="headline" v-if="selectedFile && selectedFile.name">
                                            {{ selectedFile.name }}
                                        </v-card-title>
                                        <v-card-text style="height: calc(85vh - 50px); overflow-y: auto;">
                                            <template v-if="selectedFile && isImage(selectedFile)">
                                                <img :src="selectedFile.preview" alt="Image Preview"
                                                    style="max-width: 100%;" />
                                            </template>
                                            <template v-else-if="selectedFile && isPdf(selectedFile)">
                                                <embed :src="selectedFile.preview" type="application/pdf"
                                                    style="width: 100%; height: 100%;" />
                                            </template>
                                            <template v-else-if="selectedFile && isText(selectedFile)">
                                                <iframe :src="selectedFile.preview" style="width: 100%; height: 100%;"
                                                    frameborder="0"></iframe>
                                            </template>
                                            <template v-else>
                                                <p>Preview not available for this file type. You can download it using
                                                    the link below.</p>
                                                <v-btn v-if="selectedFile" :href="selectedFile.preview" target="_blank"
                                                    download>Download File</v-btn>
                                            </template>
                                        </v-card-text>
                                    </v-card>
                                </v-dialog>

                                <!-- Diálogo para vista previa del archivo S3-->
                                <v-dialog v-model="fileDialog" max-width="1000px">
                                    <v-card>
                                        <!-- <v-card-title>Vista previa del archivo</v-card-title> -->
                                        <v-card-text>
                                            <div v-if="selectedFileUrl && isS3Image(selectedFileUrl)">
                                                <v-img :src="selectedFileUrl" max-width="100%" />
                                            </div>
                                            <div v-else-if="selectedFileUrl && isS3PDF(selectedFileUrl)">
                                                <embed :src="selectedFileUrl" width="100%" height="800px"
                                                    type="application/pdf" />
                                            </div>
                                            <div v-else>
                                                <p>No File</p>
                                            </div>
                                        </v-card-text>
                                    </v-card>
                                </v-dialog>
                            </template>
                        </v-col>
                    </v-row>
                    <v-divider class="mt-4 mb-6"></v-divider>
                    <!-- Preview of S3 files (presigned URLs) -->
                    <v-row v-show="presignedUrls.length">
                        <v-col v-for="(url, index) in presignedUrls" :key="index" cols="12" md="2">
                            <v-card>
                                <v-card-actions class="delete-icon-wrapper" v-if="form.status === false">
                                    <v-btn icon @click.stop="removeS3File(index)">
                                        <v-icon color="red">mdi-delete</v-icon>
                                    </v-btn>
                                </v-card-actions>
                                <v-img :src="url" aspect-ratio="1" class="zoomable-image" contain
                                    @click="showZoom(url)" />
                            </v-card>
                        </v-col>
                    </v-row>
                    <!-- Zoomed Image Modal -->
                    <v-dialog v-model="zoomDialog" max-width="600px">
                        <v-img :src="zoomedImage" class="zoomed-image" contain></v-img>
                    </v-dialog>
                </v-container>
            </v-form>
        </v-card-text>
        <v-card-actions v-if="form.status === false">
            <!-- Botón DELETE alineado a la izquierda con color "red" -->
            <v-btn v-if="EditedIndex != ''" color="red" @click="deleteItem(EditedIndex)">
                DELETE
            </v-btn>
            <!-- Spacer para empujar los siguientes botones hacia la derecha -->
            <v-spacer></v-spacer>
            <!-- Botón SAVE con color "success" -->
            <v-btn color="success" @click="submit">
                SAVE
            </v-btn>
            <!-- Botón SAVE AND VALIDATE con color "primary" -->
            <!-- <v-btn color="primary" @click="save_validate">
                SAVE AND VALIDATE
            </v-btn> -->
            <!-- <v-btn color="primary" @click="test_form">
                TEST
            </v-btn> -->
        </v-card-actions>
        <div class="loader-container" v-if="showLoader">
            <div class="loader"></div>
        </div>
    </v-card>
</template>

<script>
import { getItemDepartments } from '../../utils/variables'
import { getLocations } from '../../utils/locations'
import { getAllCreditCardManagements } from '../../utils/credit_card_managements_CRUD'
import { createExpense, updateExpense, createExpense_ZOHO, checkAuthorizationStatus, updateStatus, deleteExpense, getDriverNames } from '../../utils/Accounting/expense_record_CRUD'
import { checkFiles } from '../../utils/Accounting/expense_record_CRUD'
import { fetchZohoChartOfAccounts } from '../../utils/Accounting/ZOHO/zoho_chart_of_accounts_CRUD'
import { fetchActiveEmployeesWithVehicle } from '../../utils/employees_CRUD'
import _ from 'lodash';
import { getNewExpenseData } from '../../utils/Accounting/ZOHO/zoho_expense_json'
import * as XLSX from 'xlsx'; // Importamos la librería XLSX
import { formStructure, typeOfExpenseStructure } from '../../utils/Accounting/ZOHO/ZohoExpense_json'
import { generatePublicURL } from '../../utils/presignedUrl'
import { getPorcentages, deletePorcentage } from '../../utils/Accounting/porcentages_CRUD.js'
import { getReportingTags } from '../../utils/variables.js'
import { parse, format } from 'date-fns';



export default {
    props: {
        EditedIndex: String,
        Item: Object,
        Employee_number: String,
        index: String,
        suppliers: Array
    },
    data() {
        return {
            // Tag            
            tags: {
                is_tag_mandatory: false,
                tag_name: "",
                tag_id: null,
                tag_option_id: "",
                tag_option_name: ""
            },
            // Prorated            
            //prorated: false,
            amount_prorated: null,
            prorated_items: [],
            // Dialog S3
            fileDialog: false,
            selectedFileUrl: null,
            zoomedFileUrl: '',
            expensesArray: [],
            excel_json: null,
            receipts_files: [],
            showFileInput: false,
            // FILES
            dialog: false,
            selectedFile: null,
            //////////////
            instructors: [],
            form_response: null,
            // S3
            presignedUrls: [],
            // Management
            itemManagement: [],
            managementSelected: null,
            // Instructor Selected
            instructorSelected: null,
            itemInstructor: [],

            // Radio Bootoms
            selection: 'vehicle',

            /* supliers: [], */
            selectedSupplier: null,
            // LOADER
            showLoader: false,
            ///////////////////
            //departmentSelected: null,
            departmentSelected: 'Fleet',
            cardSelected: null,
            locationSelected: '',
            itemCards: [],
            itemLocations: [],
            itemDepartments: [],
            itemVehicles: [],
            itemVehicles_tags: [],
            carSelected: '',
            zoomDialog: false,
            zoomedImage: null,
            zoomedIndex: null,  // Índice del archivo actualmente haciendo zoom
            ticket_files: [],
            type_of_expense: {
                type: {
                    account_code: '',
                    account_id: '',
                    account_name: '',
                    account_type: '',
                    description: '',
                },
                amount: null,
                discount: null,
                total: null,
                description: '',
                files_names: [],
                files: [],
                supplier: null,
                porcentage: null,
                tags: [],
                location: '',
            },
            default_type_of_expense: {
                type: {
                    account_code: '',
                    account_id: '',
                    account_name: '',
                    account_type: '',
                    description: '',
                },
                amount: null,
                discount: null,
                total: null,
                files_names: [],
                files: [],
                supplier: null,
                porcentage: null,
                tags: [],
                location: '',
            },
            selectedExpenseType: null,
            valid: false,
            dateMenu: false,
            new_ticket_name_s3: [],
            form: {
                instructor: '',
                vehicle_name: '',
                location: '',
                department: "Fleet",
                expense_date: "",
                payment_method: "",
                card_number: {},
                card_account: {},
                expense_type: {
                    type: [

                    ]
                },
                ticket_name_s3: [],
                supplier: null,
                description: "",
                amount: null,
                management_name: null,
                driver_name: '',
                status: false,
                created_by: '',
                prorated: false,
            },
            defaultForm: {
                instructor: '',
                vehicle_name: '',
                location: '',
                department: "Fleet",
                expense_date: "",
                payment_method: "",
                card_number: {},
                card_account: {},
                expense_type: {
                    type: [
                    ]
                },
                ticket_name_s3: [],
                supplier: null,
                description: "",
                amount: null,
                management_name: null,
                driver_name: '',
                status: false,
                created_by: '',
                prorated: false,
            },
            paymentMethods: ["Credit", "Debit", "Cash", "Check"],
            cardNumbers: ["**** **** **** 1234", "**** **** **** 5678", "**** **** **** 9101"],
            expenseTypes: [],
            rules: {
                required: value => !!value || "Required.",
                isNumber: value => !isNaN(parseFloat(value)) && isFinite(value) || "Must be a number.",
            }
        };
    },
    computed: {
        showCombobox() {
            return this.selection !== null;
        },
        currentItems() {
            //this.clearFields();
            if (this.selection === 'management') {
                return this.itemManagement;
            } else if (this.selection === 'vehicle') {
                return this.itemVehicles;
            }
            return [];
        },
        currentLabel() {
            if (this.selection === 'management') {
                return 'Select User';
            } else if (this.selection === 'vehicle') {
                return 'Select Vehicle';
            }
            return '';
        },
        selectedItem: {
            get() {
                if (this.selection === 'management') {
                    return this.managementSelected;
                } else if (this.selection === 'vehicle') {
                    return this.carSelected;
                }
                return null;
            },
            set(value) {
                if (this.selection === 'management') {
                    this.managementSelected = value;
                } else if (this.selection === 'vehicle') {
                    this.carSelected = value;
                }
            }
        },
        itemText() {
            if (this.selection === 'management') {
                return this.getManagementFullName;
            } else if (this.selection === 'vehicle') {
                return this.getVehicleName;
            }
            return 'name';
        },
        displayText() {
            return expense => `${expense.account_code} - ${expense.account_name}`;
        },
        formattedTotalCost() {
            // Formatea el total_cost con dos decimales
            // Verifica y convierte el total_cost a número
            const cost = parseFloat(this.form.amount);
            return !isNaN(cost) ? cost.toFixed(2) : "0.00";
        },
        buttonClasses() {
            return {
                'light-green-background': this.isFormComplete,
                'disabled-background': !this.isFormComplete,
            };
        },
        isFormComplete() {
            return (
                this.selectedSupplier &&
                this.selectedExpenseType &&
                this.type_of_expense.type &&
                this.type_of_expense.amount &&
                this.type_of_expense.description
            );
        },
        /* findDepartmentByName(departments, departmentName) {
            if (!Array.isArray(departments)) {
                console.warn("departments no es un array:", departments);
                return null;
            }
            return departments.find(department => department.name === departmentName) || null;
        }, */
    },
    watch: {
        departmentSelected(val) {
            try {
                console.log("WATCH-Department Obj: ", val);
                if (!Array.isArray(this.itemDepartments) || this.itemDepartments.length === 0) {
                    console.warn("itemDepartments aún no está cargado");
                    return;
                }
                if (typeof val === 'object') {
                    this.form.department = val;
                } else {
                    this.form.department = this.findDepartmentByName(this.itemDepartments, val);
                    //console.log("WATCH-this.form.department: ", this.form.department);
                }
            } catch (error) {
                console.warn("Error/departmentSelected: ", error);
            }

        },
        "form.prorated"(val) {
            ////////////console.log("prorated: ", val);
            if (val) {
                this.selection = 'management';
                this.department = '';
            }
        },
        showFileInput(val) {
            if (!val) {
                this.expensesArray = [];
                this.excel_json = null;
                this.receipts_files = [];
            }
        },
        instructorSelected(val) {
            ////////console.log("instructorSelected: ", val);
            if (typeof val !== 'object') {
                this.form.instructor = val;
            } else {
                this.form.instructor = val.driver_name;
            }

            ////////////////console.log("<<<<<<<<<<<<<<<<<<<<this.form.instructor: ", this.form.instructor);
        },
        selection(val) {
            ////////////////console.log("selection: ", val);
            if (val === 'vehicle') {
                this.clearFields();
            }
        },
        selectedItem(val) {
            try {
                ////console.log("userSelected: ", val);
                if (typeof val === 'object') {
                    if (val != null && this.selection === 'management') {

                        this.form.vehicle_name = null;
                        //this.form.management_name = val.assigned_user;
                        this.form.management_name = {
                            type: val.assigned_user.type,
                            employee_number: val.assigned_user.name.employee_number,
                            name: val.assigned_user.name.name,
                            last_name: val.assigned_user.name.last_name,
                            last_4_digits: val.last_4_digits,
                            ledger_account: {
                                account_code: val.ledger_account.account_code,
                                account_id: val.ledger_account.account_id,
                                account_name: val.ledger_account.account_name,
                                account_number: val.ledger_account.account_number,
                                account_type: val.ledger_account.account_type,
                                currency_code: val.ledger_account.currency_code,
                                currency_id: val.ledger_account.currency_id,
                            },//val.ledger_account,
                            company: val.company,
                        }
                        //////////////////console.log("this.management_name: ", this.form.management_name);
                        this.cardSelected = `**** **** **** ${val.last_4_digits}`;
                        this.form.payment_method = val.type;
                    } else if (val != null && this.selection === 'vehicle') {

                        this.carSelected = val;//.assigned_user.name.name;
                        ////////////////console.log("this.carSelected: ", this.carSelected);
                    } /* else {
                        
                        this.clearFields();
                    } */
                } else {
                    ////////////////console.log("VAL no object...");
                }

            } catch (error) {
                console.error("Error/selectedItem: ", error);
            }
        },
        /* CAR / VEHICLE */
        carSelected(val) {
            try {
                //console.log("carSelected/val: ", val);
                if (val) {
                    //////////////////console.log("this.itemVehicles: ", this.itemVehicles);
                    if (typeof val !== 'object') {
                        // UPDATE
                        let vehicleObject = this.itemVehicles.find(vehicle => vehicle.name === val) || false;
                        //////////////////console.log("vehicleObject: ", vehicleObject);
                        ////////////////////console.log("carSelected/val.name: ", val.name);
                        ////////////////////console.log("carSelected/val.name: ", val.name);
                        if (vehicleObject) {
                            //this.form.vehicle_name = vehicleObject;
                            const result = this.findLast4DigitsByName(this.itemCards, vehicleObject.name);
                            //////////////////console.log("result: ", result);
                            //////////////////console.log("this.itemCards: ", this.itemCards);
                            this.cardSelected = `**** **** **** ${result}`;
                            this.form.card_account = this.findLedgerAccount(this.itemCards, vehicleObject.name);
                            //////////////////console.log("this.form.card_account: ", this.form.card_account);
                            this.form.payment_method = this.findPaymentMethod(this.itemCards, vehicleObject.name);
                            //////////////////console.log("this.form.payment_method: ", this.form.payment_method);
                        } else {
                            //this.clearFields();
                        }
                    } else {
                        // CREATE
                        //this.form.vehicle_name = val.name;
                        this.form.vehicle_name = val.assigned_user.name.name;

                        const result = this.findLast4DigitsByName(this.itemCards, val.assigned_user.name.name);
                        //////////////////console.log("result: ", result);
                        //////////////////console.log("this.itemCards: ", this.itemCards);
                        if (result != null) {
                            //this.form.card_number = `**** **** **** ${result}`;
                            this.cardSelected = `**** **** **** ${result}`;
                            this.form.card_account = this.findLedgerAccount(this.itemCards, val.assigned_user.name.name);
                            //////////////////console.log("this.form.card_account: ", this.form.card_account);
                            this.form.payment_method = this.findPaymentMethod(this.itemCards, val.assigned_user.name.name);
                            //this.form.driver_name = val.assigned_user.name.driver_name;
                            this.form.instructor = val.assigned_user.name.driver_name;
                            ////////////////console.log("this.form.instructor: ", this.form.instructor);
                            this.instructorSelected = val.assigned_user.name.driver_name;
                            //////////////////console.log("this.form.payment_method: ", this.form.payment_method);
                        }
                    }
                }
            } catch (error) {
                console.error("Error/carSelected: ", error);
            }
        },
        selectedSupplier(val) {
            ////////console.log("selectedSupplier/val: ", val);
            if (typeof val != 'object') {
                /* let suplierObject = this.supliers.find(suply => suply.contact_name === val) || false;
                this.form.supplier = suplierObject; */
            } else {
                //this.form.supplier = val;
                this.type_of_expense.supplier = val;
            }
        },
        /* CARD */
        cardSelected(val) {
            try {
                //////////////////console.log("cardSelected/val: ", val);
                ////////////////////console.log("this.itemCards: ", this.itemCards);
                ////////////////////console.log("typeof val, ", typeof val);
                if (val) {
                    if (typeof val !== 'object') {
                        //////////////////console.log("No object.....")
                        let lastFourDigits = val.slice(-4);
                        ////////////////////console.log("lastFourDigits: ", lastFourDigits);
                        val = this.itemCards.find(card => card.last_4_digits === lastFourDigits);
                        //////////////////console.log("val: ", val);
                        this.form.card_account = {
                            account_name: val.ledger_account.account_name,
                            account_code: val.ledger_account.account_code,
                            account_id: val.ledger_account.account_id,
                        };//val.ledger_account.account_name;
                        //////////////////console.log("this.form.card_account: ", this.form.card_account);
                        this.form.card_number = `**** **** **** ${val.last_4_digits}`;

                    } else {
                        //this.form.card_account = val.ledger_account.account_name;
                        this.form.card_account = {
                            account_name: val.ledger_account.account_name,
                            account_code: val.ledger_account.account_code,
                            account_id: val.ledger_account.account_id,
                        };
                        this.form.card_number = `**** **** **** ${val.last_4_digits}`;
                        this.form.payment_method = val.type;
                    }
                }
            } catch (error) {
                console.error("Error/cardSelected: ", error);
            }
        },
        locationSelected(val) {
            ////////console.log("locationSelected: ", val);
            if (typeof val === 'object') {
                ////////console.log("Object");
                //this.form.location = val.location;
                this.form.location = val.name;
            } else {
                ////////console.log("NO Object");
                this.form.location = val;
            }
        },
        selectedExpenseType(val) {
            ////////console.log("selectedExpenseType/val: ", val);
            if (val != null) {
                //this.type_of_expense.type = val;
                const { account_code, account_id, account_name, description, account_type } = val;
                this.type_of_expense.type.account_code = account_code;
                this.type_of_expense.type.account_id = account_id;
                this.type_of_expense.type.account_name = account_name;
                this.type_of_expense.type.description = description;
                this.type_of_expense.type.account_type = account_type;
                //////////console.log("this.type_of_expense.type: ", this.type_of_expense.type);
            }
        }
    },
    methods: {
        findDepartmentByName(departments, departmentName) {
            if (!Array.isArray(departments)) {
                console.warn("departments no es un array:", departments);
                return null;
            }
            return departments.find(department => department.name === departmentName) || null;
        },
        addItem_manual() {
            const originalFiles = this.type_of_expense.files;
            ////////console.log("originalFiles: ", originalFiles);
            const deepCopy_type_of_expense = JSON.parse(JSON.stringify(this.type_of_expense));
            deepCopy_type_of_expense.files = originalFiles;
            //this.addTags(deepCopy_type_of_expense);            
            console.log("deepCopy_type_of_expense.... ", deepCopy_type_of_expense);
            this.addTag_Department(deepCopy_type_of_expense);
            this.addTag_Location(deepCopy_type_of_expense);
            this.addTag_Vehicle(deepCopy_type_of_expense);

            deepCopy_type_of_expense.location = this.form.location;

            this.form.expense_type.type.push(deepCopy_type_of_expense);
            console.log("this.form.... ", this.form);
            this.calculateTotalAmount();
            this.type_of_expense = JSON.parse(JSON.stringify(this.default_type_of_expense));
            this.selectedExpenseType = null;
            this.selectedSupplier = null;
        },
        prorate_importFile(newForm, amount, code_expense_type, supplierObj) {
            try {
                if (amount > 0) {
                    //let times = 1;

                    this.prorated_items.forEach((item) => {
                        const deepCopy_type_of_expense = JSON.parse(JSON.stringify(typeOfExpenseStructure));
                        const porcentage = item.porcentage;
                        const location = item.location;
                        const expenseObj = this.findExpenseByCodeExpenseType(code_expense_type);
                        const total = this.calculatePercentage(amount, porcentage);
                        newForm.prorated = true;
                        deepCopy_type_of_expense.location = location;
                        deepCopy_type_of_expense.amount = total;
                        deepCopy_type_of_expense.total = total;
                        deepCopy_type_of_expense.discount = 0;
                        deepCopy_type_of_expense.porcentage = porcentage;
                        deepCopy_type_of_expense.type.description = deepCopy_type_of_expense.description;
                        deepCopy_type_of_expense.supplier = newForm.supplier;
                        deepCopy_type_of_expense.type.account_code = expenseObj.account_code;
                        deepCopy_type_of_expense.type.account_id = expenseObj.account_id;
                        deepCopy_type_of_expense.type.account_name = expenseObj.account_name;
                        deepCopy_type_of_expense.type.account_type = expenseObj.account_type;
                        deepCopy_type_of_expense.type.description = newForm.description;
                        deepCopy_type_of_expense.description = newForm.description;
                        deepCopy_type_of_expense.supplier = supplierObj;
                        //console.log("newForm.department: ", newForm.department);
                        //if (newForm.department !== null && newForm.department !== '') {
                        if (newForm.department) {
                            this.tag_department(deepCopy_type_of_expense, newForm.department);
                        }
                        this.tag_location(deepCopy_type_of_expense, location);
                        newForm.expense_type.type.push(deepCopy_type_of_expense);
                    })
                } else {
                    alert("You need to type an amount");
                }
            } catch (error) {
                console.error("prorate_importFile/Error: ", error);
            }
        },
        tag_department(type_of_expense, department) {
            try {
                //console.log("--------> type_of_expense: ", type_of_expense);
                //console.log("--------> department: ", department);
                //console.log("1");
                const department_tag_obj = this.findObjectByName_tags(this.itemDepartments, department);
                //console.log('department_tag_obj: ', department_tag_obj);
                if (department_tag_obj !== null) {
                    const newObjTag_department = this.create_tag_obj(department_tag_obj, 'Departments');
                    //console.log("newObjTag_department: ", newObjTag_department);
                    this.addTagToExpense(type_of_expense, newObjTag_department);
                }
            } catch (error) {
                console.error("Error/tag_department: ", error);
            }

        },
        tag_location(type_of_expense, location) {
            //console.log("2");
            const location_tag_obj = this.findObjectByName_tags(this.itemLocations, location);
            const newObjTag_location = this.create_tag_obj(location_tag_obj, 'Location');
            ////////////console.log("newObjTag_location: ", newObjTag_location);
            this.addTagToExpense(type_of_expense, newObjTag_location);
        },
        prorate() {
            if (this.amount_prorated > 0) {
                //let times = 1;

                this.prorated_items.forEach((item) => {
                    const deepCopy_type_of_expense = JSON.parse(JSON.stringify(this.type_of_expense));
                    const porcentage = item.porcentage;

                    const location = item.location;
                    if (!this.form.prorated) {
                        this.form.location = location;
                    } else {
                        deepCopy_type_of_expense.location = location;
                    }

                    this.form.amount = this.amount_prorated;
                    this.form.department = "General";

                    const total = this.calculatePercentage(this.amount_prorated, porcentage);
                    deepCopy_type_of_expense.amount = total;
                    deepCopy_type_of_expense.discount = 0;
                    deepCopy_type_of_expense.porcentage = porcentage;
                    deepCopy_type_of_expense.type.description = deepCopy_type_of_expense.description;
                    this.addItem(deepCopy_type_of_expense);
                    //times++;
                })
            } else {
                alert("You need to type an amount");
            }
        },
        calculatePercentage(total, percentage) {
            return (total * percentage) / 100;
        },
        importExpenses() {
            try {
                //////console.log("Import action triggered");
                let numExpense = 1;
                //////console.log("<<<<<<<<<<<< this.excel_json: ", this.excel_json);

                if (this.excel_json != null) {
                    this.showLoader = true;

                    this.excel_json.forEach((expense) => {
                        try {
                            // Crear un nuevo objeto basado en la estructura inicial con copia profunda
                            const newForm = JSON.parse(JSON.stringify(formStructure));
                            newForm.created_by = this.Employee_number;
                            //console.log("expense['Department']: ", expense["Department"]);
                            //console.log("AFTER++this.departmentSelected: ", this.departmentSelected);
                            //if (expense["Department"] !== null && expense["Department"] !== undefined && expense["Department"] !== '') {
                            if (expense.hasOwnProperty("Department") && expense["Department"]) {
                                //this.departmentSelected = expense["Department"];                                
                                newForm.department = expense["Department"];
                            } else {
                                newForm.department = this.departmentSelected;
                            }
                            //console.log("BEFORE++this.departmentSelected: ", this.departmentSelected);

                            // Validar si el campo 'Type' existe y si es 'Return', ignorarlo
                            if (expense.hasOwnProperty("Type") && expense["Type"] === "Return") {
                                //////console.log("Ignoring expense with Type 'Return':", expense);
                                return; // Ignorar este objeto y continuar con el siguiente
                            }

                            let expense_description = '';
                            // Validar si el campo 'Description' existe y si es 'CMS AUTO REPAIR', ignorarlo
                            if (expense.hasOwnProperty("Description") && expense["Description"] === "CMS AUTO REPAIR") {
                                //////console.log("Ignoring expense with Description 'CMS AUTO REPAIR':", expense);
                                return; // Ignorar este objeto y continuar con el siguiente
                            } else {
                                expense_description = expense["Description"] || 'No Description';
                            }
                            newForm.description = expense_description;

                            const total = Math.abs(expense["Total"] || 0);//expense["Total"] || 0;
                            newForm.amount = total;
                            const code_expense_type = expense["Code Expense"] || 'Unknown Code';
                            console.log("<<< code_expense_type: ", code_expense_type);
                            const card_number = expense["Card"] || 'Unknown Card';
                            //console.log("card_number: ", card_number);
                            const cardObject = this.findCardTypeByLastDigits(card_number);
                            //console.log("*** cardObject: ", cardObject);
                            if (cardObject.ledger_account.account_type === 'credit_card') {
                                newForm.payment_method = 'Credit';
                            }

                            let transaction_date = null;
                            // Validar el formato de 'Transaction Date'
                            if (expense.hasOwnProperty("Transaction Date")) {
                                const transactionDate = expense["Transaction Date"];
                                // Expresión regular para validar el formato 'dd/MM/yyyy HH:mm:ss'
                                const validDateFormat = /^\d{2}\/\d{2}\/\d{4} \d{2}:\d{2}:\d{2}$/;

                                if (validDateFormat.test(transactionDate)) {
                                    // Si ya tiene el formato esperado
                                    transaction_date = transactionDate;
                                } else {
                                    // Si no tiene el formato esperado, intenta hacer el parseo
                                    try {
                                        const dateParts = transactionDate.split('/'); // Divide la fecha "M/dd/yyyy"
                                        const [month, day, year] = dateParts.map(part => parseInt(part, 10));

                                        // Crea un objeto de fecha y formatea
                                        const parsedDate = new Date(year, month - 1, day);
                                        const formattedDate = parsedDate.toLocaleDateString('en-GB', { timeZone: 'UTC' });
                                        transaction_date = `${formattedDate} 00:00:00`;
                                    } catch (error) {
                                        console.error('Error parsing transaction date:', error);
                                        transaction_date = 'Unknown Date';
                                    }
                                }
                            }
                            // Conversión de fecha y búsqueda de vehículo
                            const expense_date = this.formatDate(transaction_date);
                            newForm.expense_date = expense_date;

                            //let card_user = null;
                            // Extracción de valores con manejo de errores específico
                            //const vehicle_name = expense["Vehicle Number"] || 'Unknown Vehicle';
                            /* let vehicle_name = expense["Vehicle Number"];
                            let vehicleObject = null;
                            //console.log("<<< vehicle_name: ", vehicle_name);
                            //console.log("-------------------------------");
                            if (vehicle_name && vehicle_name !== '' && vehicle_name !== null) {
                                //console.log("vehicle_name: ", vehicle_name);
                                vehicleObject = this.findVehicleByNumber(vehicle_name);
                                //console.log(">>>>>> vehicleObject: ", vehicleObject);
                            } else {
                                if (expense.hasOwnProperty("Card User")) {
                                    card_user = expense["Card User"] || 'Unknown Card User';
                                    //console.log("<<<< card_user: ", card_user);
                                    newForm.management_name = card_user;
                                }
                            } */
                            let vehicleObject = null;
                            if (expense.hasOwnProperty("Vehicle Number")) {
                                let vehicle_name = expense["Vehicle Number"];
                                //let vehicleObject = null;
                                //console.log("<<< vehicle_name: ", vehicle_name);
                                //console.log("-------------------------------");
                                ////console.log("TOTAL: ", total);
                                if (vehicle_name) {/*  && vehicle_name !== '' && vehicle_name !== null && vehicle_name !== undefined */
                                    //console.log("++vehicle_name: ", vehicle_name);
                                    vehicleObject = this.findVehicleByNumber(vehicle_name);
                                    //console.log(">>>>>> vehicleObject: ", vehicleObject);
                                }
                            }
                            const driver_name = vehicleObject?.assigned_user?.name?.driver_name || 'N/A';
                            // Asignar valores al nuevo formulario
                            newForm.instructor = driver_name;
                            if (vehicleObject) {
                                newForm.vehicle_name = vehicleObject?.assigned_user?.name?.name || 'N/A';
                            }

                            let card_user = null;
                            if (expense.hasOwnProperty("Card User")) {
                                card_user = expense["Card User"] || 'Unknown Card User';
                                //console.log("<<<< card_user: ", card_user);
                                newForm.management_name = card_user;
                            }

                            let location_excel_data = '';
                            let location = '';
                            let supplier = '';
                            // Validar si el campo 'Location' existe y si es 'All Locations', imprimir log
                            if (expense.hasOwnProperty("Location") && expense["Location"] === "All Locations") {
                                try {
                                    //console.log("------> ALL LOCATIONS.........");
                                    newForm.expense_type.type = [];
                                    newForm.location = 'All Locations';
                                    newForm.card_number = expense["Card"];
                                    newForm.card_account.account_id = cardObject.ledger_account.account_id;
                                    newForm.card_account.account_code = cardObject.ledger_account.account_code;
                                    newForm.card_account.account_name = cardObject.ledger_account.account_name;
                                    //const supplier = expense["Supplier"];
                                    supplier = expense["Supplier"];
                                    const supplierObj = this.findSupplierByContactName(supplier);
                                    //newForm.department = expense["Department"]                                    
                                    this.prorate_importFile(newForm, total, code_expense_type, supplierObj);
                                    this.addExpenses(newForm);
                                    return;
                                } catch (error) {
                                    console.error("Error - Location: ", error);
                                }
                            } else {
                                //newForm.department = expense["Department"]
                                location_excel_data = expense["Location"] || 'Unknown Location';
                                location = this.findLocationByName(location_excel_data);
                            }

                            let gross_cost = null;
                            if (expense.hasOwnProperty("Gross Cost")) {
                                gross_cost = expense["Gross Cost"] || 0;
                            } else {
                                gross_cost = total;
                            }

                            const discount = expense["Discount"] || 0;
                            //const supplier = expense["Supplier"] || 'Unknown Supplier';
                            supplier = expense["Supplier"] || expense["SUPPLIER"];

                            // Manejo de nombres de archivos con manejo de error
                            let expense_file_names;
                            try {
                                expense_file_names = expense["Name Receipt File"]
                                    ? expense["Name Receipt File"].split(', ').map(name => name.trim()).filter(name => typeof name === 'string')
                                    : [];
                            } catch (fileError) {
                                console.error("Error al procesar 'Name Receipt File':", fileError);
                                expense_file_names = [];
                            }
                            // Validar que vehicleObject esté definido antes de intentar acceder a sus propiedades
                            //const driver_name = vehicleObject?.assigned_user?.name?.driver_name || 'N/A';
                            const supplierObj = this.findSupplierByContactName(supplier);
                            const expenseObj = this.findExpenseByCodeExpenseType(code_expense_type);
                            console.log("expenseObj: ", expenseObj);

                            /* // Asignar valores al nuevo formulario
                            newForm.instructor = driver_name;

                            if (vehicleObject) {
                                newForm.vehicle_name = vehicleObject?.assigned_user?.name?.name || 'N/A';
                            } */
                            newForm.card_number = card_number;
                            newForm.location = location;
                            newForm.created_by = this.Employee_number;

                            // Asignación de datos al tipo de gasto
                            Object.assign(newForm.expense_type.type[0], {
                                type: {
                                    account_code: expenseObj?.account_code || 'Unknown Code',
                                    account_id: expenseObj?.account_id || 'Unknown ID',
                                    account_name: expenseObj?.account_name || 'Unknown Name',
                                    account_type: expenseObj?.account_type || 'Unknown Type',
                                    description: expenseObj?.description || 'No Description'
                                },
                                files_names: expense_file_names,
                                location: location,
                                amount: gross_cost,
                                discount: discount,
                                total: total,
                                description: expense_description,
                                supplier: supplierObj
                            });

                            // Asignación de datos de la cuenta de tarjeta
                            Object.assign(newForm.card_account, {
                                account_id: cardObject?.ledger_account?.account_id || 'Unknown Account ID',
                                account_code: cardObject?.ledger_account?.account_code || 'Unknown Account Code',
                                account_name: cardObject?.ledger_account?.account_name || 'Unknown Account Name'
                            });

                            // Procesar archivos si están disponibles
                            if (expense_file_names.length > 0) {
                                const files = this.searchFiles(this.receipts_files, expense_file_names);
                                newForm.expense_type.type[0].files = files;
                            }

                            // Añadir el nuevo expense al array y agregar tags
                            this.receiptsSelected(newForm);
                            //console.log("BEFORE ADDING TAGS...........");
                            //console.log("newForm: ", newForm);

                            //this.addTags(newForm);
                            this.addTag_Department(newForm);
                            this.addTag_Location(newForm);
                            if (vehicleObject) {
                                this.addTag_Vehicle(newForm);
                            }

                            //console.log("+++++++++++++ newForm: ", newForm);
                            // Agregar el gasto final
                            this.addExpenses(newForm);
                            numExpense++;

                        } catch (expenseError) {
                            console.error(`Error en la importación del gasto número ${numExpense}:`, expenseError);
                        }
                    });
                    this.showLoader = false;
                }
            } catch (error) {
                console.error("importExpenses/Error principal en función:", error.message, "\nStack trace:", error.stack);
            }
        },
        searchFiles(receiptsFiles, fileNames) {
            // Filter the array of File objects and check if their name matches any file names in fileNames array
            return receiptsFiles.filter(file => fileNames.includes(file.name));
        },
        findExpenseByCodeExpenseType(codeExpenseType) {
            try {
                // Verificamos que el parámetro codeExpenseType no sea null o undefined
                if (!codeExpenseType) {
                    console.error("El parámetro codeExpenseType está indefinido o es nulo");
                    return null;
                }

                // Usamos find para buscar el primer objeto cuyo code_expense_type coincida exactamente con codeExpenseType
                const matchingExpense = this.expenseTypes.find(item =>
                    item.account_code && item.account_code.toLowerCase() === codeExpenseType.toLowerCase()
                );

                // Retornamos el objeto encontrado o null si no hay coincidencias
                return matchingExpense || null;
            } catch (error) {
                console.error("findExpenseByCodeExpenseType/Error: ", error);
            }
            /* try {
                // Verificamos que el parámetro codeExpenseType no sea null o undefined
                if (!codeExpenseType) {
                    console.error("El parámetro codeExpenseType está indefinido o es nulo");
                    return null;
                }

                // Usamos find para buscar el primer objeto cuyo code_expense_type coincida parcial o exactamente con codeExpenseType
                const matchingExpense = this.expenseTypes.find(item =>
                    item.account_code && item.account_code.toLowerCase().includes(codeExpenseType.toLowerCase())
                );

                // Retornamos el objeto encontrado o null si no hay coincidencias
                return matchingExpense || null;
            } catch (error) {
                console.error("findExpenseByCodeExpenseType/Error: ", error);
            } */
        },
        findSupplierByContactName(contactName) {
            try {
                // Aseguramos que el parámetro contactName no sea null o undefined
                if (!contactName) {
                    console.error("contactName está indefinido o es nulo");
                    return null;
                }

                // Usamos find para buscar el objeto cuyo contact_name coincida exactamente con contactName
                const matchingSupplier = this.suppliers.find(item => item.contact_name === contactName);

                // Retornamos el objeto completo si se encuentra una coincidencia, o null si no hay coincidencia
                return matchingSupplier || null;
            } catch (error) {
                console.error("findSupplierByContactName/eror: ", error)
            }
        },
        findCardTypeByLastDigits(lastDigits) {
            try {
                //console.log("lastDigits: ", lastDigits);

                // Asegurarnos de que lastDigits no sea undefined o null antes de operar
                if (!lastDigits) {
                    console.error("lastDigits está indefinido o es nulo");
                    return null;
                }

                // Extraemos los últimos 4 dígitos de lastDigits
                //const lastFourDigits = lastDigits.slice(-4);
                // Convertir a string si es un número
                const lastDigitsStr = typeof lastDigits === 'number' ? String(lastDigits) : lastDigits;

                // Validar si ya tiene 4 caracteres o menos y extraer los últimos 4 dígitos si es necesario
                const lastFourDigits = lastDigitsStr.length >= 4 ? lastDigitsStr.slice(-4) : lastDigitsStr;
                //console.log("lastFourDigits: ", lastFourDigits);
                ////console.log(typeof lastFourDigits);
                /* const lastFourDigits = lastDigits.length >= 4 ? lastDigits.slice(-4) : lastDigits;
                ////console.log("lastFourDigits: ", lastFourDigits);
                ////console.log(typeof lastFourDigits); */

                // Función para calcular similitud entre dos cadenas
                const calculateSimilarity = (a, b) => {
                    let matches = 0;
                    for (let i = 0; i < Math.min(a.length, b.length); i++) {
                        if (a[i] === b[i]) matches++;
                    }
                    return matches / Math.max(a.length, b.length);
                };

                // Buscar el objeto con la mayor similitud en last_4_digits
                let bestMatch = null;
                let highestSimilarity = 0;

                this.itemCards.forEach(item => {
                    const similarity = calculateSimilarity(item.last_4_digits, lastFourDigits);
                    if (similarity > highestSimilarity) {
                        highestSimilarity = similarity;
                        bestMatch = item;
                    }
                });

                ////console.log("Best matching card:", bestMatch);

                // Retornar el objeto que tiene el valor más similar de last_4_digits o null si no hay coincidencia
                return bestMatch || null;
                /* //////console.log("lastDigits: ", lastDigits);
                // Asegurarnos de que lastDigits no sea undefined o null antes de operar
                if (!lastDigits) {
                    console.error("lastDigits está indefinido o es nulo");
                    return null;
                }
    
                // Extraemos los últimos 4 dígitos de lastDigits
                const lastFourDigits = lastDigits.slice(-4);
                //////console.log("lastFourDigits: ", lastFourDigits);
    
                // Usamos find para buscar el objeto cuyo last_4_digits coincida con los últimos 4 dígitos de lastDigits
                const matchingCard = this.itemCards.find(item => item.last_4_digits === lastFourDigits);
                //////console.log("matchingCard: ", matchingCard);
    
                // Si encuentra una coincidencia, retornamos el valor de la propiedad 'type'
                return matchingCard;// ? matchingCard.type : null; */
            } catch (error) {
                console.error("findCardTypeByLastDigits/Error: ", error);
            }

        },
        findLocationByName(locationName) {
            //console.log("--------> locationName: ", locationName);
            if (!locationName) return null;

            const target = locationName.toLowerCase();

            // Función para calcular la distancia de Levenshtein entre dos cadenas
            const calculateLevenshteinDistance = (a, b) => {
                const matrix = Array.from({ length: a.length + 1 }, (_, i) => [i]);
                for (let j = 0; j <= b.length; j++) matrix[0][j] = j;

                for (let i = 1; i <= a.length; i++) {
                    for (let j = 1; j <= b.length; j++) {
                        const cost = a[i - 1] === b[j - 1] ? 0 : 1;
                        matrix[i][j] = Math.min(
                            matrix[i - 1][j] + 1,      // Eliminación
                            matrix[i][j - 1] + 1,      // Inserción
                            matrix[i - 1][j - 1] + cost // Sustitución
                        );
                    }
                }
                return matrix[a.length][b.length];
            };

            // Inicializamos las variables para la búsqueda de la mejor coincidencia
            let closestLocation = null;
            let minDistance = Infinity;

            // Iteramos sobre las ubicaciones para encontrar la coincidencia más cercana
            this.itemLocations.forEach(item => {
                if (item.name) {
                    const distance = calculateLevenshteinDistance(target, item.name.toLowerCase());
                    if (distance < minDistance) {
                        minDistance = distance;
                        closestLocation = item.name;
                    }
                }
            });

            ////////console.log("matched location: ", closestLocation);
            return closestLocation;
            /* // Usamos filter si queremos obtener todos los objetos que coinciden (o se parecen)
            const matchingLocations = this.itemLocations.filter(item =>
                item.location && item.location.toLowerCase().includes(locationName.toLowerCase())
            );

            //////////////console.log("matchingLocations: ", matchingLocations);

            return matchingLocations[0].location; */
        },
        formatDate(dateStr) {
            // Dividimos la fecha y la hora
            const [datePart] = dateStr.split(' ');

            // Dividimos el día, mes y año
            const [day, month, year] = datePart.split('/');

            // Retornamos la fecha en el formato YYYY-MM-DD
            return `${year}-${month}-${day}`;
        },
        findVehicleByNumber(vehicleNumber) {
            try {
                //////console.log("findVehicleByNumber/vehicleNumber: ", vehicleNumber);

                // Normalizar el texto eliminando espacios adicionales y cambiando a minúsculas
                const normalizeText = (text) => text.trim().replace(/\s+/g, ' ').toLowerCase();

                // Normalizamos el número del vehículo buscado
                const normalizedVehicleNumber = normalizeText(vehicleNumber);

                // Buscamos el primer vehículo cuyo nombre sea similar al número buscado
                const matchingVehicle = this.itemVehicles.find(item =>
                    item.assigned_user?.name?.name && normalizeText(item.assigned_user.name.name).includes(normalizedVehicleNumber)
                );

                return matchingVehicle || null;
                /* //////console.log("findVehicleByNumber/vehicleNumber: ", vehicleNumber);
                // Usamos find si solo queremos el primer vehículo que coincide
                const matchingVehicle = this.itemVehicles.find(item =>
                    item.assigned_user.name.name && item.assigned_user.name.name.toLowerCase().includes(vehicleNumber.toLowerCase())
                );

                return matchingVehicle; */
            } catch (error) {
                console.error(`${vehicleNumber} findVehicleByNumber/Error: `, error);
            }
        },
        receiptsSelected(expense) {
            const newFiles = expense.expense_type.type[0].files.slice(); // Crear una copia superficial del array
            //////////////console.log("newFiles = ", newFiles);

            newFiles.forEach((file, index) => {
                const fileExtension = file.name.split('.').pop();
                const fileNameWithoutExtension = file.name.split('.').slice(0, -1).join('.');
                //////////////console.log("File name without extension: ", fileNameWithoutExtension);

                // Crear un nuevo objeto File con el nombre modificado
                const renamedFile = new File([file], file.name, { type: file.type });

                // Generar la URL de vista previa y agregarla al archivo
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.$set(newFiles, index, Object.assign(renamedFile, { preview: e.target.result }));
                };
                reader.readAsDataURL(renamedFile);
            });

            // Reasignar los archivos solo una vez después de todos los cambios
            //this.ticket_files = newFiles;
            expense.expense_type.type[0].files = newFiles;
        },
        handleFileUpload(file) {
            try {
                const reader = new FileReader();

                // Definimos qué hacer cuando la lectura del archivo termine
                reader.onload = (e) => {
                    const data = new Uint8Array(e.target.result); // Convertimos el archivo a Uint8Array
                    const workbook = XLSX.read(data, { type: 'array' }); // Leemos el contenido del archivo Excel

                    // Intentamos obtener la hoja llamada "BASE"
                    let sheetName = 'BASE';
                    let sheet = workbook.Sheets[sheetName]; // Accedemos a la hoja específica

                    // Validamos si la hoja existe
                    if (!sheet) {
                        console.warn(`La hoja ${sheetName} no se encontró en el archivo Excel. Intentando con 'Hoja1'.`);
                        sheetName = 'Hoja1';
                        sheet = workbook.Sheets[sheetName]; // Accedemos a 'Hoja1'

                        if (!sheet) {
                            console.warn(`La hoja ${sheetName} no se encontró en el archivo Excel. Intentando con 'Sheet1'.`);
                            sheetName = 'Sheet1';
                            sheet = workbook.Sheets[sheetName]; // Accedemos a 'Hoja1'
                            if (!sheet) {
                                console.error("Ni 'BASE' ni 'Hoja1' ni 'Sheet1' se encontraron en el archivo Excel.");
                                return; // Detener el proceso si ninguna hoja existe
                            }
                        }
                    }

                    // Convertimos la hoja a formato JSON, comenzando en la fila 1 para los encabezados y la fila 2 para los datos
                    const jsonData = XLSX.utils.sheet_to_json(sheet, {
                        header: 1, // Leer sin encabezados procesados
                        range: 0,  // La fila 1 es de títulos y la fila 2 es la primera de datos
                    });

                    // Extraer la fila 1 como encabezados
                    const headers = jsonData[0]; // La fila 1 contiene los títulos
                    const dataRows = jsonData.slice(1); // Omitir la fila de encabezados y comenzar en la fila 2

                    // Procesar las filas
                    const processedData = this.processRows(dataRows, headers);
                    // Crear un arreglo de objetos donde las propiedades son los encabezados
                    /* const finalJson = dataRows
                        .map(row => {
                            const rowData = {};
                            headers.forEach((header, index) => {
                                let cellValue = row[index];


                                // Verificar si el valor de la celda es un número serial de Excel para Transaction Date
                                if (header === 'Transaction Date' && typeof cellValue === 'number') {
                                    // Convertimos el número serial a una fecha legible
                                    //////console.log("cellValue: ", cellValue);
                                    cellValue = this.convertExcelSerialToDate(cellValue);
                                    //////console.log("<<<<<<<<<<<  cellValue: ", cellValue);
                                }
                                rowData[header] = cellValue; // Asigna la propiedad según el encabezado
                            });
                            return rowData;
                        })
                        .filter(rowData => this.isRowValid(rowData)) // Filtrar filas vacías o con datos no válidos
                        .filter(rowData => !this.isInvalidTransactionDate(rowData['Transaction Date'])); */ // Filtrar las filas con fechas no válidas

                    // Asignamos el JSON final a la variable excel_json
                    this.excel_json = processedData;
                    /* this.excel_json = finalJson; */
                    ////console.log("<<<< this.excel_json: ", this.excel_json);
                };
                //////console.log("<<<< this.excel_json: ", this.excel_json);
                // Iniciar la lectura del archivo como ArrayBuffer
                reader.readAsArrayBuffer(file);
            } catch (error) {
                console.error("Error/handleFileUpload: ", error);
            }

        },
        processRows(dataRows, headers) {
            // Verificar si las columnas requeridas existen
            const hasDescription = headers.includes('Description');
            const hasType = headers.includes('Type');
            const hasTransactionDate = headers.includes('Transaction Date');
            const hasCard = headers.includes('Card');

            return dataRows
                .map(row => this.mapRowToObject(row, headers))
                .filter(rowData => {
                    // Validar solo si la columna `Card` existe
                    if (hasCard && (rowData['Card'] === null || rowData['Card'] === undefined)) {
                        return false;
                    }
                    // Validar solo si las columnas existen
                    if (hasTransactionDate && this.isInvalidTransactionDate(rowData['Transaction Date'])) {
                        return false;
                    }
                    if (hasDescription && rowData['Description'] === 'CMS AUTO REPAIR') {
                        return false;
                    }
                    if (hasType && rowData['Type'] === 'Return' || hasType && rowData['Type'] === 'Payment') {
                        return false;
                    }

                    // Eliminar filas con valores undefined o null
                    /* return Object.values(rowData).every(value => value !== undefined && value !== null); */
                    return true;
                });
        },
        mapRowToObject(row, headers) {
            const rowData = {};
            headers.forEach((header, index) => {
                let cellValue = row[index];

                // Convertir fechas de formato serial de Excel a legibles
                if (header === 'Transaction Date' && typeof cellValue === 'number') {
                    cellValue = this.convertExcelSerialToDate(cellValue);
                }
                rowData[header] = cellValue;
            });
            return rowData;
        },
        // Función para convertir el número serial de Excel a una fecha JavaScript sin alterar el valor
        convertExcelSerialToDate(serial) {
            const date = XLSX.SSF.parse_date_code(serial); // Usamos XLSX para parsear el serial a fecha

            // Formatear la fecha en el formato "dd/mm/yyyy hh:mm:ss"
            const day = String(date.d).padStart(2, '0');
            const month = String(date.m).padStart(2, '0');
            const year = date.y;
            const hours = String(date.H).padStart(2, '0');
            const minutes = String(date.M).padStart(2, '0');
            const seconds = String(date.S).padStart(2, '0');

            // Retornamos la fecha en formato dd/mm/yyyy hh:mm:ss
            return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
        },
        // Función para verificar si la fecha es inválida (corresponde a 00/01/1900 o 01/01/1900)
        isInvalidTransactionDate(dateStr) {
            // Verificar si la fecha es 01/01/1900 00:00:00 o fechas relacionadas
            if (!dateStr) return true;
            return dateStr === '01/01/1900 00:00:00' || dateStr === '00/01/1900 00:00:00';
        },
        async openFileDialogS3Bucket(file, uuid) {
            try {
                ////////console.log(typeof file);
                ////////console.log("uuid: ", uuid);
                ////////console.log("file: ", file);
                if (uuid !== '' && uuid !== undefined && (typeof file !== 'object')) {
                    ////////////console.log("OPEN FILE DIALOG S3...........");
                    const folderName = `Accounting/Expense_Record/${uuid}`;
                    ////////////console.log("folderName: ", folderName);
                    const presignedUrl = await generatePublicURL(folderName, file);  // Genera la URL presignada
                    ////////////console.log("presignedUrl: ", presignedUrl);
                    this.selectedFileUrl = presignedUrl.url;  // Asigna la URL generada
                    ////////////console.log("this.selectedFileUrl: ", this.selectedFileUrl);
                    this.fileDialog = true;  // Abre el diálogo para mostrar la vista previa
                } else {
                    ////////console.log("OPEN FILE DIALOG LOCAL...........");
                    this.selectedFile = file;
                    ////////console.log("this.selectedFile: ", this.selectedFile)
                    if (file.type === 'application/pdf') {
                        this.selectedFile.preview = URL.createObjectURL(file);
                        ////////console.log("this.selectedFile.preview: ", this.selectedFile.preview);
                    }
                    this.dialog = true;
                }
            } catch (error) {
                console.error("Error al manejar el archivo:", error);
            }
        },
        isS3Image(url) {
            return /\.(jpe?g|png|gif)(?=\?|$)/i.test(url);  // Verifica si el archivo es una imagen
        },
        isS3PDF(url) {
            return /\.pdf(?=\?|$)/i.test(url);  // Verifica si el archivo es un PDF
        },
        openFileDialog(file) {
            ////////////console.log("OPEN FILE DIALOG...........");
            //////////////console.log("file: ", file);
            this.selectedFile = file;
            if (file.type === 'application/pdf') {
                this.selectedFile.preview = URL.createObjectURL(file);
            }

            this.dialog = true;
        },
        isImage(file) {
            return file && file.type && file.type.startsWith('image/');
        },
        isPdf(file) {
            return file && file.type && file.type === 'application/pdf';
        },
        isText(file) {
            return file && file.type && file.type.startsWith('text/');
        },
        async deleteItem(uuid) {
            try {
                //////////////////console.log("UUID: ", uuid)
                this.showLoader = true;
                let res_delete = await deleteExpense(uuid);
                if (res_delete.ok) {
                    this.showLoader = false;
                    this.$emit('sent-info', false);
                } else {
                    this.showLoader = false;
                }
            } catch (error) {
                console.error("deleteItem/error: ", error);
                this.showLoader = false;
            }

        },
        generate_json() {
            //console.log("Generating JSON.............");
            //console.log("<<<<< form_response:", JSON.stringify(this.form_response, null, 2));
            const new_zoho_expense = getNewExpenseData();
            const account_id = this.form_response.expense_type.type[0].type.account_id;
            new_zoho_expense.account_id = account_id;
            new_zoho_expense.date = this.form_response.expense_date;

            //new_zoho_expense.amount = this.form_response.amount;
            new_zoho_expense.amount = this.form_response.expense_type.type[0].total;

            new_zoho_expense.paid_through_account_id = this.form_response.card_account.account_id;
            //new_zoho_expense.vendor_id = this.form_response.supplier.contact_address_id;
            new_zoho_expense.vendor_id = this.form_response.expense_type.type[0].supplier.contact_address_id;

            const last_4_digits = this.form_response.card_number.slice(-4);
            //const reference = `${last_4_digits} - NovaDrivingSystem`;
            //const reference = `${last_4_digits} - ${item.vehicle_name} - NDSys`;
            //new_zoho_expense.reference_number = reference;
            let user = '';
            let reference = '';
            if (this.form_response.vehicle_name !== null) {
                user = `${this.form_response.vehicle_name} - Driver: ${this.form_response.instructor}`;
                reference = `${last_4_digits} - ${item.vehicle_name} - NDSys`;
                ////////////////console.log("user: ", user);
            }
            if (this.form_response.management_name !== null) {
                user = `${this.form_response.management_name.name} ${this.form_response.management_name.last_name}`;
                reference = `${last_4_digits} - Management - NDSys`;
                ////////////////console.log("user: ", user);
            }
            new_zoho_expense.reference_number = reference;
            //const new_zoho_description = `${this.form_response.description} - User: ${user} - UUID: ${this.form_response.uuid}`
            let new_zoho_description = "";
            if (this.form_response.expense_type.type[0].discount != null) {
                new_zoho_description = `${this.form_response.expense_type.type[0].description} / Discount: $ ${this.form_response.expense_type.type[0].discount} / User: ${user} / UUID: ${this.form_response.uuid}`
            } else {
                new_zoho_description = `${this.form_response.expense_type.type[0].description} / User: ${user} / UUID: ${this.form_response.uuid}`
            }
            ////////////////console.log("new_zoho_description: ", new_zoho_description);

            new_zoho_expense.description = new_zoho_description; //this.form_response.description;
            new_zoho_expense.payment_mode = this.form_response.payment_method;
            const expense_types = this.form_response.expense_type;
            // Iterar sobre cada objeto y crear el nuevo formato
            expense_types.type.forEach((item, index) => {
                let new_description = '';
                if (parseFloat(item.discount) > 0.0) {
                    new_description = `${item.type.description} - Discount: $ ${parseFloat(item.discount).toFixed(2)}`;
                } else {
                    new_description = item.type.description;
                }
                const newItem = {
                    account_id: item.type.account_id,       // type.account_id
                    description: new_description,    // type.description
                    amount: parseFloat(item.total),      // amount, convertido a número
                    item_order: index + 1                  // item_order basado en el índice
                };
                // Añadir el nuevo objeto al array line_items
                new_zoho_expense.line_items.push(newItem);
            });
            //////////////////console.log("<<<<< filled_expenseData:", JSON.stringify(new_zoho_expense, null, 2))
            return new_zoho_expense;
        },
        async validate() {
            //////////////////console.log("Validate......");
            this.showLoader = true;

            //this.form_response = this.form; // Global variable

            ////////////////console.log("<<<<< form_response:", JSON.stringify(this.form_response, null, 2));
            //let uuid = this.form.uuid;
            let uuid = this.form_response.uuid;
            ////////////////console.log("<<<<<<<<<<<<<<<<<<<< uuid: ", uuid);
            // Generar el payload a partir del formulario
            let payload = this.generate_json();
            ////////////////console.log("<<<<< payload:", JSON.stringify(payload, null, 2));

            try {
                //createExpense_ZOHO
                // Intentar enviar el payload al endpoint
                //let response = await createExpense_ZOHO(payload);



                let response = await createExpense_ZOHO(payload, uuid);

                // Verificar si la respuesta es exitosa
                ////////////console.log("<<<<<<<<<<<<<< response: ", response);

                if (response.status === 404) {
                    this.showLoader = false;
                    return;
                }

                if (response.message === "Browser opened succesfully") {
                    //////////////////console.log("Autorización pendiente. Consultando el estado...");
                    let response_check = await checkAuthorizationStatus(response.unique_id);
                    //////////////////console.log("response/checkAuthorizationStatus: ", response_check);
                    //////////////////console.log("UPDATING STATUS.....");
                    let res_update = await updateStatus(uuid);
                    //////////////////console.log("res_update: ", res_update)
                    if (res_update.ok) {
                        return true;
                    }
                } else {
                    //////////////////console.log("SUCCESFUL / response: ", response);
                    //////////////////console.log("UPDATING STATUS.....");
                    let res_update = await updateStatus(uuid);
                    //////////////////console.log("res_update: ", res_update)
                    if (res_update.ok) {
                        return true;
                    }
                }
            } catch (error) {
                // Manejar cualquier error de la operación asincrónica
                this.showLoader = false;
                console.error("Error during expense creation:", error);
            }
        },
        async save_validate() {
            //////////////////console.log("Save and Validate......");
            if (this.$refs.form.validate()) {

                let resValidate = false;
                //const res = await this.save();
                if (Array.isArray(this.form.expense_type.type)) {
                    for (const typeItem of this.form.expense_type.type) {
                        // Creamos una copia profunda del formulario para evitar mutaciones
                        const formToSend = JSON.parse(JSON.stringify(this.form));

                        // Asignamos el elemento actual a formToSend.expense_type.type
                        formToSend.expense_type.type = [typeItem];  // Aquí, mantenemos el array con un solo objeto

                        ////////////////console.log("formToSend: ", formToSend);
                        //////////////////console.log("formToSend:", JSON.stringify(formToSend, null, 2));

                        // Llamamos a save con el formulario modificado
                        try {
                            //////////////console.log("----------------------------------")
                            //////////////console.log("----------------------------------")
                            ////////////////console.log("formToSend:", JSON.stringify(formToSend, null, 2));
                            const res_save = await this.save(formToSend);
                            //////////////console.log("res_save/submit: ", res_save);

                            // Si el UUID no está presente, algo salió mal y podrías manejarlo aquí
                            if (res_save.uuid === '' || res_save.uuid === null) {
                                throw new Error("Failed to save data");
                            }

                            resValidate = await this.validate();

                        } catch (error) {
                            this.showLoader = false;
                            console.error("Error saving data: ", error);
                        }
                    }
                    if (resValidate) {
                        this.showLoader = false;
                        this.$emit('sent-info', false);
                    }

                } else {
                    console.error("Error: form.expense_type.type is not an array");
                }
            }
        },
        addExpenses(newExpense) {
            if (newExpense) {
                ////////////console.log("****** newExpense: ", newExpense);
                this.expensesArray.push(newExpense);
                //////////////console.log('Nuevo gasto agregado:', newExpense);
            } else {
                console.error('El gasto no puede estar vacío');
            }
        },
        addExpenseType(newType) {
            if (newType) {
                this.form.expense_type.type.push(newType);
                //////////////////console.log('Nuevo tipo de gasto agregado:', newType);
            } else {
                console.error('El tipo de gasto no puede estar vacío');
            }
        },
        // Method to remove an S3 file
        removeS3File(index) {
            ////////////////console.log("removeS3File/index: ", index);
        },
        getVehicleName(vehicle) {
            ////////////////////console.log("vehicle: ", vehicle);
            //return `${vehicle.name}`;
            return `${vehicle.assigned_user.name.name}`;
        },
        getManagementFullName(management) {
            if (management.assigned_user?.name?.last_name) {
                return `${management.assigned_user.name.name} ${management.assigned_user.name.last_name}`;
            } else {
                return `${management.assigned_user.name.name}`;
            }
        },
        getFilteredObjects(dataArray) {
            return dataArray.filter(item => {
                // Verificamos que assigned_user exista y sea un objeto
                if (item.assigned_user && typeof item.assigned_user === 'object') {
                    // Devolvemos los objetos donde el type no sea 'vehicle'
                    return item.assigned_user.type !== 'vehicle';
                }
                return false; // Si no tiene assigned_user o no es un objeto, lo excluimos
            });
        },
        getFilteredVehicles(dataArray) {
            return dataArray.filter(item => {
                // Verificamos que assigned_user exista y sea un objeto
                if (item.assigned_user && typeof item.assigned_user === 'object') {
                    // Devolvemos los objetos donde el type no sea 'vehicle'
                    return item.assigned_user.type === 'vehicle';
                }
                return false; // Si no tiene assigned_user o no es un objeto, lo excluimos
            });
        },
        clearFields() {
            ////////////////console.log("<<<<<<<<<<<<<<<<< Clearing fields.....");
            this.instructorSelected = null;
            this.carSelected = '';
            this.form.payment_method = '';
            this.cardSelected = '';
            this.form.card_account = '';
            this.managementSelected = null;
            this.form.vehicle_name = null;
            this.form.management_name = null;
            this.$nextTick(() => {
                this.$refs.form.resetValidation();
            });
        },
        findLedgerAccount(items, searchName) {
            try {
                for (const item of items) {
                    if (item.assigned_user.name.name === searchName) {
                        return item.ledger_account.account_name;
                    }
                }
                return null; // Retornar `null` si no se encuentra el nombre
            } catch (error) {
                console.error("findLedgerAccount/Error: ", error)
            }
        },
        findPaymentMethod(items, searchName) {
            try {
                for (const item of items) {
                    if (item.assigned_user.name.name === searchName) {
                        return item.type;
                    }
                }
                return null; // Retornar `null` si no se encuentra el nombre
            } catch (error) {
                console.error("findPaymentMethod/Error: ", error)
            }
        },
        findLast4DigitsByName(items, searchName) {
            try {
                for (const item of items) {
                    if (item.assigned_user.name.name === searchName) {
                        return item.last_4_digits;
                    }
                }
                return null; // Retornar `null` si no se encuentra el nombre
            } catch (error) {
                console.error("findPaymentMethod/Error: ", error)
            }
        },
        getVehicles(items) {
            try {
                const result = items.map(item => {
                    if (item.assigned_user.type === 'vehicle') {
                        return item.assigned_user.name;
                    }
                    return null; // Retornar `null` si no es `vehicle` 
                }).filter(item => item !== null); // Filtrar los `null` para obtener solo los objetos deseados
                return result;
            } catch (error) {
                console.error("Error: ", error);
            }
        },
        formatCardNumber(card) {
            return `**** **** **** ${card.last_4_digits}`;
        },
        removeFile(index) {
            this.ticket_files.splice(index, 1);
            this.form.ticket_name_s3.splice(index, 1);
        },
        showZoom(image) {
            this.zoomedImage = image;
            this.zoomDialog = true;
        },
        onFileSelected() {
            try {
                if (this.EditedIndex != '') {
                    //this.new_ticket_name_s3 = [];
                    this.type_of_expense.files_names = [];
                } else {
                    //this.form.ticket_name_s3 = [];
                    this.type_of_expense.files_names = [];
                }
                ////////console.log("this.type_of_expense.files: ", this.type_of_expense.files);
                //const newFiles = this.ticket_files.slice(); // Crear una copia superficial del array
                const newFiles = this.type_of_expense.files.slice(); // Crear una copia superficial del array

                newFiles.forEach((file, index) => {
                    const fileExtension = file.name.split('.').pop();
                    const newFileName = `${this.form.instructor}_${Date.now()}_${index}.${fileExtension}`;
                    if (this.EditedIndex === '') {
                        //////////////////console.log("this.EditedIndex: ''", this.EditedIndex);
                        // Añadir el nuevo nombre al array ticket_name_s3
                        //this.form.ticket_name_s3.push(newFileName);
                        this.type_of_expense.files_names.push(newFileName);
                    } else {
                        //////////////////console.log("this.EditedIndex: != ''", this.EditedIndex);
                        //this.new_ticket_name_s3.push(newFileName);
                        this.type_of_expense.files_names.push(newFileName);
                        //////////////////console.log('this.new_ticket_name_s3:', this.new_ticket_name_s3);
                        //////////////////console.log('Nuevo ticket agregado:', newFileName);
                    }

                    // Crear un nuevo objeto File con el nombre modificado
                    const renamedFile = new File([file], newFileName, { type: file.type });

                    // Generar la URL de vista previa y agregarla al archivo
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        this.$set(newFiles, index, Object.assign(renamedFile, { preview: e.target.result }));
                    };
                    reader.readAsDataURL(renamedFile);
                });

                // Reasignar los archivos solo una vez después de todos los cambios
                //this.ticket_files = newFiles;
                this.type_of_expense.files = newFiles;
                ////////console.log("<<<<< this.type_of_expense.files: ", this.type_of_expense.files);
            } catch (error) {
                console.error("Error/onFileSelected: ", error);
            }
        },
        calculateTotalAmount() {
            try {
                let totalCost = 0;
                this.form.expense_type.type.forEach(item => {
                    //////////////////console.log("Item: ", item);
                    let amount = parseFloat(item.amount) || 0;
                    let discount = parseFloat(item.discount) || 0;
                    //////////////////console.log("amount: ", amount);
                    //////////////////console.log("discount: ", discount);
                    item.total = amount - discount;
                    //////////////////console.log("item.total: ", item.total);
                    if (isNaN(item.total)) {
                        item.total = 0;
                    }
                    item.total = item.total.toFixed(2);
                    totalCost += parseFloat(item.total);
                    //////////////////console.log("Item: ", item);
                });
                //this.form.amount = totalCost.toFixed(2);
            } catch (error) {
                console.error("calculateTotalAmount/Error: ", error);
            }
        },
        removeExpense_fromExpenses(index) {
            try {
                //////////////console.log("expensesArray: ", this.expensesArray)
                this.expensesArray.splice(index, 1);
                this.presignedUrls = [];
                this.$nextTick(() => {
                    ////////////////console.log("Array updated after removing the expense.");
                });
            } catch (error) {
                console.error("Error/removeExpense_fromExpenses: ", error);
            }
        },
        removeExpense(index) {
            try {
                this.form.expense_type.type.splice(index, 1);
                this.presignedUrls = [];
                this.$nextTick(() => {
                    ////////////////console.log("Array updated after removing the expense.");
                });
            } catch (error) {
                console.error("Error/removeExpense: ", error);
            }
        },
        addTag_Department(expense) {
            try {
                console.log("**********************addTag_Department************************");
                console.log("EXPENSE: ", expense);
                console.log("this.form: ", this.form);
                let type_of_expense = null
                let department = '';
                if (expense.expense_type) {
                    console.log("Existe expense_type.type..");
                    type_of_expense = expense.expense_type.type[0];
                    department = expense.department;
                } else {
                    console.log("No Existe expense.expense_type..");
                    type_of_expense = expense;
                    department = this.form.department;
                }
                console.log("type_of_expense: ", type_of_expense);
                const department_tag_obj = this.findObjectByName_tags(this.itemDepartments, department);
                console.log('department_tag_obj: ', department_tag_obj);
                const newObjTag_department = this.create_tag_obj(department_tag_obj, 'Departments');
                console.log("newObjTag_department: ", newObjTag_department);
                this.addTagToExpense(type_of_expense, newObjTag_department);
                console.log("**********************/addTag_Department************************");
            } catch (error) {
                console.error("addTag_Department/Error: ", error);
            }
        },
        addTag_Location(expense) {
            try {
                console.log("**********************addTag_Location************************");
                console.log("EXPENSE: ", expense);
                console.log("this.form: ", this.form);
                let type_of_expense = null;
                let location_tag_obj = null;
                let location = '';
                if (expense.expense_type) {
                    type_of_expense = expense.expense_type.type[0];
                    location = expense.location;
                } else {
                    type_of_expense = expense;
                    location = this.form.location;
                }
                location_tag_obj = this.findObjectByName_tags(this.itemLocations, location);
                const newObjTag_location = this.create_tag_obj(location_tag_obj, 'Location');
                this.addTagToExpense(type_of_expense, newObjTag_location);
                console.log("**********************/addTag_Location************************");
            } catch (error) {
                console.error("addTag_Location/Error: ", error);
            }
        },
        addTag_Vehicle(expense) {
            try {
                console.log("**********************addTag_Vehicle************************");
                console.log("EXPENSE: ", expense);
                console.log("this.form: ", this.form);
                let vehicle = null;
                let type_of_expense = null;
                let vehicle_tag_obj = null;
                if (expense.expense_type) {
                    type_of_expense = expense.expense_type.type[0];
                    vehicle = expense.vehicle_name;
                } else {
                    type_of_expense = expense;
                    vehicle = this.form.vehicle_name;
                }
                vehicle_tag_obj = this.findObjectByName_tags(this.itemVehicles_tags, vehicle);
                this.addTagToExpense(type_of_expense, vehicle_tag_obj);
                console.log("**********************/addTag_Vehicle************************");
            } catch (error) {
                console.error("addTag_Vehicle/Error: ", error);
            }
        },
        addTags(type_of_expense) {
            console.log("adding tags....type_of_expense: ", type_of_expense);
            console.log("this.form: ", this.form);
            let vehicle = null;
            try {
                if (this.showFileInput) {
                    vehicle = type_of_expense.vehicle_name;
                    //console.log("--- vehicle: ", vehicle);
                    type_of_expense = type_of_expense.expense_type.type[0];
                }

                /* DEPARTMENTS */
                //console.log("type_of_expense: ", type_of_expense);
                if (!this.showFileInput) {
                    //console.log('-------> this.departmentSelected: ', this.departmentSelected);
                    //console.log("3");
                    const department_tag_obj = this.findObjectByName_tags(this.itemDepartments, this.departmentSelected);
                    ////////console.log('department_tag_obj: ', department_tag_obj);
                    const newObjTag_department = this.create_tag_obj(department_tag_obj, 'Departments');
                    ////////////console.log("newObjTag_department: ", newObjTag_department);
                    this.addTagToExpense(type_of_expense, newObjTag_department);
                    ////////////console.log("this.type_of_expense: ", this.type_of_expense);
                } else {
                    console.log("**********************DEPARTMENT TAG************************");
                    console.log("type_of_expense: ", type_of_expense);
                    console.log('******* this.departmentSelected: ', this.departmentSelected);
                    console.log("4");
                    console.log("this.form.department: ", this.form.department);
                    //const department_tag_obj = this.findObjectByName_tags(this.itemDepartments, this.form.department);
                    const department_tag_obj = this.findObjectByName_tags(this.itemDepartments, type_of_expense.department);
                    console.log('department_tag_obj: ', department_tag_obj);
                    const newObjTag_department = this.create_tag_obj(department_tag_obj, 'Departments');
                    console.log("newObjTag_department: ", newObjTag_department);
                    this.addTagToExpense(type_of_expense, newObjTag_department);
                    console.log("this.type_of_expense: ", this.type_of_expense);
                    console.log("**********************/DEPARTMENT TAG************************");
                }

            } catch (error) {
                console.error("addTags/Department/Error: ", error)
            }

            /* LOCATIONS */
            try {
                if (!this.form.prorated) {
                    let location_tag_obj = null;
                    if (!this.showFileInput) {
                        //console.log("5");
                        location_tag_obj = this.findObjectByName_tags(this.itemLocations, this.form.location);
                    } else {
                        //console.log("6");
                        location_tag_obj = this.findObjectByName_tags(this.itemLocations, type_of_expense.location);
                    }
                    const newObjTag_location = this.create_tag_obj(location_tag_obj, 'Location');
                    this.addTagToExpense(type_of_expense, newObjTag_location);
                }
            } catch (error) {
                console.error("addTags/Location/Error: ", error)
            }

            /* VEHICLES */
            if (!this.form.prorated) {
                try {
                    //console.log('this.form.vehicle_name: ', this.form.vehicle_name);
                    //console.log('vehicle: ', vehicle);

                    //if (this.form.vehicle_name !== null || vehicle !== null) {
                    if (this.form.vehicle_name || vehicle) {
                        let vehicle_tag_obj = null;
                        if (!this.showFileInput) {
                            //////console.log("Diferente de null VEHICLE");
                            //console.log("7");
                            vehicle_tag_obj = this.findObjectByName_tags(this.itemVehicles_tags, this.form.vehicle_name);

                        } else {
                            //console.log("else/Diferente de null VEHICLE: ", vehicle);
                            //console.log("this.itemVehicles_tags: ", this.itemVehicles_tags);
                            //console.log("8");
                            vehicle_tag_obj = this.findObjectByName_tags(this.itemVehicles_tags, vehicle);
                        }
                        //////console.log('vehicle_tag_obj: ', vehicle_tag_obj);
                        const newObjTag_vehicle = this.create_tag_obj(vehicle_tag_obj, 'Vehicles');
                        //console.log("newObjTag_vehicle: ", newObjTag_vehicle);
                        this.addTagToExpense(type_of_expense, newObjTag_vehicle);
                        ////////////console.log("this.type_of_expense: ", this.type_of_expense);
                    } else {
                        //console.log("NO VEHICLE...");
                    }
                } catch (error) {
                    console.error("addTags/Vehicle/Error: ", error)
                }
            }

        },
        addItem(type_of_expense) {
            // PRORATED
            try {
                if (type_of_expense.type !== '' && type_of_expense.amount !== '') {
                    try {
                        ////////////console.log('this.departmentSelected: ', this.departmentSelected);
                        const department_tag_obj = this.findObjectByName_tags(this.itemDepartments, this.departmentSelected);
                        ////////////console.log('department_tag_obj: ', department_tag_obj);
                        const newObjTag_department = this.create_tag_obj(department_tag_obj, 'Departments');
                        ////////////console.log("newObjTag_department: ", newObjTag_department);
                        this.addTagToExpense(type_of_expense, newObjTag_department);
                        ////////////console.log("this.type_of_expense: ", this.type_of_expense);
                    } catch (error) {
                        console.error("addItem/Department/Error: ", error)
                    }

                    try {
                        if (!this.form.prorated) {
                            //////////console.log('this.form.location: ', this.form.location);
                            //console.log("9");
                            const location_tag_obj = this.findObjectByName_tags(this.itemLocations, this.form.location);
                            ////////////console.log('department_tag_obj: ', location_tag_obj);
                            const newObjTag_location = this.create_tag_obj(location_tag_obj, 'Location');
                            ////////////console.log("newObjTag_location: ", newObjTag_location);
                            this.addTagToExpense(type_of_expense, newObjTag_location);
                            ////////////console.log("this.type_of_expense: ", this.type_of_expense);
                        } else {
                            //////////console.log('type_of_expense.location: ', type_of_expense.location);
                            //console.log("10");
                            const location_tag_obj = this.findObjectByName_tags(this.itemLocations, type_of_expense.location);
                            ////////////console.log('department_tag_obj: ', location_tag_obj);
                            const newObjTag_location = this.create_tag_obj(location_tag_obj, 'Location');
                            ////////////console.log("newObjTag_location: ", newObjTag_location);
                            this.addTagToExpense(type_of_expense, newObjTag_location);
                            ////////////console.log("this.type_of_expense: ", this.type_of_expense);
                        }
                    } catch (error) {
                        console.error("Location/Error: ", error)
                    }

                    /* VEHICLES */
                    if (!this.form.prorated) {
                        try {
                            //console.log('this.form.vehicle_name: ', this.form.vehicle_name);
                            //console.log("11");
                            const vehicle_tag_obj = this.findObjectByName_tags(this.itemVehicles_tags, this.form.vehicle_name);
                            ////////////console.log('vehicle_tag_obj: ', vehicle_tag_obj);
                            const newObjTag_vehicle = this.create_tag_obj(vehicle_tag_obj, 'Vehicles');
                            ////////////console.log("newObjTag_vehicle: ", newObjTag_vehicle);
                            this.addTagToExpense(type_of_expense, newObjTag_vehicle);
                            ////////////console.log("this.type_of_expense: ", this.type_of_expense);
                        } catch (error) {
                            console.error("Vehicle/Error: ", error)
                        }
                    }

                    ////////console.log("<<<<<  new type_of_expense:", JSON.stringify(type_of_expense, null, 2));
                    if (this.EditedIndex === '') {
                        this.form.expense_type.type.push(type_of_expense);
                    } else {
                        this.addExpenseType(type_of_expense)
                    }
                    this.calculateTotalAmount();
                }
            } catch (error) {
                console.error("Error adding expense type: ", error);
            }
        },
        create_tag_obj(tag_obj, tag_name) {
            try {
                //console.log("------> tag_obj: ", tag_obj);
                //console.log("------> tag_name: ", tag_name);
                const id_tag = tag_obj.tag_id
                const tags_copy = JSON.parse(JSON.stringify(this.tags));
                tags_copy.tag_id = id_tag;
                tags_copy.tag_name = tag_name;
                tags_copy.tag_option_name = tag_obj.name;
                tags_copy.tag_option_id = tag_obj.tag_option_id;
                return tags_copy;
            } catch (error) {
                console.error(`${tag_name} - create_tag_obj/Error:`, error);
            }
        },
        findObjectByName_tags(array, nameValue) {
            try {
                //console.log("-------> findObjectByName_tags");
                //console.log("-------> array: ", array);
                //console.log("-------> nameValue: ", nameValue);

                // Convertir nameValue a minúsculas para una búsqueda insensible a mayúsculas
                //const lowerNameValue = nameValue.toLowerCase();
                let lowerNameValue = '';
                if (typeof nameValue === 'Object') {
                    //console.log("IT IS AN OBJECT..............");
                    //console.log("-------> nameValue.name: ", nameValue.name);
                    lowerNameValue = nameValue.name.toLowerCase();
                    //console.log("-------> lowerNameValue: ", lowerNameValue);
                } else {
                    //console.log("IT IS NOT AN OBJECT..............");
                    //console.log("-------> nameValue.name: ", nameValue.name);
                    lowerNameValue = nameValue.toLowerCase();
                }

                //console.log("-------> lowerNameValue: ", lowerNameValue);
                // Encontrar el elemento con el nombre más parecido
                return (
                    array.find(item => item.name.toLowerCase() === lowerNameValue) ||
                    array.find(item => item.name.toLowerCase().includes(lowerNameValue)) ||
                    null
                );
            } catch (error) {
                console.error(`${nameValue} / Error/findObjectByName_tags:`, error);
            }
        },
        addTagToExpense(type_of_expense, newTag) {
            try {
                console.log("************addTagToExpense**************");
                console.log("----  type_of_expense: ", type_of_expense);
                console.log("----  newTag: ", newTag);
                type_of_expense.tags.push(newTag);
                console.log("************/addTagToExpense**************");
            } catch (error) {
                console.error("addTagToExpense/Error: ", error)
            }

        },
        async submit() {
            try {
                ////////////console.log("this.expensesArray: ", this.expensesArray);
                if (this.form.prorated) {
                    const res_save = await this.save(this.form); // Send the current expense to save
                    if (!res_save.uuid) { // This will handle both empty string and null cases
                        throw new Error("Failed to save data for one of the expenses");
                    }
                    this.showLoader = false;
                    this.$emit('sent-info', false);
                } else {
                    if (this.expensesArray.length > 0) {
                        ////////console.log("this.expensesArray.length = ", this.expensesArray.length);
                        // Iterate over each item in expensesArray
                        for (const expense of this.expensesArray) {
                            if (typeof expense === 'object' && expense !== null) {
                                // Modify formToSend with the current expense (if needed)
                                const res_save = await this.save(expense); // Send the current expense to save
                                //////////////console.log("res_save/submit: ", res_save);

                                // Check if the save response contains a valid UUID
                                if (!res_save.uuid) { // This will handle both empty string and null cases
                                    throw new Error("Failed to save data for one of the expenses");
                                }
                            } else {
                                console.error("Expense is not an object or is null", expense);
                            }
                        }
                        this.showLoader = false;
                        this.$emit('sent-info', false);
                    } else {
                        if (this.$refs.form.validate()) {
                            ////////console.log("this.expensesArray.length = ", this.expensesArray.length);
                            ////////////////console.log("this.form: ", this.form);
                            ////////////////console.log("this.form.expense_type.type.length: ", this.form.expense_type.type.length);

                            if (this.form.expense_type.type.length === 0) {
                                alert("You must ADD at least one expense");
                                return;
                            }

                            // Iteramos sobre cada type y enviamos el form con ese type
                            //////////////////console.log("this.form:", JSON.stringify(this.form, null, 2));
                            if (Array.isArray(this.form.expense_type.type)) {
                                for (const typeItem of this.form.expense_type.type) {
                                    // Creamos una copia profunda del formulario para evitar mutaciones
                                    const formToSend = JSON.parse(JSON.stringify(this.form));

                                    // Asignamos el elemento actual a formToSend.expense_type.type
                                    formToSend.expense_type.type = [typeItem];  // Aquí, mantenemos el array con un solo objeto

                                    ////////////////console.log("formToSend: ", formToSend);
                                    //////////////////console.log("formToSend:", JSON.stringify(formToSend, null, 2));

                                    // Llamamos a save con el formulario modificado
                                    try {
                                        const res_save = await this.save(formToSend);
                                        ////////////////console.log("res_save/submit: ", res_save);

                                        // Si el UUID no está presente, algo salió mal y podrías manejarlo aquí
                                        if (res_save.uuid === '' || res_save.uuid === null) {
                                            throw new Error("Failed to save data");
                                        }
                                    } catch (error) {
                                        this.showLoader = false;
                                        console.error("Error saving data: ", error);
                                    }
                                }
                            } else {
                                console.error("Error: form.expense_type.type is not an array");
                            }
                            // Si todas las solicitudes han sido exitosas
                            this.showLoader = false;
                            this.$emit('sent-info', false);
                        }
                    }
                }
            } catch (error) {
                this.showLoader = false;
                console.error("Error: ", error);
            }
        },
        async save(formToSend) {
            //////////////////console.log("this.form/save: ", this.form);
            try {
                if (this.$refs.form.validate()) {
                    // Handle form submission
                    this.showLoader = true;
                    if (this.EditedIndex === '') {
                        // CREATING

                        // Itera sobre el array de expense_type.type
                        let originalFiles = null;

                        formToSend.expense_type.type.forEach((expenseType) => {
                            if (Array.isArray(expenseType.files)) {
                                // Copia los archivos originales antes de hacer modificaciones
                                originalFiles = [...expenseType.files];
                                ////////console.log("originalFiles: ", originalFiles)

                                // Copia los archivos y elimina 'preview' de cada archivo en files
                                expenseType.files = expenseType.files.map(file => {
                                    const fileCopy = { ...file }; // Hacer una copia del archivo
                                    delete fileCopy.preview; // Elimina la propiedad preview
                                    return fileCopy;
                                });
                                delete expenseType.files;
                            }
                        });

                        // Procede con la creación de la transacción
                        //////////////console.log("formToSend:", JSON.stringify(formToSend, null, 2));
                        //////////////console.log("formToSend: ", formToSend);
                        const response = await createExpense(formToSend);
                        this.form_response = response;
                        ////////////////console.log("this.form_response: ", this.form_response);
                        //////////////console.log("this.form_response:", JSON.stringify(this.form_response, null, 2));

                        if (response.uuid != '') {
                            if (originalFiles.length > 0) {
                                ////////console.log("FILES..............");
                                const uuid = response.uuid;
                                // Aquí asumes que checkFiles debería recibir todos los archivos juntos o solo los archivos de un expense_type
                                //const filesToCheck = formToSend.expense_type.type.flatMap(expenseType => expenseType.files || []);
                                ////////console.log("originalFiles: ", originalFiles)
                                const res = await checkFiles(originalFiles, uuid);
                                if (res) {
                                    return response;
                                }
                            } else {
                                ////////console.log("NO FILES..............");
                                return response;
                            }
                        }
                    } else {
                        // UPDATING
                        //////////////console.log("UPDATING EXPENSE...................");
                        //this.form.ticket_name_s3.push(...this.new_ticket_name_s3);
                        //////////////////console.log("this.form: ", this.form);
                        ////////////console.log("UPDATING/formToSend: ", formToSend);
                        let originalFiles = null;
                        formToSend.expense_type.type.forEach((expenseType) => {
                            if (Array.isArray(expenseType.files)) {
                                // Copia los archivos originales antes de hacer modificaciones
                                originalFiles = [...expenseType.files];

                                // Copia los archivos y elimina 'preview' de cada archivo en files
                                expenseType.files = expenseType.files.map(file => {
                                    const fileCopy = { ...file }; // Hacer una copia del archivo
                                    delete fileCopy.preview; // Elimina la propiedad preview
                                    return fileCopy;
                                });
                                delete expenseType.files;
                            }
                        });
                        ////////////////console.log("<<<<<<<<<<<<<<<<<< formToSend: ", formToSend);
                        const response = await updateExpense(formToSend.uuid, formToSend);
                        const updatedExpense = await response.json();
                        ////////////////console.log("response/Updating: ", response);
                        if (response.ok) {
                            const res = await checkFiles(originalFiles, formToSend.uuid);
                            if (res) {
                                return updatedExpense;
                            }
                        }
                    }
                }
            } catch (error) {
                this.showLoader = false;
                console.error("Error: ", error);
            }
        },
        resetForm() {
            this.$refs.form.reset();
        }
    },
    async mounted() {
        try {
            //console.log("ITEM: ", this.Item);
            ////////////console.log("ITEM.UUID: ", this.Item.uuid);
            this.showLoader = true;
            //////////////////console.log("this.EditedIndex: ", this.EditedIndex);
            this.$nextTick(() => {
                this.$refs.form.resetValidation();
            });
            this.form.created_by = this.Employee_number;
            console.time('fetchActiveEmployeesWithVehicle');
            console.time('getAllCreditCardManagements');
            //console.time('fetchVehicles');
            console.time('getItemDepartments');
            //console.time('getLocations');
            console.time('fetchZohoChartOfAccounts');
            //console.time('fetchZohoVendors');
            console.time('getDriverNames');
            /* vehicles, zohoVendors, locations, */
            const [fleetInstructors, activeEmployeesWithVehicle, creditCardManagements, departments, zohoChartOfAccounts] = await Promise.all([
                (async () => {
                    const result = await getDriverNames();
                    console.timeEnd('getDriverNames');
                    return result;
                })(),
                (async () => {
                    const result = await fetchActiveEmployeesWithVehicle();
                    console.timeEnd('fetchActiveEmployeesWithVehicle');
                    return result;
                })(),
                (async () => {
                    const result = await getAllCreditCardManagements();
                    console.timeEnd('getAllCreditCardManagements');
                    return result;
                })(),
                (async () => {
                    //const result = getItemDepartments(); // Si este no es asincrónico, puedes ejecutarlo fuera del Promise.all
                    const result = getReportingTags(); // Si este no es asincrónico, puedes ejecutarlo fuera del Promise.all
                    console.timeEnd('getItemDepartments');
                    return result;
                })(),
                (async () => {
                    const result = await fetchZohoChartOfAccounts();
                    console.timeEnd('fetchZohoChartOfAccounts');
                    return result;
                })(),
            ]);

            this.instructors = fleetInstructors;
            //////////////////console.log("this.instructors: ", this.instructors);
            this.itemInstructor = activeEmployeesWithVehicle;
            this.itemCards = creditCardManagements;
            //////////////console.log("this.itemCards: ", this.itemCards);
            this.itemManagement = this.getFilteredObjects(this.itemCards);
            ////console.log("this.itemManagement: ", this.itemManagement);
            //this.itemVehicles = vehicles;
            this.itemVehicles = this.getFilteredVehicles(this.itemCards);
            ////////////console.log("this.itemVehicles: ", this.itemVehicles);

            this.itemDepartments = departments.Departments.tag_options;
            //console.log("this.itemDepartments: ", this.itemDepartments);


            this.itemLocations = departments.Location.tag_options;
            ////////console.log("this.itemLocations: ", this.itemLocations);

            this.itemVehicles_tags = departments.Vehicles.tag_options;
            ////////////console.log("this.itemVehicles_tags: ", this.itemVehicles_tags);

            this.expenseTypes = zohoChartOfAccounts;
            //////////////console.log("this.expenseTypes: ", this.expenseTypes);
            //this.supliers = zohoVendors;
            //////////////console.log("this.suppliers: ", this.suppliers);
            this.prorated_items = await getPorcentages();
            ////////////console.log("this.prorated_items: ", this.prorated_items);

            /* try {
                if (Array.isArray(this.itemDepartments) && this.itemDepartments.length > 0) {
                    this.departmentSelected = 'Fleet';
                } else {
                    console.warn("itemDepartments no está cargado correctamente.");
                }
            } catch (error) {
                console.error("Error departmentSelected: ", error);
            } */


            if (this.EditedIndex === '') {
                // CREATE NEW EXPENSE
                //////////////////console.log("this.itemCards: ", this.itemCards);
                this.departmentSelected = 'Fleet';

            } else {
                // EDIT EXPENSE
                ////////console.log("<<<<<<<<<<<<<< this.Item/EDIT: ", this.Item);

                this.form = {};
                //this.form = _.cloneDeep(this.Item);
                //this.form = { ...this.Item };
                const clonedItem = { ...this.Item };
                ////////console.log("clonedItem/EDIT: ", clonedItem);

                // Verificamos si 'expense_type' y 'type' existen y son objetos
                /* if (clonedItem.expense_type && clonedItem.expense_type.type && typeof clonedItem.expense_type.type === 'object') {
                    const { files, ...cleanedType } = clonedItem.expense_type.type;  // Eliminamos 'files'
                    clonedItem.expense_type.type = cleanedType;  // Asignamos el objeto limpio sin 'files'
                } */
                // Verificamos si 'expense_type' y 'type' existen y si 'type' es un array
                if (clonedItem.expense_type && Array.isArray(clonedItem.expense_type.type)) {
                    // Iteramos sobre cada elemento en el array 'type'
                    clonedItem.expense_type.type = clonedItem.expense_type.type.map(typeItem => {
                        // Desestructuramos 'files' para eliminarlo del objeto
                        const { files, ...cleanedType } = typeItem;
                        // Retornamos el objeto limpio sin 'files'
                        return cleanedType;
                    });
                }

                // Asignamos el objeto modificado a this.form
                this.form = { ...clonedItem };
                /* Se asigna para poder actualizar historial */
                //////console.log("BEFORE / this.form.created_by: ", this.form.created_by)
                this.form.created_by = this.Employee_number;
                //////console.log("AFTER / this.form.created_by: ", this.form.created_by)
                ////////console.log("this.form/EDIT: ", this.form);
                ////////////////console.log("<<<<< this.form:", JSON.stringify(this.form, null, 2));
                ////////////////console.log("<<<<<<<<<<<<< this.form.expense_type.type.type", this.form.expense_type.type)

                if (this.form.management_name != null) {
                    this.selection = 'management';
                    if (this.form.management_name.type === 'employee') {
                        this.managementSelected = `${this.form.management_name.name} ${this.form.management_name.last_name}`;
                        //console.log("this.managementSelected: ", this.managementSelected);
                    } else {
                        this.managementSelected = this.form.management_name.name;
                    }
                }
                if (this.form.vehicle_name !== null) {
                    this.selection = 'vehicle';
                    this.carSelected = this.form.vehicle_name;
                }
                this.cardSelected = this.form.card_number; //.account_name;
                this.locationSelected = this.form.location;
                //this.selectedSupplier = this.form.supplier.contact_name;
                //this.selectedSupplier = this.form.expense_type.type.supplier.contact_name;
                this.instructorSelected = this.form.instructor;
                //////////////////console.log("this.selectedSupplier: ", this.selectedSupplier);
                if (this.form.prorated) {
                    this.amount_prorated = this.form.amount;
                }

                if (this.form.expense_type.type.length > 0 && this.form.expense_type.type[0].files_names.length > 0) {
                    const folderName = `Accounting/Expense_Record/${this.form.uuid}`;
                    ////////////////console.log("this.form.expense_type.type[0].files_names: ", this.form.expense_type.type[0].files_names);
                    const filesNames = this.form.expense_type.type[0].files_names;
                    ////////////////console.log("filesNames: ", filesNames);
                    //this.presignedUrls = await generatePublicURLs(folderName, filesNames);
                    //////////////console.log("this.presignedUrls: ", this.presignedUrls);
                } else {
                    ////////////////console.log("No archivos....");
                }
                /*if (this.form.ticket_name_s3.length > 0) {
                    const folderName = `Accounting/Expense_Record/${this.form.uuid}`
                    //this.presignedUrls = await generatePublicURLs(folderName, this.form.ticket_name_s3);
                    this.presignedUrls = await generatePublicURLs(folderName, this.form.expense_type.type.files_names);
                    //////////////////console.log("arrayUrl: ", this.presignedUrls)
                } else {
                    //////////////////console.log("No archivos....", this.form.ticket_name_s3);
                } */
            }
            ////////console.log("this.form: ", this.form);
            this.showLoader = false;
        } catch (error) {
            console.error("Error/Mounted: ", error);
        }
    },
};
</script>

<style>
.light-green-background {
    background-color: #8BC34A !important;
    /* Verde claro cuando habilitado */
}

.disabled-background {
    background-color: #D3D3D3 !important;
    /* Gris claro cuando deshabilitado */
}

.delete-icon-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    padding: 0;
    margin: 4px;
}

.zoomable-image {
    cursor: pointer;
    /* Remueve el efecto de zoom in */
    transition: none;
}

/* Elimina la transformación al hacer hover */
.zoomable-image:hover {
    transform: none;
}

/* LOADER */
.loader-container {
    position: fixed;
    /* Para que el loader esté por encima de todo el contenido */
    top: 0;
    left: 0;
    width: 100%;
    /* Ocupa todo el ancho del viewport */
    height: 100%;
    /* Ocupa todo el alto del viewport */
    display: flex;
    justify-content: center;
    /* Centra horizontalmente */
    align-items: center;
    /* Centra verticalmente */
    background-color: rgba(255, 255, 255, 0.5);
    /* Fondo semitransparente */
    z-index: 9999;
    /* Coloca el loader por encima de todo el contenido */
}

.loader {
    width: 120px;
    height: 60px;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    background: #fff;
    filter: blur(5px) contrast(10) hue-rotate(60deg);
    mix-blend-mode: darken;
}

.loader:before,
.loader:after {
    content: "";
    width: 40px;
    border-radius: 50%;
    background: #ff00ff;
    animation: l3 1s infinite alternate;
}

.loader:after {
    --s: -1;
}

@keyframes l3 {

    90%,
    100% {
        transform: translate(calc(var(--s, 1)*30px))
    }
}
</style>
