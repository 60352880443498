<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-img :src="require('../assets/Logos/logoNegro.png')" contain height="400" />
      </v-col>
      <v-col>
        <h1 class="display-2 font-weight-bold mb-3">
          Welcome {{ name }} {{ last_name }} to NOVADRIVING
        </h1>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { bus } from '../main.js';
import { get_permissions } from '../utils/permission.js'

export default {
  name: 'HomePage',
  data: () => ({
    loggedIn: false,
    permissions: "",   
    name: '',
    last_name: '',
    employee_number: ''
  }),

  mounted() {
    bus.$emit('login-success');
    bus.$emit('permissions');
    const { permissions, walk_ins, training_logs, name, last_name, employee_number } = get_permissions();
    this.permissions = permissions;    
    this.name = name;
    this.last_name = last_name;
    this.employee_number = employee_number;
  },
}
</script>
