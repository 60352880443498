<template>
    <nova-exam-creator/>
</template>

<script>
import NovaExamCreator from '../../components/NovaExam/NovaExamCreator.vue'

export default {
    name: 'NovaExamCreatorView',
    components: {
        NovaExamCreator,
    },
}
</script>