<template>
    <sales-goal />
</template>

<script>
import SalesGoal from '../../components/Sales/SalesGoal.vue'

export default {
    name: 'SalesGoalView',
    components: {
        SalesGoal,
    },
}
</script>