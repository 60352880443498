import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store'
import vuetify from './plugins/vuetify'
import VueSignaturePad from 'vue-signature-pad';
import VueI18n from 'vue-i18n';
export const bus = new Vue();


Vue.config.productionTip = false
Vue.use(VueSignaturePad);
Vue.use(VueI18n);

// Traducciones
const messages = {
  en: {
    chooseLanguage: "Choose your language",
    welcomeMessage:
      "Welcome to NOVA Driving School's driving test simulator. The following exam consists of questions to prepare for your driving license test. It contains single-choice questions. The average time for this exam is: 24 minutes. Pay attention and focus. Good luck!",
    start: "Start",
    selectExamVersion: "Please select the exam version",
    version1: "VERSION 1",
    version1Signals: "VERSION 1 - ONLY SIGNALS",
    version2: "VERSION 2",
    version2Signals: "VERSION 2 - ONLY SIGNALS",
    goBack: "BACK"
  },
  es: {
    chooseLanguage: "Escoja su idioma",
    welcomeMessage:
      "Bienvenido al simulador de pruebas de conducción de NOVA Driving School.\n\nEl siguiente examen reúne preguntas de preparación para rendir el examen para obtener tu licencia de conducción.\nEl examen consta de preguntas de una única opción.\nEl tiempo promedio de este examen es de: 24 minutos.\nPresta atención y concéntrate.\n¡Buena suerte!",
    start: "Empezar",
    selectExamVersion: "Por favor selecciona la versión del exámen",
    version1: "VERSIÓN 1",
    version1Signals: "VERSIÓN 1 - SOLO SEÑALES",
    version2: "VERSIÓN 2",
    version2Signals: "VERSIÓN 2 - SOLO SEÑALES",
    goBack: "REGRESAR"
  },
};

// Crear instancia de VueI18n
const i18n = new VueI18n({
  locale: "en", // Idioma predeterminado
  fallbackLocale: "en", // Idioma de respaldo
  messages, // Traducciones
});

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: function (h) { return h(App) }
}).$mount('#app')
