<template>
    <div>
        <v-row class="pt-4 px-4">
            <v-col cols="12" sm="12" md="12" class="d-flex justify-center">
                <v-card height="auto" width="900">
                    <v-card-text>
                        <v-data-table :headers="headers" :items="filteredItems" sort-by="coverage"
                            class="elevation-1"><!-- @click:row="openDialog" -->
                            <template v-slot:top>
                                <v-toolbar flat>
                                    <v-toolbar-title class="font-weight-bold">Collected Goal</v-toolbar-title>
                                    <v-divider class="mx-4" inset vertical></v-divider>
                                    <v-text-field v-model="search" label="Search" solo-inverted
                                        prepend-icon="mdi-magnify" class="mr-3 mt-5"></v-text-field>
                                    <v-btn color="blue" dark class="mb-2 mr-2" @click="fetchItems">
                                        <v-icon>mdi-refresh</v-icon>
                                    </v-btn>
                                    <v-btn color="success" dark class="mb-2 mr-2" @click="openDialog">
                                        <v-icon>mdi-plus-thick</v-icon>
                                    </v-btn>
                                </v-toolbar>
                            </template>
                            <template v-slot:item.actions="{ item }">
                                <v-icon small :style="{ color: 'red' }" @click="deleteItem(item)">
                                    mdi-delete
                                </v-icon>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <div class="loader-container" v-if="showloader">
            <!-- <l-bouncy size="150" speed="0.8" color="#FF00F7"></l-bouncy> -->
            <l-grid size="75" speed="1.5" color="red"></l-grid>
        </div>
        <v-dialog v-model="dialogOpen" max-width="500">
            <v-card>
                <v-card-title>
                    <span class="text-h6">Select Dates and Quantity</span>
                </v-card-title>

                <v-card-text>
                    <v-container>
                        <v-row>
                            <!-- Campo para Fecha Inicial -->
                            <v-col cols="12" md="6">
                                <v-menu v-model="menu1" :close-on-content-click="false" transition="scale-transition"
                                    offset-y min-width="auto">
                                    <template #activator="{ on, attrs }">
                                        <v-text-field v-model="editedItem.initial_date" label="Fecha Inicial" readonly
                                            v-bind="attrs" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="editedItem.initial_date"
                                        @input="menu1 = false"></v-date-picker>
                                </v-menu>
                            </v-col>

                            <!-- Campo para Fecha Final -->
                            <v-col cols="12" md="6">
                                <v-menu v-model="menu2" :close-on-content-click="false" transition="scale-transition"
                                    offset-y min-width="auto">
                                    <template #activator="{ on, attrs }">
                                        <v-text-field v-model="editedItem.final_date" label="Fecha Final" readonly
                                            v-bind="attrs" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="editedItem.final_date"
                                        @input="menu2 = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>

                        <!-- Campo para Cantidad -->
                        <v-row>
                            <v-col cols="12">
                                <v-text-field v-model="editedItem.amount" label="Cantidad" type="number"
                                    min="0"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-btn text color="primary" @click="saveData">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { bus } from '../../main.js';
import { grid } from 'ldrs'
import { createCollectedGoal, fetchAllCollectedGoals, removeCollectedGoal } from '../../utils/Sales/collected_goal_CRUD.js'

grid.register()

export default {
    data: () => ({
        menu1: false, // Controla el menú de fecha inicial
        menu2: false, // Controla el menú de fecha final
        showloader: false,
        dialogInfoInsurance: false,
        headers: [
            { text: 'Actions', value: 'actions', sortable: false },
            { text: 'Amount', value: 'amount' },
            { text: 'Initial Date', value: 'initial_date' },
            { text: 'Final Date', value: 'final_date' },
            /* { text: 'Total cost', value: 'total_cost' }, */
        ],
        dialogOpen: false,
        search: '',
        items: [],
        editedIndex: -1,
        editedItem: {
            amount: 0,
            initial_date: null,
            final_date: null,
        },
        defaultItem: {
            amount: 0,
            initial_date: null,
            final_date: null,
        },

    }),
    computed: {
        filteredItems() {
            if (!this.search.trim()) {
                return this.items;
            } else {
                return this.items.filter(item =>
                    item.amount.toLowerCase().includes(this.search.toLowerCase())
                );
            }
        }
    },
    async mounted() {
        //console.log("-------------------MOUNTED.......................")
        bus.$emit('login-success');
        bus.$emit('permissions');
        await this.fetchItems();
    },
    methods: {
        async saveData() {
            console.log(this.editedItem); // Aquí puedes guardar o procesar los datos
            this.showloader = true;
            this.editedItem.amount = parseFloat(this.editedItem.amount) || 0;
            const response = await createCollectedGoal(this.editedItem);
            console.log("response: ", response);
            if(response.ok){
                this.showloader = false;
                this.dialogOpen = false;
            }
        },
        async deleteItem(item) {
            console.log("item: ", item);
            this.showloader = true;
            //this.editedIndex = this.employees.indexOf(item)
            //this.editedItem = Object.assign({}, item)
            const response = await removeCollectedGoal(item.id)
            if(response.ok){
                this.fetchItems();
                this.showloader = false;
            }
            //this.dialogDelete = true
        },
        handleInfoFromChild(info) {
            // Manejar la información recibida desde el hijo
            console.log('Información recibida desde el hijo:', info);
            this.dialogOpen = info; //Close dialog
            this.fetchItems(); //Refresh employees table
        },
        updateItem(item) {
            console.log("item: ", item)
            /* this.editedIndex = item.id;
            this.editedItem = item; */
            //this.editedIndex = this.items.indexOf(item);            
            console.log("this.editedIndex: ", this.editedIndex);
            this.editedItem = Object.assign({}, item);
            this.editedIndex = this.editedItem.id;
            console.log("this.editedIndex: ", this.editedIndex);
            console.log("<<<<<<<<<<<<<<<<<<<< this.editedItem: ", this.editedItem);
            this.dialogOpen = true;
        },
        openDialog(item) {
            console.log("Opening dialog.....", item)
            //this.editedItem = Object.assign({}, item);
            console.log("this.editedItem/openDialog: ", this.editedItem)
            //this.editedIndex = item.id;
            if (item.id) {
                this.editedIndex = item.id
                this.editedItem = Object.assign({}, item);
                console.log("Updating report to...", this.editedItem);
            }
            this.dialogOpen = true;

        },
        async fetchItems() {
            try {
                this.showloader = true;
                //this.items = await getComissions();
                this.items = await fetchAllCollectedGoals();
                this.showloader = false;
                console.log("this.items: ", this.items);
            } catch (error) {
                console.error("Error fetching: ", error);
            }
        }
    },
    watch: {
        dialogOpen(val) {
            console.log(">>>>>>>>>>>>>>>>>> val: ", val);
            if (!val) {
                this.editedItem = Object.assign({}, this.defaultEditedItem);
                //this.editedItem = this.defaultEditedItem;
                this.editedIndex = -1;
                this.fetchItems();
            }
        },
        dialogInfoInsurance(val) {
            console.log(">>>>>>>>>>>>>>>>>>dialogInfoInsurance/val: ", val);
            if (!val) {
                this.editedItem = this.defaultEditedItem;
                this.editedIndex = -1;
                this.fetchItems();
            }
        },
    }
}
</script>

<style scoped>
.loader-container {
    position: fixed;
    /* Para que el loader esté por encima de todo el contenido */
    top: 0;
    left: 0;
    width: 100%;
    /* Ocupa todo el ancho del viewport */
    height: 100%;
    /* Ocupa todo el alto del viewport */
    display: flex;
    justify-content: center;
    /* Centra horizontalmente */
    align-items: center;
    /* Centra verticalmente */
    background-color: rgba(255, 255, 255, 0.5);
    /* Fondo semitransparente */
    z-index: 9999;
    /* Coloca el loader por encima de todo el contenido */
}

.dialog-height {
    max-height: auto !important;
    min-height: auto !important;
}

.dialog-card {
    padding: 0;
    /* Elimina el relleno interno */
    margin: 0;
    /* Elimina los márgenes */
}
</style>