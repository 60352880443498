<template>
  <div id="app">
    <v-container>
      <v-card>
        <v-card-title>Multiple Choice Exam</v-card-title>
        <v-card-text>
          <div>
            <p class="question-text">
              <v-icon v-if="!isImageQuestion" large class="mr-3 audio-icon"
                @click="playAudio(currentQuestion.audio_path)">mdi-volume-high</v-icon>
              <span v-if="!isImageQuestion">
                {{ currentQuestionIndex + 1 }}/{{ questions.length }}. {{ currentQuestion.question }}
              </span>
              <span v-else>
                {{ currentQuestionIndex + 1 }}/{{ questions.length }}.
                <img :src="imageQuestionUrl" alt="Question Image" class="question-image">
              </span>
            </p>
            <div v-if="currentQuestion && currentQuestion.answers">
              <div v-for="(option, index) in currentQuestion.answers" :key="option.id_selection"
                class="d-flex align-center mb-2 option-container">
                <v-icon large class="mr-3 audio-icon" @click="playAudio(option.audio_path)">mdi-volume-high</v-icon>
                <div :class="{
                  'option-div': true,
                  'correct': answered && option.isTrue,
                  'incorrect': answered && !option.isTrue && selectedAnswer === option.id_selection
                }" @click="selectAnswer(option.id_selection)" :disabled="answered"
                  :style="answered ? 'pointer-events: none;' : ''">
                  {{ option.text_option }}
                </div>
              </div>
            </div>
          </div>
          <v-btn @click="currentQuestionIndex === questions.length - 1 ? showFinalSummary() : nextQuestion()"
            :disabled="!answered">
            {{ currentQuestionIndex === questions.length - 1 ? "Finish" : "Next" }}
          </v-btn>
        </v-card-text>
      </v-card>

      <!-- Modal de Resumen -->
      <v-dialog v-model="showSummary" max-width="500">
        <v-card>
          <v-card-title>Exam Summary</v-card-title>
          <v-card-text>
            <p>Correct: {{ correctAnswers }}</p>
            <p>Incorrect: {{ incorrectAnswers }}</p>
            <ul>
              <li v-for="(question, index) in questions" :key="index"
                :class="{ 'incorrect-summary': !results[index], 'correct-summary': results[index] }" class="mb-3">
                <v-icon v-if="!question.imageUrl" small class="mr-3 audio-icon" @click="playAudio(question.audio_path)">mdi-volume-high</v-icon>
                {{ index + 1 }}.
                <span v-if="question.imageUrl">
                  <img :src="question.imageUrl" alt="Question Image" class="img-responsive">
                </span>
                <span v-else>
                  {{ question.question }}
                </span>
                <ul>
                  <li>
                    Your Answer:
                    <v-icon small class="mr-3 audio-icon"
                      @click="playAudio(getUserAnswerAudio(index))">mdi-volume-high</v-icon>
                    <span :class="{ 'text-error': !results[index], 'text-success': results[index] }">
                      {{ getUserAnswerText(index) }}
                    </span>
                  </li>
                  <li>
                    Correct answer:
                    <v-icon small class="mr-3 audio-icon"
                      @click="playAudio(getCorrectAnswerAudio(index))">mdi-volume-high</v-icon>
                    {{ getCorrectAnswerText(index) }}
                  </li>
                </ul>
              </li>
            </ul>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" @click="finishExam">Finish</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import { fetchQuestions } from '../../utils/NovaExam/NovaExam_CRUD'
import { fetchPublicURLs } from '../../utils/NovaExam/NovaExam_CRUD'


export default {
  data() {

    return {
      imageUrls: [], // URLs públicas de las imágenes
      version: '',
      language: '',
      type_of_test: '',
      questions: [],
      currentQuestionIndex: 0,
      selectedAnswer: null,
      answered: false,
      feedback: "",
      correctAnswers: 0,
      incorrectAnswers: 0,
      results: [],
      userAnswers: [],
      showSummary: false,
      imageQuestionUrl: "", // Nueva propiedad para almacenar la URL de la imagen
      isImageQuestion: false, // Indica si la pregunta es una imagen
    };
  },
  async mounted() {
    try {

      // Obtiene los datos de los parámetros de la ruta
      const { version, language, type_of_test } = this.$route.query;
      this.version = version;
      this.language = language;
      this.type_of_test = type_of_test;

      console.log("Version:", this.version);
      console.log("Language:", this.language);
      console.log("Type of Test:", this.type_of_test);

      this.questions = await fetchQuestions(this.language, this.version, this.type_of_test);
      console.log('Loaded questions:', this.questions);
      if (this.questions.length > 0) {
        this.currentQuestionIndex = 0;
      } else {
        console.warn('No questions available!');
      }
    } catch (error) {
      console.error("Error/Mounted: ", error)
    }
  },
  computed: {
    currentQuestion() {
      try {
        //return this.questions[this.currentQuestionIndex];
        return this.questions[this.currentQuestionIndex] || { question: '', answers: [] };
      } catch (error) {
        console.error("Error/currentQuestion: ", currentQuestion);
      }
    },
  },
  watch: {
    showSummary(val) {
      console.log("showSummary/val: ", val);
      console.log("this.questions: ", this.questions);
      if (val) {
        this.processImageQuestions();
      }
    },
    currentQuestion: {
      immediate: true,
      handler(newQuestion) {
        if (newQuestion && this.isImagePath(newQuestion.question)) {
          this.getImageQuestion(newQuestion.question);
        } else {
          this.isImageQuestion = false;
          this.imageQuestionUrl = "";
        }
      },
    },
  },
  methods: {
    async handleDialogOpen(isOpen) {
      console.log("handleDialogOpen: ", isOpen);
      if (isOpen) {
        await this.processImageQuestions();
      }
    },
    async processImageQuestions() {
      const fetchPromises = this.questions.map(async (question, index) => {
        if (question.question.startsWith("/")) {
          try {
            const path = `nova_exam/img${question.question}.png`;
            const urlS3 = await fetchPublicURLs(path);
            this.$set(this.questions[index], "imageUrl", urlS3.url); // Garantizar reactividad
          } catch (error) {
            console.error("Error fetching image question URL: ", error);
            this.$set(this.questions[index], "imageUrl", null); // Manejar errores
          }
        } else {
          this.$set(this.questions[index], "imageUrl", null); // Asignar nulo si no aplica
        }
      });
      await Promise.all(fetchPromises);
    },
    isImageQuestionDialog(questionText) {
      return questionText.startsWith("/en/v1/1");
    },

    finishExam() {
      console.log("Finishing");
      this.showSummary = false;
    },
    getCorrectAnswerAudio(index) {
      try {
        const option = this.questions[index].answers.find((opt) => opt.isTrue);
        return option ? option.audio_path : "";
      } catch (error) {
        console.error("Error/getCorrectAnswerAudio: ", error);
      }

    },
    getUserAnswerAudio(index) {
      try {
        const userAnswerId = this.userAnswers[index];
        const option = this.questions[index].answers.find(
          (opt) => opt.id_selection === userAnswerId
        );
        let pathAudio = option ? option.audio_path : "";
        console.log("pathAudio: ", pathAudio);

        return option ? option.audio_path : "";
      } catch (error) {
        console.error("Error/getUserAnswerAudio: ", error);
      }

    },
    async getImageQuestion(imagePath) {
      try {
        const path = `nova_exam/img${imagePath}.png`;
        const urlS3 = await fetchPublicURLs(path);
        this.imageQuestionUrl = urlS3.url; // Guarda la URL en la propiedad reactiva
        this.isImageQuestion = true;
      } catch (error) {
        console.error("Error/getImageQuestion: ", error);
      }
    },
    isImagePath(questionText) {
      const regex = /^\/[a-z]{2}\/v[0-9]+\/[0-9]+$/;
      return regex.test(questionText);
    },
    async playAudio(audioPath) {
      try {
        console.log("Listening question", audioPath);

        let path = `nova_exam/audio${audioPath}.mp3`
        console.log("path: ", path);

        let urlS3 = await fetchPublicURLs(path);
        console.log("urlS3: ", urlS3);

        // Crear un objeto Audio con la URL proporcionada
        const audio = new Audio(urlS3.url);

        // Reproducir el audio
        audio.play();

        console.log("Audio is playing...");

      } catch (error) {
        console.error("Error/playAudio: ", error);
      }

    },
    selectAnswer(selectionId) {
      try {
        this.answered = true;
        this.selectedAnswer = selectionId;
        const selectedOption = this.currentQuestion.answers.find(
          (option) => option.id_selection === selectionId
        );
        const isCorrect = selectedOption.isTrue;
        if (isCorrect) {
          this.feedback = "Correct!";
          this.correctAnswers++;
        } else {
          this.feedback = "Incorrect.";
          this.incorrectAnswers++;
        }
        this.results[this.currentQuestionIndex] = isCorrect;
        this.userAnswers[this.currentQuestionIndex] = selectionId;
      } catch (error) {
        console.error("Error/selectAnswer: ", error); y
      }

    },
    getUserAnswerText(index) {
      try {
        const userAnswerId = this.userAnswers[index];
        const option = this.questions[index].answers.find(
          (opt) => opt.id_selection === userAnswerId
        );
        return option ? option.text_option : "";
      } catch (error) {
        console.error("Error/getUserAnswerText: ", error);
      }

    },
    getCorrectAnswerText(index) {
      try {
        const option = this.questions[index].answers.find((opt) => opt.isTrue);
        return option ? option.text_option : "";
      } catch (error) {
        console.error("Error/getCorrectAnswerText: ", error);
      }

    },
    nextQuestion() {
      try {
        if (this.currentQuestionIndex < this.questions.length - 1) {
          this.currentQuestionIndex++;
          this.resetState();
        }
      } catch (error) {
        console.error("Error/nextQuestion: ", error);
      }

    },
    prevQuestion() {
      try {
        if (this.currentQuestionIndex > 0) {
          this.currentQuestionIndex--;
          this.resetState();
        }
      } catch (error) {
        console.error("Error/prevQuestion: ", error);
      }

    },
    showFinalSummary() {
      this.showSummary = true;
    },
    resetState() {
      this.selectedAnswer = null;
      this.answered = false;
      this.feedback = "";
    },
    restartExam() {
      this.currentQuestionIndex = 0;
      this.correctAnswers = 0;
      this.incorrectAnswers = 0;
      this.results = [];
      this.userAnswers = [];
      this.showSummary = false;
      this.resetState();
    },
  },
};
</script>

<style>
.correct {
  /* background-color: rgb(43, 218, 33) !important;
  color: white !important; */
  background-color: rgb(43, 218, 33) !important;
  color: white !important;
  pointer-events: none;
  /* Prevenir clics */
}

.incorrect {
  /* background-color: rgb(228, 35, 35) !important;
  color: white !important; */
  background-color: rgb(228, 35, 35) !important;
  color: white !important;
  pointer-events: none;
  /* Prevenir clics */
}

.correct-summary {
  background-color: rgb(198, 250, 198);
  padding: 10px;
  border-radius: 5px;
}

.incorrect-summary {
  background-color: rgb(228, 155, 155);
  padding: 10px;
  border-radius: 5px;
}

.text-error {
  color: rgb(148, 14, 14);
}

.text-success {
  color: rgb(8, 156, 8);
}

.question-text {
  font-size: 1.25rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.audio-icon {
  color: #78c0ff !important;
  cursor: pointer;
}

.option-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  /* Permite que los botones se ajusten si no hay suficiente espacio */
  gap: 8px;
  /* Espacio entre los botones y otros elementos */
}

.option-btn {
  text-align: left;
  display: block;
  /* El botón se comporta como un bloque para ajustarse al contenedor */
  width: auto;
  /* Ancho dinámico basado en el contenido */
  max-width: 100%;
  /* No excede el ancho del contenedor */
  white-space: normal;
  /* Permite saltos de línea */
  overflow-wrap: break-word;
  /* Ajusta palabras largas */
  word-break: break-word;
  /* Ajusta palabras largas */
  padding: 8px 16px;
  /* Espaciado interno adecuado */
  box-sizing: border-box;
  /* Incluye padding y borde en el tamaño total */
}

.aligned-btn {
  justify-content: flex-start;
}

.option-div {
  background-color: #e0e0e0;
  border: 1px solid #bdbdbd;
  border-radius: 8px;
  padding: 12px 20px;
  cursor: pointer;
  white-space: normal;
  /* Permitir texto en varias líneas */
  word-wrap: break-word;
  /* Permitir dividir palabras largas */
  text-align: center;
  display: inline-block;
  transition: all 0.2s ease;
  /* Transiciones suaves */
  user-select: none;
  /* Evitar selección de texto accidental */
}

.option-div:hover {
  background-color: #d6d6d6;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
}

.option-div:active {
  background-color: #cfcfcf;
  box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.2);
  transform: scale(0.98);
  /* Reducir tamaño al presionar */
}

.option-div.correct {
  background-color: #c8e6c9;
  border-color: #4caf50;
}

.option-div.incorrect {
  background-color: #ffcdd2;
  border-color: #f44336;
}

.question-image {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}
</style>
