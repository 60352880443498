<template>
  <v-container class="fill-height d-flex align-center justify-center">
    <v-form ref='form'>
      <v-card class="rounded custom-card" width="800">
        <v-img :src="require('../../assets/Logos/logoNegro.png')" class="my-3 mx-3" contain height="200"
          loading="eager" />
        <v-card-title class="headline orange-bg">Walk ins Registration </v-card-title>
        <v-card-text>
          <form @submit.prevent="submit">
            <v-text-field v-model="name_staff" label="" readonly></v-text-field>
            <!-- Location -->
            <v-combobox v-model="locationSelect" :items="locationOptions" item-text="location" label="Location"
              return-object :readonly="walkItem !== null && walkIndex === -1" :rules="computedRules"
              required></v-combobox>
            <div class="checkbox-title">Customer Type</div>
            <v-row>
              <v-col v-for="checkbox in checkboxes.slice(0, Math.ceil(checkboxes.length / 2))" :key="checkbox.id"
                cols="6">
                <v-checkbox v-model="checkbox.value" :label="checkbox.label" class="custom-checkbox"
                  :disabled="checkbox.disabled" @change="checkboxChanged(checkbox)"
                  :readonly="itemPreview !== -1 || (walkItem !== null && walkIndex === -1)"></v-checkbox>
              </v-col>
              <v-col v-for="checkbox in checkboxes.slice(Math.ceil(checkboxes.length / 2))" :key="checkbox.id" cols="6">
                <v-checkbox v-model="checkbox.value" :label="checkbox.label" class="custom-checkbox"
                  :disabled="checkbox.disabled" @change="checkboxChanged(checkbox)"
                  :readonly="itemPreview !== -1 || (walkItem !== null && walkIndex === -1)"></v-checkbox>
              </v-col>
            </v-row>
            <v-text-field v-model="account" label="Account Zoho / Danubenet / Notes" style="margin-top: 50px;"
              :readonly="itemPreview !== -1 || (walkItem !== null && walkIndex === -1)" :rules="computedRules"
              required></v-text-field>
            <v-btn class="mr-4" @click="submit" color="green lighten-2"
              v-if="walkItem === null || (walkItem !== null && walkIndex !== -1 && itemPreview === -1)">Submit</v-btn>
            <v-btn @click="clear" color="blue lighten-2" v-if="walkItem === null">Clear</v-btn>
          </form>
        </v-card-text>
      </v-card>
    </v-form>
    <div class="loader-container" v-if="showLoader">
      <div class="loader"></div>
    </div>
  </v-container>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, maxLength } from 'vuelidate/lib/validators'
import { LOCATIONS_ENDPOINT, STAFFS_ENDPOINT, WALKINS_ENDPOINT, GET_EMPLOYEES_BY_STATUS_WALK_INS, GET_EMPLOYEE_BY_EMPLOYEE_NUMBER } from '../../utils/endpoints';
import { bus } from '../../main.js';
import { employeeNumber } from '@/utils/employee_number';
import { get_employee_number } from '../../utils/get_employee_number.js'
import Swal from 'sweetalert2';
import { updateWalkIn } from '../../utils/WalkIns/walk_ins_CRUD'
import { fetchTodayActivities, createActivity, addTodayActivity } from '../../utils/Sales/activity_report_CRUD.js'

export default {
  props: {
    walkIndex: {
      type: Number,
      default: -1 // Valor por defecto para walkIndex
    },
    walkItem: Object,
    name_staff: String,
    preview: Number,
    itemPreview: Number,
    //editIndex: Number,
  },
  mixins: [validationMixin],
  validations: {
    checkboxes: {
      $each: {
        checked: val => val,
      },
    },
    //staffSelect: { required },
    locationSelect: { required },
    account: { required },
    sqlDateTime: {},
  },

  data: () => ({
    activityReportPermission: false,
    employeeData: {},
    employee_number: '',
    rules: {
      required: value => {
        // Verifica si el valor es un objeto (como en el caso de un combobox con return-object)
        if (typeof value === 'object') {
          if (value && (value.name || value.location)) {
            return true;  // Si existe 'name' o 'location', es válido
          } else {
            return 'Required';
          }
        }
        // Si no es un objeto, valida como un valor normal (string o número)
        return value !== null && value !== undefined &&
          (typeof value === 'string' ? value.trim() !== '' : typeof value === 'number' || /^\d+(\.\d+)?$/.test(value))
          || 'Required.';
      },
    },
    showLoader: false,
    pageRoute: window.location.pathname,
    staffSelect: null,
    staffOptions: [],
    locationSelect: null,
    locationOptions: [],
    account: '',
    currentDateTime: '',
    checkboxes: [
      { id: 1, label: "Information", value: false },
      { id: 2, label: "Sold", value: false, disabled: true },
      { id: 3, label: "HR", value: false },
      { id: 4, label: "Copies", value: false },
      { id: 5, label: "Appointment (DMV)", value: false },
      { id: 6, label: "CR + CR Online", value: false },
      { id: 7, label: "Complaint", value: false },
      { id: 8, label: "Payment", value: false },
      { id: 9, label: "Practice WE", value: false },
      { id: 10, label: "Schedule And Reschedule BTW", value: false },
      { id: 11, label: "Teen RT", value: false },
      { id: 12, label: "Documents (TEENS/ADULTS/CHAUFFEURS)", value: false },
      { id: 13, label: "Test Chauffeurs Taxi/Livery", value: false },
      { id: 14, label: "MU Teens", value: false },
    ],
    isInformationChecked: false,
    dialogVisible: false,
    //employee_number: '',
    //name_staff: '',
    previewCheckboxes: [
      { id: 1, label: "Information", value: false },
      { id: 2, label: "Sold", value: false/* , disabled: true */ },
      { id: 3, label: "HR", value: false },
      { id: 4, label: "Copies", value: false },
      { id: 5, label: "Appointment (DMV)", value: false },
      { id: 6, label: "BTW Lessons", value: false },
      { id: 7, label: "CR + CR Online", value: false },
      { id: 8, label: "Complaint", value: false },
      { id: 9, label: "Payment", value: false },
      { id: 10, label: "Practice WE", value: false },
      { id: 11, label: "Schedule And Reschedule BTW", value: false },
      { id: 12, label: "Teen RT", value: false },
      { id: 13, label: "Documents (TEENS/ADULTS/CHAUFFEURS)", value: false },
      { id: 14, label: "Test Chauffeurs Taxi/Livery", value: false },
      { id: 15, label: "MU Teens", value: false },
    ]
  }),

  computed: {
    computedRules() {
      // Define las reglas basadas en la ruta actual
      //if (this.pageRoute === '/employees') {
      return [this.rules.required];
      //}
      // Devuelve reglas vacías o diferentes si la ruta no coincide
      //return [];
    },
    computedStaff() {
      return this.staffOptions.map(staff => ({
        ...staff,
        fullName: `${staff.name} ${staff.last_name}`
      }));
    },
    staffSelectErrors() {
      const errors = []
      if (!this.$v.staffSelect.$dirty) return errors
      !this.$v.staffSelect.required && errors.push('Staff is required')
      return errors
    },
    locationSelectErrors() {
      const errors = []
      if (!this.$v.locationSelect.$dirty) return errors
      !this.$v.locationSelect.required && errors.push('Location is required')
      return errors
    },
    accountErrors() {
      const errors = []
      if (!this.$v.account.$dirty) return errors
      !this.$v.account.maxLength && errors.push('Account must be at most 10 characters long')
      !this.$v.account.required && errors.push('Account is required.')
      return errors
    },
  },

  async mounted() {
    bus.$emit('login-success');
    bus.$emit('permissions');
    this.fill_location_dropdown();

    if (this.walkIndex === -1 && this.walkItem === null) {
      console.log("CREATING....");
      this.staffSelect = await this.get_EmployeeData()
      console.log("this.staffSelect: ", this.staffSelect);
      this.name_staff = `${this.staffSelect.name} ${this.staffSelect.last_name}`;
      console.log("this.name_staff: ", this.name_staff);
      this.employeeData = {
        employee_number: this.employee_number,
        name: this.staffSelect.name,
        last_name: this.staffSelect.last_name,
      };
      this.activityReportPermission = this.staffSelect.activity_report;
      console.log("this.activityReportPermission: ", this.activityReportPermission);
      console.log("this.employeeData: ", this.employeeData);
    } else if (this.walkIndex !== -1 || this.itemPreview !== -1) {
      this.updateCheckboxes();
      console.log("this.previewCheckboxes: ", this.previewCheckboxes);
      this.checkboxes = this.previewCheckboxes;
      this.locationSelect = this.walkItem.location;
      console.log("this.locationSelect: ", this.locationSelect);
      this.account = this.walkItem.account_zoho_danubanet;
    }

  },
  watch: {
    locationSelect(val) {
      console.log("locationSelect: ", val);
    }
  },
  methods: {
    checkSelectedCheckboxes(checkboxes) {
      // Filtra los checkboxes para encontrar los seleccionados
      const selectedCheckboxes = checkboxes.filter(checkbox => checkbox.value);

      /* if (selectedCheckboxes.length > 0) {
        console.log("Hay al menos un checkbox seleccionado:", selectedCheckboxes);
      } else {
        console.log("No hay ningún checkbox seleccionado.");
      } */
      return selectedCheckboxes;
    },
    updateCheckboxes() {
      this.previewCheckboxes.forEach(checkbox => {
        const jsonKey = this.getJsonKeyFromLabel(checkbox.label);
        if (jsonKey in this.walkItem) {
          checkbox.value = this.walkItem[jsonKey];
        }
      });
    },
    getJsonKeyFromLabel(label) {
      const map = {
        "Information": "information",
        "Sold": "sold",
        "HR": "hr",
        "Copies": "copies",
        "BTW Lessons": "btw_lessons",  // Cambiado de "BTW"
        "Appointment (DMV)": "appointment_dmv",
        "CR + CR Online": "cr_cr_online",  // Cambiado de "CR"
        "Complaint": "complaint",
        "Payment": "payment",  // Cambiado de "Pay balance due"
        "Practice WE": "practice_we",  // Cambiado de "Practice WE / WE C / WE Motorcycle"
        "Schedule And Reschedule BTW": "schedule_reschedule_btw",  // Cambiado de "Schedule BTW/ CR/ RT Prep"
        "Teen RT": "teen_rt",
        "Documents (TEENS/ADULTS/CHAUFFEURS)": "documents_teens_adults_chauffeurs",  // Cambiado de "Teens Documents"
        "Test Chauffeurs Taxi/Livery": "test_chauffeurs_taxi_livery", // Combinación de "Test Chauffeurs - Livery" y "Test Chauffeurs - Taxi"
        "MU Teens": "mu_teens"
      };
      return map[label];
    },
    async get_EmployeeData() {
      try {

        this.employee_number = get_employee_number();
        console.log("employee_number: ", this.employee_number);
        const url = `${GET_EMPLOYEE_BY_EMPLOYEE_NUMBER}?employee_number=${this.employee_number}`;
        console.log("url: ", url);

        const response = await fetch(url); // Replace "your_endpoint" with the URL of your endpoint
        if (!response.ok) {
          throw new Error('Failed to fetch record');
        }
        const data = await response.json();
        return data; // Returns the object retrieved from the endpoint
      } catch (error) {
        console.error('Error fetching record:', error);
        throw error;
      }
    },
    printStaff() {
      console.log(this.staffSelect);
    },
    back() {
      this.$router.push({ name: 'user-walk-ins' });
    },
    async submit() {
      try {
        if (this.walkIndex === -1) {
          // CREATE
          console.log("this.staffSelect: ", this.staffSelect);
          let selectedCheckboxes = this.checkSelectedCheckboxes(this.checkboxes);
          if (selectedCheckboxes.length > 0) {
            console.log("Hay al menos un checkbox seleccionado:", selectedCheckboxes);
          } else {
            console.log("No hay ningún checkbox seleccionado.");
            alert("You need to select at least one reason!")
            return;
          }
          if (this.$refs.form.validate()) {
            console.log("Válido...");
            const res = await this.obtenerDatosYGuardarRegistro();
            console.log("<<<<<<<<<<<<<<< res: ", res);
            if (res.ok) {
              console.log("this.employeeData: ", this.employeeData);

              let resActivity = null;

              console.log("Si entra despues de añadir activity de walk ins");
                this.showLoader = false;
                console.log("<<< 1");
                this.clear();
                console.log("<<< 2");
                this.$emit('sent-info', false);
                console.log("<<< 3");
              /* if (this.activityReportPermission) {
                resActivity = await addTodayActivity("Walk In", this.employeeData, {});
                console.log("resActivity: ", resActivity);
              } */

              /* if (!this.activityReportPermission || resActivity?.ok) {
                console.log("Si entra despues de añadir activity de walk ins");
                this.showLoader = false;
                console.log("<<< 1");
                this.clear();
                console.log("<<< 2");
                this.$emit('sent-info', false);
                console.log("<<< 3");
              } */
            }
          }
          else {
            console.log("NO Válido...");
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              //text: 'All fields must be filled out correctly!',
              text: `You must fill out all the fields correctly`,
            });
            return;
          }
        } else {
          // UPDATE
          console.log("Updating.... ", this.walkItem);
          const new_form = this.obtenerValoresFormulario();
          //new_form.uuid = this.walkItem.uuid;
          const uuid = this.walkItem.uuid;
          console.log("new_form: ", new_form);
          console.log("new_form: ", JSON.stringify(new_form, null, 2));
          const res = await updateWalkIn(uuid, new_form);
          console.log("res: ", res);
          if (res.ok) {
            //this.clear();
            this.$emit('sent-info', false);
          }
        }
      } catch (error) {
        console.error("Error/Submit: ", error);
      }
    },
    clear() {
      try {
        //this.$v.$reset();      
        //this.staffSelect = null;
        this.locationSelect = null;
        this.account = '';
        this.isInformationChecked = false;
        this.checkboxes.forEach(checkbox => {
          checkbox.value = false;
          this.checkboxChanged(checkbox);
        });
        this.removeCheckboxes();
        this.$refs.form.resetValidation();
      } catch (error) {
        console.error("Error/Clear: ", error);
      }
    },
    checkboxChanged(checkbox) {
      if (checkbox.label === 'Information') {
        this.isInformationChecked = checkbox.value;
        const soldCheckbox = this.checkboxes.find(checkbox => checkbox.label === "Sold");
        soldCheckbox.disabled = this.isCheckboxDisabled(soldCheckbox);
        if (this.isInformationChecked == false && soldCheckbox.value == true) {
          soldCheckbox.value = false;
        }
      }
      /* if (checkbox.label === 'Others') {
        this.addCheckboxes(checkbox);
      } */
    },
    isCheckboxDisabled(checkbox) {
      if (this.isInformationChecked) {
        if (checkbox.label == 'Sold') {
          return false;
        }
      }
      return true;
    },
    fill_location_dropdown() {
      fetch(LOCATIONS_ENDPOINT, { mode: 'cors' })
        .then(response => {
          if (!response.ok) {
            throw new Error(`Error en la solicitud: ${response.status}`);
          }
          return response.json();
        })
        .then(data => {
          console.log("data: ", data);
          const arrayLocations = data.filter(item => !['México', 'Ecuador', 'España', 'Colombia', 'Venezuela', 'Holanda', 'Europa', 'Philippines'].includes(item.location));
          console.log("arrayLocations: ", arrayLocations);
          this.locationOptions = arrayLocations;
        })
        .catch(error => console.error('Error al obtener datos de la API:', error));
    },
    fill_staff_dropdown() {
      GET_EMPLOYEES_BY_STATUS_WALK_INS
      /* fetch(STAFFS_ENDPOINT, { mode: 'cors' }) */
      fetch(GET_EMPLOYEES_BY_STATUS_WALK_INS, { mode: 'cors' })
        .then(response => {
          if (!response.ok) {
            throw new Error(`Error en la solicitud: ${response.status}`);
          }
          return response.json();
        })
        .then(data => {
          console.log("data: ", data);
          this.staffOptions = data;
        })
        .catch(error => console.error('Error al obtener datos de la API:', error));
    },
    obtenerValoresFormulario() {
      let fullNameStaff = '';
      let employeeNumber = '';
      let location = '';

      if (this.walkIndex === -1) {
        fullNameStaff = this.staffSelect['name'] + ' ' + this.staffSelect['last_name'];
        employeeNumber = this.staffSelect['employee_number'];
        location = this.locationSelect.location;
      } else {
        fullNameStaff = this.name_staff;
        employeeNumber = this.walkItem.employee_number;
        console.log("this.locationSelect: ", this.locationSelect);
        if (typeof this.locationSelect !== 'object') {
          location = this.walkItem.location;
        } else {
          location = this.locationSelect.location;
        }
      }

      const datosFormulario = {
        employee_number: employeeNumber,
        staffSelect: fullNameStaff,
        locationSelect: location,
        account: this.account,
        checkboxes: this.checkboxes.map(checkbox => ({
          label: checkbox.label,
          value: checkbox.value
        })),
      };
      console.log("datosFormulario: ", datosFormulario);
      return datosFormulario;
    },
    async obtenerDatosYGuardarRegistro() {
      try {
        this.showLoader = true;
        const datos = this.obtenerValoresFormulario();
        console.log("-----------------");
        const response = await fetch(WALKINS_ENDPOINT, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(datos),
        });

        if (!response.ok) {
          const errorMessage = await response.text();
          throw new Error(`Error al guardar el registro: ${errorMessage}`);
        }
        const responseData = await response.json();
        //this.showLoader = false;
        return response;
      } catch (error) {
        console.error(error.message);
      }
    },
    addCheckboxes(checkbox) {
      if (checkbox.value) {
        this.checkboxes.push(
          ...[
            { id: 6, label: "Appointment (DMV)", value: false },
            { id: 7, label: "CR + CR Online", value: false },
            { id: 8, label: "Complaint", value: false },
            { id: 9, label: "Payment", value: false },
            { id: 10, label: "Practice WE", value: false },
            { id: 11, label: "Schedule And Reschedule BTW", value: false },
            { id: 12, label: "Teen RT", value: false },
            { id: 13, label: "Documents (TEENS/ADULTS/CHAUFFEURS)", value: false },
            { id: 14, label: "Test Chauffeurs Taxi/Livery", value: false },
            { id: 15, label: "MU Teens", value: false },
          ]
        );
      } else {
        this.removeCheckboxes();
      }
    },
    removeCheckboxes() {
      const startIndex = this.checkboxes.findIndex(checkbox => checkbox.id === 6);
      const deleteCount = 18;
      if (startIndex == 5) {
        this.$data.checkboxes.splice(startIndex, deleteCount);
      } else {
        //console.error("NO-StarIndex = ", startIndex);
      }
    },
  },
}
</script>

<style scoped>
.orange-bg {
  background-color: rgb(255, 71, 11);
}

.checkbox-title {
  font-weight: bold;
  margin-bottom: 8px;
}

.custom-checkbox {
  margin-bottom: -60px;
}

.success-dialog {
  color: green;
}

/* LOADER */
.loader-container {
  position: fixed;
  /* Para que el loader esté por encima de todo el contenido */
  top: 0;
  left: 0;
  width: 100%;
  /* Ocupa todo el ancho del viewport */
  height: 100%;
  /* Ocupa todo el alto del viewport */
  display: flex;
  justify-content: center;
  /* Centra horizontalmente */
  align-items: center;
  /* Centra verticalmente */
  background-color: rgba(255, 255, 255, 0.5);
  /* Fondo semitransparente */
  z-index: 9999;
  /* Coloca el loader por encima de todo el contenido */
}

.loader {
  width: 120px;
  height: 60px;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  background: #fff;
  filter: blur(5px) contrast(10) hue-rotate(60deg);
  mix-blend-mode: darken;
}

.loader:before,
.loader:after {
  content: "";
  width: 40px;
  border-radius: 50%;
  background: #ff00ff;
  animation: l3 1s infinite alternate;
}

.loader:after {
  --s: -1;
}

@keyframes l3 {

  90%,
  100% {
    transform: translate(calc(var(--s, 1)*30px))
  }
}
</style>