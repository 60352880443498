<template>
  <v-container class="d-flex justify-center align-center" fill-height>
    <v-card max-width="400" class="pa-4">
      <!-- Logo -->
      <v-card-title class="justify-center">
        <v-img :src="require('../../assets/Logos/nova_exam.png')" class="my-3 mx-3" contain height="200"
          loading="eager" />
      </v-card-title>

      <!-- Selección de idioma -->
      <div v-if="step === 1" class="text-center">
        <!-- <p>{{ $t('chooseLanguage') }}</p> -->
        <p>{{ $t('chooseLanguage', 'en') }} / {{ $t('chooseLanguage', 'es') }}</p>
        <v-btn @click="setLanguage('en')" color="primary" class="ma-2">English</v-btn>
        <v-btn @click="setLanguage('es')" color="primary" class="ma-2">Español</v-btn>
      </div>

      <!-- Mensaje de bienvenida -->
      <div v-if="step === 2" class="text-center">
        <p>{{ $t('welcomeMessage') }}</p>
        <v-btn @click="startExam" color="success" class="ma-2">
          {{ $t('start') }}
        </v-btn>
      </div>

      <!-- Selección de versión del examen -->
      <div v-if="step === 3" class="text-center">
        <p>{{ $t('selectExamVersion') }}</p>
        <v-btn @click="selectVersion('selection', 'v1')" color="primary" class="ma-2">
          {{ $t('version1') }}
        </v-btn>
        <v-btn @click="selectVersion('image', 'v1')" color="primary" class="ma-2">
          {{ $t('version1Signals') }}
        </v-btn>
        <v-btn @click="selectVersion('selection', 'v2')" color="primary" class="ma-2">
          {{ $t('version2') }}
        </v-btn>
        <!-- <v-btn @click="selectVersion('image', 'v2')" color="primary" class="ma-2">
          {{ $t('version2Signals') }}
        </v-btn> -->
      </div>
      <!-- Botón para regresar -->
      <v-card-actions v-if="step > 1" class="justify-end">
        <v-btn @click="goBack" color="secondary" outlined>
          {{ $t('goBack') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      step: 1, // Controla los pasos del flujo
      type_of_test: '',
      version: '',
      lenguage: '',
    };
  },
  methods: {
    goBack() {
      if (this.step > 1) {
        this.step--; // Regresa al paso anterior
      }
    },
    setLanguage(lang) {
      this.$i18n.locale = lang; // Cambia el idioma global
      console.log(`Idioma seleccionado: ${lang}`);
      this.step = 2; // Cambia al paso 2
    },
    startExam() {
      this.step = 3;
    },
    selectVersion(type, version) {
      //console.log(`Versión seleccionada: ${version}`);
      //console.log("Lenguage: ", this.$i18n.locale);
      this.version = version;
      console.log("version: ", this.version);

      //this.lenguage = this.$i18n.locale;
      if(this.$i18n.locale === 'en'){
        this.lenguage = 'english';
      }else{
        this.lenguage = 'spanish';
      }
      console.log("lenguage: ", this.lenguage);

      this.type_of_test = type
      console.log("type_of_test: ", this.type_of_test);
      console.log("path: ", `/${this.lenguage}/${this.version}`);

      // Redirecciona a la vista Exam con los datos
      this.$router.push({
        path: '/exam',
        query: {
          version: this.version,
          language: this.lenguage,
          type_of_test: this.type_of_test,
        },
      });

    },
  },
};
</script>
