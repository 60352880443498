<template>
    <div>
        <v-row class="pt-4 px-4">
            <v-col cols="12" sm="12" md="12">
                <v-card><!-- height="520" -->
                    <v-card-text>
                        <v-data-table :headers="headers" :items="filteredItems" sort-by="last_4_digits"
                            class="elevation-1">
                            <template v-slot:top>
                                <v-toolbar flat>
                                    <v-toolbar-title class="font-weight-bold">Nova Exam Creator</v-toolbar-title>
                                    <v-divider class="mx-4" inset vertical></v-divider>
                                    <v-text-field v-model="search" label="Search" solo-inverted
                                        prepend-icon="mdi-magnify" class="mr-3 mt-5"></v-text-field>
                                    <v-btn color="blue" dark class="mb-2 mr-2" @click="fetchItems">
                                        <v-icon>mdi-refresh</v-icon>
                                    </v-btn>
                                    <v-btn color="success" dark class="mb-2 mr-2" @click="openDialog">
                                        <v-icon>mdi-plus-thick</v-icon>
                                    </v-btn>
                                </v-toolbar>
                            </template>
                            <template v-slot:item.actions="{ item }">
                                <v-icon small class="mr-2" :style="{ color: 'blue' }" @click="updateItem(item)">
                                    mdi-pencil
                                </v-icon>
                                <v-icon small :style="{ color: 'red' }" @click="deleteItem(item)">
                                    mdi-delete
                                </v-icon>
                            </template>

                            <template v-slot:item.question="{ item }">
                                <div class="wrap-text">{{ item.question }}</div>
                            </template>

                            <template v-slot:item.answers="{ item }">
                                <div class="wrap-text">
                                    <span v-if="Array.isArray(item.answers)">
                                        {{ formatAnswers(item.answers) }}
                                    </span>
                                    <span v-else>N/A</span>
                                </div>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-dialog v-model="dialogOpen" max-width="700">
                <v-card class="pa-4">
                    <!-- Título del formulario -->
                    <v-card-title class="text-h5 font-weight-medium pb-4">
                        Question Creator
                    </v-card-title>

                    <v-card-text>
                        <v-form ref="form" v-model="valid">
                            <!-- Campo de tipo de pregunta -->
                            <v-select v-model="formData.type_of_question" :items="typeOptions" label="Type of Question"
                                :rules="[rules.required]" dense outlined required></v-select>

                            <!-- Campo de pregunta -->
                            <v-textarea ref="questionTextarea" :disabled="formData.type_of_question === 'image'"
                                v-model="formData.question" label="Question"
                                :rules="[formData.type_of_question === 'image' ? rules.questionRule : rules.required]"
                                dense outlined required></v-textarea>

                            <!-- Campo para subir archivo de audio -->
                            <!-- <v-file-input v-if="formData.type_of_question !== 'image'" v-model="audioQuestionFile"
                                label="Upload Audio (.mp3 only)" accept=".mp3" :rules="[rules.fileRequired]" dense
                                outlined required show-size></v-file-input> -->
                            <!-- Campo para subir archivo de audio -->
                            <v-file-input v-if="formData.type_of_question !== 'image'" v-model="audioQuestionFile"
                                label="Upload Audio (.mp3 only)" accept=".mp3" :rules="[rules.fileRequired]" dense
                                outlined required show-size @change="addAudioFile">
                            </v-file-input>

                            <!-- Campo de ruta de audio -->
                            <v-text-field v-if="formData.type_of_question !== 'image'" disabled
                                v-model="formData.audio_path" label="Audio Path" dense outlined required></v-text-field>

                            <!-- <v-file-input v-if="formData.type_of_question === 'image'" v-model="imageQuestionFile"
                                label="Image Question (.png only)" accept=".png" :rules="[rules.fileRequired]" dense
                                outlined required show-size></v-file-input> -->
                            <!-- Campo para subir archivo de imagen -->
                            <v-file-input v-if="formData.type_of_question === 'image'" v-model="imageQuestionFile"
                                label="Image Question (.png only)" accept=".png" :rules="[rules.fileRequired]" dense
                                outlined required show-size @change="addImageFile">
                            </v-file-input>

                            <!-- Campo de lenguaje -->
                            <v-select v-model="formData.lenguage" :items="['english', 'spanish']" label="Language"
                                :rules="[rules.required]" dense outlined required></v-select>

                            <!-- Campo de versión -->
                            <v-select :disabled="formData.type_of_question === 'image'" v-model="formData.version"
                                :items="['v1', 'v2']" label="Version" :rules="[rules.required]" dense outlined
                                required></v-select>

                            <!-- Respuestas -->
                            <div>
                                <v-btn @click="addAnswer" color="primary" text class="my-4">
                                    Add Answer
                                </v-btn>
                                <v-container v-for="(answer, index) in formData.answers" :key="index" class="py-3"
                                    fluid>
                                    <v-divider class="my-3"></v-divider>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-textarea v-model="answer.text_option" label="Text Option"
                                                :rules="[rules.required]" dense outlined required></v-textarea>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-file-input v-model="answer.audioFile" label="Upload Audio (.mp3 only)"
                                                accept=".mp3" :rules="[rules.fileRequired]" dense outlined required
                                                show-size></v-file-input>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <v-text-field disabled v-model="answer.audio_path" label="Audio Path" dense
                                                outlined required></v-text-field>
                                        </v-col>
                                        <v-col class="mb-3" cols="6" md="3">
                                            <v-checkbox class="mb-3" v-model="answer.isTrue" label="Is True"
                                                @change="handleIsTrueChange(index)"></v-checkbox>
                                        </v-col>
                                        <v-col cols="6" md="3" class="text-right">
                                            <v-btn @click="removeAnswer(index)" color="error" text>
                                                Remove
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </div>
                        </v-form>
                    </v-card-text>

                    <!-- Botón para enviar el formulario -->
                    <v-card-actions class="justify-end">
                        <v-btn :disabled="!valid" @click="submitForm" color="success" text>
                            Submit
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>


        </v-row>
        <div class="loader-container" v-if="showLoader">
            <div class="loader"></div>
        </div>
    </div>
</template>

<script>
import { bus } from '../../main.js';
import { getAllCreditCardManagements, deleteCreditCardManagement } from '../../utils/credit_card_managements_CRUD.js'
import CreditCardManagementCard from '../../components/Accounting/CreditCardManagementCard.vue'
import { format } from 'date-fns';
import _ from 'lodash';

import { fetchAllQuestions, createQuestion, updateQuestion, uploadFilesToEndpoint } from '../../utils/NovaExam/NovaExam_CRUD.js'

export default {
    components: {
        CreditCardManagementCard
    },
    data: () => ({
        imageQuestionFiles: [], // Almacena arrays de archivos de imágenes
        audioQuestionFiles: [], // Almacena arrays de archivos de audio
        audioAnswersFiles: [],
        imageQuestionFile: null,
        audioQuestionFile: null,
        nextIdSelection: 1, // Controla el ID incremental para las respuestas
        audioAnswerFile: [],
        valid: false,
        typeOptions: ["selection", "image"],
        formData: {
            question: "",
            audio_path: "/",
            type_of_question: "",
            lenguage: "",
            version: "",
            answers: [],
        },
        editedIndex: -1,
        questions: [],
        showLoader: false,
        headers: [
            { text: 'Actions', value: 'actions', sortable: false },
            { text: 'ID', value: 'id_question' },
            { text: 'Question', value: 'question' },
            { text: 'Audio Path', value: 'audio_path' },
            { text: 'Type of question', value: 'type_of_question' },
            { text: 'Lenguage', value: 'lenguage' },
            { text: 'Version', value: 'version' },
            { text: 'Answers', value: 'answers' },
        ],
        dialogOpen: false,
        search: '',
        items: [],
        editedItem: {
        },
        defaultEditedItem: {
        }
    }),
    computed: {
        isSubmitDisabled() {
            if (this.editedIndex === -1) {
                // Modo "Crear": el botón estará deshabilitado hasta que el formulario sea válido
                return !this.valid || !this.formData.question || this.formData.answers.length < 2;
            } else {
                // Modo "Editar": el botón estará habilitado si el formulario es válido
                return !this.valid;
            }
        },
        rules() {
            return {
                required: value => !!value || "This field is required",
                fileRequired: value => {
                    // Aplica la validación solo si `editedIndex` es -1 (modo creación)
                    console.log("***** this.editedIndex: ", this.editedIndex);
                    if (this.editedIndex === -1) {
                        return !!value || "File is required for new questions";
                    }
                    return true; // No requiere validación en modo edición
                },
                questionRule: value => {
                    console.log("this.formData.type_of_question: ", this.formData.type_of_question);
                    if (this.formData.type_of_question === 'selection' || this.formData.type_of_question === '') {
                        return !!value || "Question is required for selection type questions";
                    }
                    return true; // No valida si no es de tipo 'selection'
                },
            };
        },
        filteredItems() {
            if (!this.search.trim()) {
                return this.items;
            } else {
                return this.items.filter(item =>
                    item.question.toLowerCase().includes(this.search.toLowerCase())/*  ||
                    (item.assigned_user && item.assigned_user.name && item.assigned_user.name.name.toLowerCase().includes(this.search.toLowerCase())) */
                );
            }
        }
    },
    async mounted() {
        //console.log("-------------------MOUNTED.......................")
        bus.$emit('login-success');
        bus.$emit('permissions');
        await this.fetchItems();


    },
    methods: {
        addAudioFile() {
            if (this.audioQuestionFile) {
                //const audioFileArray = [this.audioQuestionFile]; // Crea un array para el archivo individual
                this.audioQuestionFiles.push(this.audioQuestionFile); // Almacena el array en la lista principal
                console.log("Audio file array added:", audioFileArray);
            }
        },
        addImageFile() {
            if (this.imageQuestionFile) {
                //const imageFileArray = [this.imageQuestionFile]; // Crea un array para el archivo individual
                this.imageQuestionFiles.push(this.imageQuestionFile); // Almacena el array en la lista principal
                console.log("Image file array added:", imageFileArray);
            }
        },
        resetTextareaValidation() {
            if (this.$refs.questionTextarea) {
                this.$refs.questionTextarea.resetValidation(); // Resetea la validación del v-textarea
            }
            this.formData.question = ""; // Limpia el valor del campo si es necesario
        },
        /* onDialogUpdate(value) {
            if (value) {
                this.resetForm();
            }
        }, */
        resetForm() {
            console.log("----------- Reset form......");
            this.formData = {
                question: "",
                audio_path: "",
                type_of_question: "",
                lenguage: "",
                version: "",
                answers: [],
            };
            this.audioQuestionFile = null;
            this.imageQuestionFile = null;
            this.valid = false;

            // Resetear las validaciones del formulario
            this.$nextTick(() => {
                this.$refs.form.reset();
            });
        },
        handleIsTrueChange(selectedIndex) {
            this.formData.answers.forEach((answer, index) => {
                if (index !== selectedIndex) {
                    answer.isTrue = false; // Set other answers to false
                }
            });
        },
        getFilesArray(audioFilesAnwers) {
            console.log("*** getFilesArray: ", audioFilesAnwers);
            const filesArray = audioFilesAnwers.answers.map(answer => {
                // Si no tiene 'audio_path', se lo asigna con el valor '/'
                if (!answer.audio_path) {
                    let fileName = this.getFileNameWithoutExtension(answer.audioFile);
                    let lenguage = '';
                    if (this.formData.lenguage === 'english') {
                        lenguage = 'en';
                    } else {
                        lenguage = 'es';
                    }
                    answer.audio_path = `/${lenguage}/${this.formData.version}/${fileName}`;
                    console.log("answer.audio_path: ", answer.audio_path);
                }
                // Retorna la propiedad 'audioFile'
                return answer.audioFile;
            });

            console.log("audioFilesAnwers.answers: ", audioFilesAnwers.answers);
            // Retorna el array con los archivos
            return filesArray;
        },
        addAnswer() {
            this.formData.answers.push({
                id_selection: this.nextIdSelection, // Asignamos el ID incremental
                text_option: "",
                audio_path: "",
                isTrue: false,
            });
            this.nextIdSelection++; // Incrementamos el ID para la próxima respuesta
        },
        removeAnswer(index) {
            //this.formData.answers.splice(index, 1);
            this.formData.answers.splice(index, 1);
            // Opcional: Si quieres recalcular los IDs después de eliminar una respuesta
            this.formData.answers.forEach((answer, idx) => {
                answer.id_selection = idx + 1;
            });
            this.nextIdSelection = this.formData.answers.length + 1;
        },
        async submitForm() {
            try {

                if (this.formData.answers.length < 2) {
                    alert('You need to register at least 2 answers');
                    return;
                }
                // Validar que al menos una respuesta tiene isTrue como true
                const hasCorrectAnswer = this.formData.answers.some(answer => answer.isTrue);
                if (!hasCorrectAnswer) {
                    alert('You need to select at least one correct answer');
                    return;
                }
                if (this.$refs.form.validate()) {
                    this.showLoader = true;
                    console.log("Form Submitted", this.formData);
                    let lenguage = '';
                    if (this.formData.lenguage === 'english') {
                        lenguage = 'en';
                    } else {
                        lenguage = 'es';
                    }

                    let fileName = '';
                    if (this.formData.type_of_question === 'selection') {
                        fileName = this.getFileNameWithoutExtension(this.audioQuestionFile);
                        this.formData.audio_path = `/${lenguage}/${this.formData.version}/${fileName}`;
                        console.log("this.formData.audio_path: ", this.formData.audio_path);
                    } else {
                        fileName = this.getFileNameWithoutExtension(this.imageQuestionFile);
                        this.formData.question = `/${lenguage}/${this.formData.version}/${fileName}`;
                        console.log("this.formData.question: ", this.formData.question);
                    }


                    this.audioAnswersFiles = this.getFilesArray(this.formData);
                    console.log("this.audioAnswersFiles: ", this.audioAnswersFiles);

                    const sanitizedData = {
                        ...this.formData, // Copiamos las demás propiedades
                        answers: this.formData.answers.map(({ audioFile, ...rest }) => rest), // Eliminamos 'audioFile' de cada objeto en answers
                    };
                    console.log("sanitizedData", JSON.stringify(sanitizedData, null, 2));




                    let response = null;
                    if (this.editedIndex === -1) {
                        response = await createQuestion(sanitizedData);
                    } else {
                        response = await updateQuestion(sanitizedData.id_question, sanitizedData);
                    }

                    if (response.ok) {

                        console.log("*********************")
                        console.log("this.audioQuestionFiles.length: ", this.audioQuestionFiles.length);
                        console.log("this.audioQuestionFiles: ", this.audioQuestionFiles);
                        console.log("*********************")

                        if (this.audioQuestionFiles.length > 0) {
                            await this.uploadQuestionAudioS3(this.audioQuestionFiles, `nova_exam/audio/${lenguage}/${this.formData.version}`);
                        }/* this.formData.audio_path */

                        if (this.audioAnswersFiles.length > 0) {
                            await this.uploadAnswersAudiosS3(this.audioAnswersFiles, `nova_exam/audio/${lenguage}/${this.formData.version}`);/* this.formData.audio_path */
                        }

                        if (this.formData.type_of_question === 'image') {
                            if (this.imageQuestionFiles.length > 0) {
                                await this.uploadImageS3(this.imageQuestionFiles, `nova_exam/img/${lenguage}/${this.formData.version}`);
                            }/* this.formData.question */
                        }
                        this.showLoader = false;
                        this.dialogOpen = false;
                        await this.fetchItems();
                        ;
                    }
                }
            } catch (error) {
                console.error("submitForm/Error: ", error);
                this.showLoader = false;
            }
        },
        async uploadAnswersAudiosS3(files, path) {
            try {
                console.log("Uploading Answers Audios to Bucket...");
                console.log("files...", files);
                //let arrayAudios = this.getFilesArray();
                //console.log("arrayAudios: ", arrayAudios);
                return await uploadFilesToEndpoint(files, path);
            } catch (error) {
                console.error("Error/loadAudiosS3: ", error)
            }
        },
        async uploadQuestionAudioS3(files, path) {
            try {
                console.log("Uploading Question Audio to Bucket...");
                return await uploadFilesToEndpoint(files, path);
            } catch (error) {
                console.error("Error/loadAudiosS3: ", error)
            }
        },
        async uploadImageS3(files, path) {
            try {
                console.log("Uploading Image to Bucket...");
                return await uploadFilesToEndpoint(files, path);
            } catch (error) {
                console.error("Error/loadImageS3: ", error)
            }
        },
        getFileNameWithoutExtension(file) {
            if (!file || !file.name) {
                return null; // Verifica que el archivo sea válido
            }

            // Divide el nombre del archivo en partes separadas por puntos
            const fileNameParts = file.name.split('.');

            // Remueve la última parte (la extensión) y une las demás si el nombre contiene más de un punto
            fileNameParts.pop();

            return fileNameParts.join('.');
        },
        formatAnswers(answers) {
            // Devuelve los valores de text_option concatenados
            return answers.map(answer => answer.text_option).join(', ');
        },
        formatDate(dateString) {
            if (!dateString) return '';

            return format(new Date(dateString), 'yyyy-MM-dd');
        },
        handleInfoFromChild(info) {
            // Manejar la información recibida desde el hijo
            console.log('Información recibida desde el hijo:', info);
            //this.dialogOpen = info; //Close dialog
            this.fetchItems(); //Refresh employees table
        },
        updateItem(item) {
            console.log("<<<<<<<<<<<<<< item: ", item)
            this.editedIndex = item.id_question;
            console.log("<<<<<<<<<<<<<< editedIndex: ", this.editedIndex)
            this.formData = _.cloneDeep(item);
            console.log("<<<<<<<<<<<<<< this.editedItem: ", this.formData);
            this.dialogOpen = true;
        },
        async deleteItem(item) {
            console.log("item: ", item)
            const response = await deleteCreditCardManagement(item.id);
            if (response.ok) {
                this.fetchItems();
            }
        },
        openDialog() {
            console.log("Opening dialog.....")
            //this.editedItemDialog = Object.assign({}, this.editedItem);
            this.dialogOpen = true;
        },
        async fetchItems() {
            try {
                this.showLoader = true;
                this.items = await fetchAllQuestions();
                //console.log("this.items: ", this.items);
                this.showLoader = false;
            } catch (error) {
                this.showLoader = false;
                console.error("Error fetching Banks: ", error);
            }
        },
    },
    watch: {
        'formData.type_of_question'(val) {
            console.log("type_of_question/val: ", val);
            //this.resetTextareaValidation();
            if (val === 'image') {
                if (this.editedIndex === -1) {
                    this.formData.version = 'v1';
                    this.formData.question = '';
                    this.formData.audio_path = '';
                    this.imageQuestionFile = null;
                    /* this.resetTextareaValidation(); */
                    this.valid = this.$refs.form.validate();
                }
            } else {
                this.audioQuestionFile = null;
                /* this.resetTextareaValidation();
            this.valid = this.$refs.form.validate(); */
            }
        },
        dialogOpen(val) {
            console.log("val: ", val);
            if (!val) {
                this.editedItem = this.defaultEditedItem;
                this.editedIndex = -1;
                this.resetForm();
                this.fetchItems();
            } else {
                if (this.editedIndex === -1) {
                    this.resetForm();
                    // Modo Crear: `valid` comienza como falso
                    //this.valid = false;
                } else {
                    // Modo Editar: `valid` es verdadero si los datos precargados son válidos                    
                    this.$nextTick(() => {
                        if (this.$refs.form) {
                            this.valid = this.$refs.form.validate();
                        }
                    });
                }
                console.log("Dialog opened in mode:", this.editedIndex === -1 ? "Create" : "Edit");
            }
        }
    }
}
</script>

<style scoped>
.dialog-card {
    padding: 0;
    /* Elimina el relleno interno */
    margin: 0;
    /* Elimina los márgenes */
}

/* LOADER */
.loader-container {
    position: fixed;
    /* Para que el loader esté por encima de todo el contenido */
    top: 0;
    left: 0;
    width: 100%;
    /* Ocupa todo el ancho del viewport */
    height: 100%;
    /* Ocupa todo el alto del viewport */
    display: flex;
    justify-content: center;
    /* Centra horizontalmente */
    align-items: center;
    /* Centra verticalmente */
    background-color: rgba(255, 255, 255, 0.5);
    /* Fondo semitransparente */
    z-index: 9999;
    /* Coloca el loader por encima de todo el contenido */
}

.loader {
    width: 120px;
    height: 60px;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    background: #fff;
    filter: blur(5px) contrast(10) hue-rotate(60deg);
    mix-blend-mode: darken;
}

.loader:before,
.loader:after {
    content: "";
    width: 40px;
    border-radius: 50%;
    background: #ff00ff;
    animation: l3 1s infinite alternate;
}

.loader:after {
    --s: -1;
}

.wrap-text {
    white-space: normal;
    /* Permite el salto de línea */
    word-wrap: break-word;
    /* Ajusta las palabras largas */
    word-break: break-word;
    /* Evita desbordamientos */
    overflow-wrap: break-word;
    /* Compatibilidad adicional */
    max-width: 300px;
    /* Opcional: Ajusta el ancho máximo de la celda */
}
</style>
