<template>
    <exam-lenguage-version/>
</template>

<script>
import ExamLenguageVersion from '../../components/NovaExam/ExamLenguageVersion.vue'

export default {
    name: 'NovaExamLenguageVersion',
    components: {
        ExamLenguageVersion,
    },
}
</script>