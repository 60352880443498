<template>
    <nova-enroll/>
</template>

<script>
import NovaEnroll from '../../components/NovaExam/Exam.vue'

export default {
    name: 'NovaEnrollView',
    components: {
        NovaEnroll,
    },
}
</script>