import {MONTHLY_SALES_GOAL} from '../../utils/endpoints'

export async function createMonthlySalesGoal(data) {  
    try {
      const response = await fetch(MONTHLY_SALES_GOAL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data), // Convierte el objeto `data` a JSON
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const result = await response.json(); // Parsear la respuesta como JSON
      console.log('Response:', result); // Mostrar el resultado en la consola
      return response;
    } catch (error) {
      console.error('Error:', error.message);
    }
  }
  
  export async function fetchMonthlySalesGoals() {
    //const url = 'http://localhost:3000/monthly-sales-goal'; // Cambia la URL según tu configuración
  
    try {
      // Realizar la solicitud GET
      const response = await fetch(MONTHLY_SALES_GOAL, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      // Verificar si la respuesta fue exitosa
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || `Error: ${response.status}`);
      }
  
      // Parsear la respuesta como JSON
      const result = await response.json();
      console.log('Monthly Sales Goals:', result); // Mostrar los resultados en la consola
      return result;
    } catch (error) {
      console.error('Error fetching monthly sales goals:', error.message);
      return null;
    }
  }