import { NOVA_QUESTIONS } from '../../utils/endpoints.js'

import axios from 'axios';

/**
 * Fetches questions based on the provided criteria.
 * 
 * @param {string} lenguage - The language of the questions (e.g., 'en' or 'es').
 * @param {string} version - The version of the questions (e.g., 'v1').
 * @param {string} typeOfQuestion - The type of the questions (e.g., 'selection').
 * @returns {Promise<Array>} A promise that resolves to the list of questions.
 * @throws {Error} Throws an error if the request fails.
 * @author JDGT
 */
export async function fetchQuestions(lenguage, version, typeOfQuestion) {
  NOVA_QUESTIONS
  try {
    const response = await axios.get(`${NOVA_QUESTIONS}/find-questions`, {
      params: {
        lenguage,
        version,
        type_of_question: typeOfQuestion,
      },
    });

    // Logging the response for debugging
    //console.log('Questions fetched successfully:', response.data);

    return response.data; // Returns the list of questions
  } catch (error) {
    console.error('Error fetching questions:', error);
    throw error;
  }
}

export async function fetchPublicURLs(path) {
  const endpoint = `${NOVA_QUESTIONS}/public-urls`//'http://your-server-url/public-urls'; // Reemplaza con la URL de tu endpoint

  try {
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ path }), // Enviar el `path` como parte del cuerpo de la solicitud
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json(); // Procesar la respuesta en formato JSON
    console.log('Fetched URLs:', data);

    return data; // Devuelve el resultado al cliente que llame esta función
  } catch (error) {
    console.error('Error fetching public URLs:', error);
    return null; // Manejo de errores para evitar interrupciones en la aplicación
  }
}

/**
 * Fetches all questions from the API.
 * @returns {Promise<Array>} A promise that resolves to an array of questions.
 */
export async function fetchAllQuestions() {

  try {
    const response = await fetch(NOVA_QUESTIONS, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    // Maneja respuestas no JSON
    const contentType = response.headers.get('content-type');
    if (!contentType || !contentType.includes('application/json')) {
      throw new Error('The response is not in JSON format');
    }

    if (!response.ok) {
      throw new Error(`HTTP Error: ${response.status} - ${response.statusText}`);
    }

    const data = await response.json();
    //console.log('Fetched Questions:', data);
    return data;
  } catch (error) {
    console.error('Error fetching questions:', error.message);
    throw error;
  }
}

/**
 * Function to send a POST request to the Questions endpoint
 * @param {Object} questionData - The question data to be sent to the endpoint
 * @returns {Promise<Object>} - A promise that resolves to the response from the server
 * @author JDGT
 */
export async function createQuestion(questionData) {
  const headers = {
    'Content-Type': 'application/json',
  };

  try {
    const response = await fetch(NOVA_QUESTIONS, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(questionData),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status} ${response.statusText}`);
    }
    return response;
  } catch (error) {
    console.error('Error creating question:', error);
    throw error;
  }
}

/**
 * Function to update a question by its ID
 * @param {string} id - The ID of the question to update
 * @param {Object} updateQuestionDto - The data to update the question
 * @returns {Promise<Object>} - A promise that resolves to the response from the server
 */
export async function updateQuestion(id, updateQuestionDto) {
  const url = `${NOVA_QUESTIONS}/${id}`; // Ajusta la URL base si es necesario
  const headers = {
    'Content-Type': 'application/json',
  };

  try {
    const response = await fetch(url, {
      method: 'PATCH',
      headers: headers,
      body: JSON.stringify(updateQuestionDto),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status} ${response.statusText}`);
    }

    return response;
  } catch (error) {
    console.error('Error updating the question:', error);
    throw error;
  }
}

export async function uploadFilesToEndpoint(files, folderPath) {
  // Crear un objeto FormData
  const formData = new FormData();

  // Agregar los archivos al FormData
  files.forEach((file) => {
    formData.append('files', file); // 'files' es el mismo nombre usado en el controlador
  });

  // Agregar el folderPath al FormData
  formData.append('folderPath', folderPath);

  try {
    // Consumir el endpoint
    const response = await fetch(`${NOVA_QUESTIONS}/upload-files`, {
      method: 'POST',
      body: formData,
    });

    // Verificar la respuesta
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    /* const data = await response.json();
    console.log('Response from server:', data);
    return data; // Devolver los resultados del servidor */
    console.log("Upload succesful");
    return response;
  } catch (error) {
    console.error('Error uploading files:', error);
    throw error;
  }
}

